import React, { memo} from 'react';
import ComModuleBg from '../ComModuleBg';
import styles from './Index.module.scss';

const Index = memo(({config}) => {

    const renderTemple= () => {
        const {
            normalCss,
            rightBox,
            leftContentCss,
            rightContent,
            purPoseTitle,
            purPoseContent,
        } = config;
        return (
            <div className={styles.normal} style={normalCss}>
                <div className="leftContent" style={leftContentCss}>
                    {purPoseTitle()}
                    {purPoseContent()}
                </div>
                <div className="rightContent" style={rightContent.contentCss}>
                    <ComModuleBg
                        config={{
                            img: rightBox.img,
                            css: {
                                ...rightBox.css
                            },

                        }}
                    />
                </div>


            </div>
        );
    }
    return renderTemple();
});
export default Index;