import React, { memo } from 'react';
import {useSelector} from "react-redux";
import { whCss } from "@/utils";
import styles from './Index.module.scss';



const BlockContents = memo(({config}) => {
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const {
        title='',
        list=[],
        flag=true,
        alignItems='',
        status=1,
        // style
        titleCss,
        listCss,
        fLCss,
    } = config;
    
    const flex = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems,
    }

    const renderText = (data) => {
        if(status === 1){
            return data.map((item, index) => {
                return (
                    <p key={index} style={listCss}>{item}</p>
                )
            });
        }else{

            const isLen = localesReducer['navbar.lang'] === '中文';
            return (
                // <p style={{ ...listCss, width: isLen ? 'auto' : `${800 / 100}rem`}}>
                <p style={{ ...listCss }}>
                    {
                        data.map((item, index) => {
                            if(index === 0) {
                                return (
                                    <span key={index} style={{color: fLCss.firstColor, fontSize: listCss.fontSize, display: 'inline-block'}}>{item}</span>
                                )
                            }else{
                                return (
                                    <span key={index} style={{color: fLCss.lastColor, marginLeft: isLen ? '': `${0 / 100}rem`, fontSize: listCss.fontSize, display: 'inline-block'}}>{item}</span>
                                )
                            }

                        })
                    }
                </p>
            )
        }
    }

    const isWidth = recReSizeReducer <= 750;
    const wh = isWidth ? {
        width: whCss(24),
        height: whCss(4),
    }: null

    return (
        <div className={styles.normal} style={flex}>
            {title ? (<h3 className="title" style={titleCss}>{title}</h3>) : null}
            <div className="text">
                {renderText(list)}
            </div>
            <div className="desc">
                {
                    flag ? (
                        <p>
                            <span>SINCE 2012</span>
                            <span>____________</span>
                        </p>
                    ) : (
                        <div className="subline" style={{ background: fLCss.firstColor, ...wh}}/>
                    )
                }
            </div>
        </div>
    );
});
export default BlockContents;
