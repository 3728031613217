import React, { memo} from 'react';
import {useSelector} from "react-redux";
import { whCss } from "../../utils";
import styles from './Index.module.scss';

const ListCards = memo(() => {
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);

    const handleJumpToOfficialWebsite = (url) => {
        if(url) window.open(url);
    }

    const renderPC = () => {
        const isNormal = recReSizeReducer >=1920 ? styles.normalMax : styles.normal;
        const isWidth = recReSizeReducer >=1920;
        return (
            <div className={isNormal}>
                <div className="list">
                    <ul>
                        {
                            localesReducer.game.pageOne.listNewData.map(item => {
                                const isMargin = isWidth ? {margin: `0 ${whCss(80)}`} : item.css.margin;
                                return (
                                    <li key={item.id} style={item.css.flex}>
                                        <div className="pic" style={{background: `url(${item.img}) no-repeat 100% 100%/100%`}} />
                                        <div className="content" style={isMargin}>
                                            {
                                                item.title ? (
                                                    <>
                                                        <h3 className="title">{item.title}</h3>
                                                        <p className="titleen">{item.en}</p>
                                                        <p className="subtitle">
                                                            <span>{item.subLineText.role}</span>
                                                            <i className="line" />
                                                            <span>{item.subLineText.party}</span>
                                                            <i className="line" />
                                                            <span>{item.subLineText.platform} </span>
                                                        </p>
                                                    </>
                                                ):null
                                            }
                                            <p className="desc">{item.desc}</p>
                                            {item.btnText ? <button className="btn" onClick={() => handleJumpToOfficialWebsite(item.url)}>{item.btnText}</button> : null}
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
    const renderH5 = () => {
        return (
            <div className={styles.normalh5}>
                <div className="list">
                    <ul>
                        {
                            localesReducer.game.pageOne.listNewData.map(item => {
                                return (
                                    <li key={item.id}>
                                        <div className="pic" style={{background: `url(${item.img}) no-repeat ${item.css.lr} ${item.css.tb}/100%`}} />
                                        <div className="content">
                                            {
                                                item.title ? (
                                                    <>
                                                        <h3 className="title">{item.title}</h3>
                                                        <p className="titleen">{item.en}</p>
                                                        <p className="subtitle">
                                                            <span>{item.subLineText.role}</span>
                                                            <i className="line" />
                                                            <span>{item.subLineText.party}</span>
                                                            <i className="line" />
                                                            <span>{item.subLineText.platform} </span>
                                                        </p>
                                                    </>
                                                ):null
                                            }
                                            <p className="desc">{item.desc}</p>
                                            {item.btnText ? <button className="btn" onClick={() => handleJumpToOfficialWebsite(item.url)}>{item.btnText}</button> : null}
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }


    if(Object.keys(localesReducer.game).length){
        return recReSizeReducer > 750 ? renderPC() : renderH5();
    }else{
        return null;
    }
});
export default ListCards;