import React, { memo, useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from "../../models/stores/actions";
import BlockContents from '../../components/BlockContents';
import WelcomeVisit from '../../components/WelcomeVisit';

import VerticalLine from '../../components/VerticalLine';
import GroupRectangle from '../../components/GroupRectangle';
import {
    HuanXiangText,
    GamesText,
    LatestNewsText,
    DropAMessageText,
} from '../../components/FontStrokeCommTexts';
import VideoDisplay from '../../components/VideoDisplay';
// import ScrollLRNews from '../../components/ScrollLRNews';
import {
    whCss,
    getOffset,
    getParentElement,
    storage,
    backTo,
    delay,
    mailtoSubmit,
} from '@/utils';

import styles from './Index.module.scss';

// one
import slogan from '../../assets/home/one/slogan.svg';
import aboutLogo from '../../assets/home/two/img_about_logo.svg';

// vector icon
import vectorLeftBlack from '../../assets/public/vector-left-black.svg'; // 左⬅️
import vectorRightBlack from '../../assets/public/vector-right-black.svg';
import vectorLeftWith from '../../assets/public/vercor-left-with.svg';


const Home = memo((props) => {
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);

    const tListEl = useRef(null);
    const threeEl= useRef(0);
    const [refThreeEl, setRefThreeEl] = useState(0);


    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const { value: scrollNumReducer, cshVal } = useSelector(({scrollNumReducer}) => scrollNumReducer);
    const dispatch = useDispatch();
    const { handleNewsDetail } = actions;

    const [active, setActive] = useState(101);
    const linearGradient = `linear-gradient(0deg, rgba(26, 26, 26, 0.8), rgba(26, 26, 26, 0.8))`;
    const linearGradientActive = `linear-gradient(0deg, rgba(176, 162, 124, 0.3), rgba(176, 162, 124, 0.3))`;

    const [threeTabsListData, setThreeTabsListData] = useState(localesReducer.home.pageThree.threeNewTabsListData);
    const [threeTabsItemData, setThreeTabsItemData] = useState({...threeTabsListData[0]});
    const [threeTabsNum, setThreeTabsNum] = useState(1);

    const desktopVideoMp4 = window.globalConfig.source.desktop;
    const mobileVideoMp4 = window.globalConfig.source.mobile;


    // 数据
    const [progreVal, setProgreVal] = useState(1);
    const [newsTabListLeng, setNewsTabListLeng] = useState(0);
    const [newsTabListLength, setNewsTabListLength] = useState(0);
    const [listTotal, setlistTotal] = useState(1);
    const [listFourData, setListFourData] = useState([]);
    const [listIcsData, setListIcsData] = useState([]);


    // TODO: two start
    // PC端-动画
    const twoHuanXiangRef = useRef();
    const twoHuanXiangRefM = useRef();
    const [huanXiangFlag, setHuanXiangFlag] = useState(false);
    const [huanXiangFlagM, setHuanXiangFlagM] = useState(false);

    const twoAweAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        let B1 = getParentElement(A1.offsetParent);
        const A = B1.offsetHeight+getOffset(B1).top - (B1.offsetHeight/2);
        if(A <= B){
            setHuanXiangFlag(true);
        }else{
            setHuanXiangFlag(false);
        }
    }
    // 移动端-动画
    const twoAweAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        let B1 = getParentElement(A1.offsetParent);
        const A = B1.offsetHeight+getOffset(B1).top - (B1.offsetHeight/2);
        if(A <= B){
            setHuanXiangFlagM(true);
        }else{
            setHuanXiangFlagM(false);
        }
    }


    // PC和移动端进行判断
    const twoAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            twoAweAnimateScroll(twoHuanXiangRef);
        }else{
            // H5
            twoAweAnimateScrollM(twoHuanXiangRefM);
        }
    }
    // TODO: two end

    // TODO: three start
    // PC端-动画
    const threeGamesTextRef = useRef();
    const [gamesFlag, setGamesFlag] = useState(false);
    const threeGamesTextRefM = useRef();
    const [gamesFlagM, setGamesFlagM] = useState(false);

    const threeAweAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        let B1 = getParentElement(A1.offsetParent);
        const A = A1.offsetHeight+getOffset(B1).top;
        if(A <= B){
            setGamesFlag(true);
        }else{
            setGamesFlag(false);
        }
    }
    // 移动端-动画
    const threeAweAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        let B1 = getParentElement(A1.offsetParent);
        const A = A1.offsetHeight+getOffset(B1).top;
        if(A <= B){
            setGamesFlagM(true);
        }else{
            setGamesFlagM(false);
        }
    }

    // PC和移动端进行判断
    const threeAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            threeAweAnimateScroll(threeGamesTextRef);
        }else{
            // H5
            threeAweAnimateScrollM(threeGamesTextRefM);
        }
    }
    // TODO: three end

    // TODO: four start
    // PC端-动画
    const fourLRenderDrawTextRef = useRef();
    const [lRenderDrawFlag, setLRenderDrawFlag] = useState(false);
    const fourLRenderDrawTextRefM = useRef();

    const [lRenderDrawFlagM, setLRenderDrawFlagM] = useState(false);
    const fourAweAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight + getOffset(A1).top;
        if(A <= B){
            setLRenderDrawFlag(true);
        }else{
            setLRenderDrawFlag(false);
        }
    }
    // 移动端-动画
    const fourAweAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight + getOffset(A1).top;
        if(A <= B){
            setLRenderDrawFlagM(true);
        }else{
            setLRenderDrawFlagM(false);
        }
    }

    // PC和移动端进行判断
    const fourAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            fourAweAnimateScroll(fourLRenderDrawTextRef);
        }else{
            // H5
            fourAweAnimateScrollM(fourLRenderDrawTextRefM);
        }
    }
    // TODO: four end


    // TODO: five start
    // PC端-动画
    const fiveDRenderDrawTextRef = useRef();
    const [dRenderDrawFlag, setDRenderDrawFlag] = useState(false);
    const fiveDRenderDrawTextRefM = useRef();
    const [dRenderDrawFlagM, setDRenderDrawFlagM] = useState(false);

    const [fiveBg, setFiveBg] = useState({
        background: '#FFF',
        opacity: 0,
        transition: 'all 0.8s'
    });

    const [fiveBgM, setFiveBgM] = useState({
        background: '#FFF',
        opacity: 0,
        transition: 'all 0.8s'
    });

    const fiveAweAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight+ getOffset(A1).top;
        if(A <= B){
            setFiveBg({
                background: '#1A1A1A',
                opacity: 1,
                transition: 'all 0.8s'
            });
            setDRenderDrawFlag(true);
        }else{
            setFiveBg({
                background: '#FFF',
                opacity: 0,
                transition: 'all 0.8s'
            });
            setDRenderDrawFlag(false);
        }
    }
    // 移动端-动画
    const fiveAweAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight+ getOffset(A1).top;
        if(A <= B){
            setFiveBgM({
                background: '#1A1A1A',
                opacity: 1,
                transition: 'all 0.8s'
            });
            setDRenderDrawFlagM(true);
        }else{
            setFiveBgM({
                background: '#FFF',
                opacity: 0,
                transition: 'all 0.8s'
            });
            setDRenderDrawFlagM(false);
        }
    }

    // PC和移动端进行判断
    const fiveAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            fiveAweAnimateScroll(fiveDRenderDrawTextRef);
        }else{
            // H5
            fiveAweAnimateScrollM(fiveDRenderDrawTextRefM);
        }
    }
    // TODO: five end

    useEffect(() => {
        twoAnimateScroll();
        threeAnimateScroll();
        fourAnimateScroll();
        fiveAnimateScroll();
    }, [cshVal]);


    const newsListDatMemo = useMemo(() => {
        let newArrList = [];
        const newsListDat = localesReducer.news.pageOne.tabListData.filter(item => item.id !== 1001);
        // eslint-disable-next-line array-callback-return
        newsListDat.map((item) => {
            // eslint-disable-next-line array-callback-return
            item.list.map((k) => {
                newArrList.push(k);
            });
        });

        return newArrList;
    }, [localesReducer.news.pageOne.tabListData]);


    useEffect(() => {
        // 盒的内容的滚动条距离设置
        handleScroll(41);
        // 初始化数据
        setThreeTabsListData(localesReducer.home.pageThree.threeNewTabsListData);
        // 过滤子级数据内容
        const data = localesReducer.home.pageThree.threeNewTabsListData.filter((item) => item.id === active);
        setThreeTabsItemData({...data[0]});

        // 动态获取坚线的高度
        const isHeight = threeEl.current.offsetHeight;

        setRefThreeEl(isHeight);

        // NEWS数据切换
        setListFourData(newsListDatMemo);
        // 联系我们
        setListIcsData(localesReducer.home.pageFives.listIcsData);
    }, [localesReducer, threeTabsListData, threeEl, refThreeEl, active]);

    useEffect(() => {
        setProgreVal(100 / newsListDatMemo.length);
        setNewsTabListLeng(100 / newsListDatMemo.length);
        setNewsTabListLength(newsListDatMemo.length);
    }, [newsListDatMemo]);




    // 进度条的设置
    const handleScroll = (num) => {
        try {
            tListEl.current.scrollLeft = num;
        }catch(e) {
            return false;
        }

    }

    // 1616 - 1556 = 60   200 / 4 = 11;  200 / 4 * 0.5 = 25
    const handleTFirst = useCallback(() => {
        const islistTotal = listTotal-1;
        const isProgreVal = progreVal-newsTabListLeng;
        if(listTotal !== 1){
            let newPopdata = listFourData.pop();
            setListFourData((oldListFourData) => [newPopdata, ...oldListFourData]);
            setlistTotal(islistTotal);
            setProgreVal(isProgreVal);
        }else{
            handleScroll(41);
        }
    }, [listFourData, progreVal, listTotal]);

    const handleTNext = useCallback(() => {
        const islistTotal = listTotal+1;
        const isProgreVal = progreVal+newsTabListLeng;
        if(listTotal !== listFourData.length){
            let newPopdata = listFourData.shift();
            setListFourData((oldListFourData) => [...oldListFourData, newPopdata]);
            setlistTotal(islistTotal);
            setProgreVal(isProgreVal);
        }else{
            handleScroll(224);
        }

    }, [listFourData, progreVal, listTotal]);

    const handleTabItem = useCallback((item, index) => {
        setActive(item.id);
        setThreeTabsItemData(item);
        setThreeTabsNum(index);
    }, [threeTabsItemData, threeTabsListData]);


    useEffect(() => {
        delay(200, () =>{
            backTo();
        });
    }, []);

    const handleLinkJump = useCallback( ({item, index}) => {
        const { history } = props;

        storage('details', {type: 'home'}).setStorage();         // 记录来当前的页面
        storage('scrollTopKey', scrollNumReducer).setStorage();  // 记录来当前的页面滚动的位置

        history.push(`/news/detail?active=all`,);
        dispatch(handleNewsDetail({
            id: item.id,
            page: index,
            size: newsTabListLength,
            list: newsListDatMemo,
        }));
    }, [dispatch, handleNewsDetail, newsListDatMemo, newsTabListLength, props, scrollNumReducer]);

    const handleLinkJumpGames = () => {
        const { history } = props;
        history.push(`/game`,);
    }

    const handleJumpToOfficialWebsite = (url) => {
        if(url) window.open(url);
    }


    const [firstFlag, setFirstFlag] = useState(true);
    const [nextFlag, setNextFlag] = useState(true);
    const handleShowMouse = useCallback((type) => {
        if (type === 'firstShow'){
            setFirstFlag(false);
        }
        if (type === 'firstHidden'){
            setFirstFlag(true);
        }

        if (type === 'nextShow'){
            setNextFlag(false);
        }
        if (type === 'nextHidden'){
            setNextFlag(true);
        }
    },[]);


    const handleMouseOverHover = useCallback((type, item, event) => {
        event.preventDefault();
        const bgColor = active === item.id ? linearGradientActive : linearGradient;
        if (type === 'flagGameShow'){
            event.target.style.background = `${linearGradientActive}, url(${item.img}) no-repeat ${item.styles.lr} ${item.styles.tb}/cover`;
        }
        if (type === 'flagGameHidden') {
            event.target.style.background = `${bgColor}, url(${item.img}) no-repeat ${item.styles.lr} ${item.styles.tb}/cover`
        }
    },[active, linearGradient, linearGradientActive]);


    const renderPC = () => {
        const { pageOne, pageTwo } = localesReducer && localesReducer.home;
        const isLang = localesReducer['navbar.lang'] === 'English';

        const isSloganCss = {
            width: localesReducer['navbar.lang'] === 'English' ? `${755 / 100}rem` : `${490 / 100}rem`, // 490
            height: `${42 / 100}rem`, // 42
            position: 'absolute',
            bottom: `${150 / 100}rem`, //
            left: '50%',
            marginLeft: localesReducer['navbar.lang'] === 'English' ? `${-377.5 / 100}rem` : `${-254 / 100}rem`,// -2.54rem;
        };


        // const isNormal = recReSizeReducer >= 1920 ? styles.normalMax : styles.normal;
        const isNormal = recReSizeReducer >= 1920 ? styles.normalMax : styles.normal;
        const isWidth = recReSizeReducer >= 1920;
        const hMax = 176.76;

        const isThreeH = {
            // height: whCss( isLang ? (isWidth ? 1247: 1170) : (isWidth ? 1170 : 1170))
            height: whCss( isWidth ? (isLang ? 1402: 1247) :(isLang ? 1325: 1170) )
        };

        return (
            <div className={isNormal}>
                <div className="one">
                    <VideoDisplay
                        config={{
                            img: '',           // 封面图像
                            source: desktopVideoMp4,  // 视频资源文件
                            css: {
                                position: 'absolute',
                                top: whCss(0),
                            },
                            videoCss: {
                                width: `100vw`, // 等比的宽度
                            }
                        }}
                    />
                    <div className="slogan" style={isSloganCss}><img style={{ width: isSloganCss.width, height: isSloganCss.height}} src={pageOne.slogan} alt="slogan"/></div>
                    <div className="groupScroll">
                        <GroupRectangle config={{
                            innerLineH: 148,
                            css: {
                                height: whCss(148),
                            }
                        }} />
                    </div>
                </div>
                <div className="two">
                    <div className="left">
                        <BlockContents config={{
                            title: pageTwo.title,       // 标题
                            list: pageTwo.subTitle,     // 副标题
                            flag: true,                 // 状态
                            alignItems: 'flex-start', // flex-start / flex-end
                            status: 1, // 状态 1， 2， 3
                            titleCss: {
                                fontSize: `${20 / 100}rem`,
                                color: '#B0A27C',
                                marginBottom: `${16 / 100}rem`,
                            },
                            listCss: {
                                width: '',
                                height: '',
                                fontSize: `${56 / 100}rem`,
                                textAlign: 'left',
                                color: '#1A1A1A',
                                lineHeight: `${80 / 100}rem`
                            },
                            fLCss: {
                                firstColor: '',
                                lastColor: '',
                            }
                        }} />
                    </div>
                    <div className="right">
                        <div className="content">
                            <div className="aboutlogo">
                                <img src={aboutLogo} alt="aboutLogo"/>
                            </div>
                            <div className="desc">
                                {pageTwo.desc.map((item, index) => {
                                    return (<p key={index}>{item}</p>);
                                })}

                            </div>
                        </div>
                    </div>
                    <div className="borderTextBox" ref={twoHuanXiangRef}>
                        {huanXiangFlag ? (
                            <HuanXiangText
                                config={{
                                    huanTextCss: {
                                        width: whCss(isWidth ? 756 : 540.85),
                                        height: whCss(isWidth ? hMax : 132.57),
                                        marginRight: whCss(70.95),
                                    },
                                    XiangTextCss: {
                                        width: whCss(isWidth ? 811.26 : 608.45),
                                        height: whCss(isWidth ? 178.68 : 134.01),
                                    }
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="btn">{pageTwo.readMore}</div>
                </div>
                <div className="three" ref={threeEl} style={isThreeH}>
                    <VerticalLine
                        config={{
                            css: {
                                height: refThreeEl,
                                left: whCss(isWidth ? 260 : 222),
                            }
                        }}
                    />
                    <VerticalLine
                        config={{
                            css: {
                                height: refThreeEl,
                                left: whCss(isWidth ? 1095 : 838),
                            }
                        }}
                    />
                    <VerticalLine
                        config={{
                            css: {
                                height: refThreeEl,
                                right: whCss(isWidth ? 260 : 222),
                            }
                        }}
                    />
                    <div className="pi">
                        <BlockContents config={{
                            title: threeTabsItemData.title,       // 标题
                            list: isLang ? threeTabsItemData.subTitle : [`${threeTabsItemData.subTitle[0]}${threeTabsItemData.subTitle[1]}`],     // 副标题
                            flag: false,                 // 状态
                            alignItems: 'flex-start', // flex-start / flex-end
                            status: 1, // 状态 1， 2， 3
                            titleCss: {
                                fontSize: `${20 / 100}rem`,
                                color: '#B0A27C',
                                marginBottom: `${16 / 100}rem`,
                            },
                            listCss: {
                                width: '',
                                height: '',
                                fontSize: `${56 / 100}rem`,
                                textAlign: 'left',
                                color: '#1A1A1A',
                                lineHeight: `${80 / 100}rem`
                            },
                            fLCss: {
                                firstColor: '',
                                lastColor: '',
                            }
                        }} />
                    </div>
                    <div className="gamesBorderTextBox" ref={threeGamesTextRef}>
                        {gamesFlag ? (
                            <GamesText
                                config={{
                                    css: {
                                        width: whCss(isWidth ? hMax : 134.01),
                                        height: whCss(isWidth ? 891 : 657.46),
                                    }
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="gamesbox">
                        <div className="content">
                            <div className="imgbox" style={{background: `url(${threeTabsItemData.contents.img}) no-repeat ${threeTabsItemData.contents.styles.lr} ${threeTabsItemData.contents.styles.tb}/cover`}} />
                            <div className="descbox">
                                <div className="left">
                                    <p className="desctitle">{threeTabsItemData.contents.desc.title}</p>
                                    <div className="line" />
                                    <p className="desctext">{threeTabsItemData.contents.desc.subTitle}</p>
                                    <span className="gbtn" onClick={() => handleJumpToOfficialWebsite(threeTabsItemData.contents.desc.url)}>{threeTabsItemData.contents.desc.btnText}</span>
                                </div>
                                <div className="right">
                                    {threeTabsItemData.contents.list.map((item, index) => {
                                        return (
                                            <div key={index} className="rleft">
                                                <p className="ltype">{item.name}</p>
                                                <p className="lshooting">{item.desc}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gamestabbox">
                        <div className="mathnum">
                            <span className="next">0{threeTabsNum}</span>
                            <span className="botline"/>
                            <span className="total">0{threeTabsListData.length}</span>
                        </div>
                        <div className="tabs">
                            {threeTabsListData.map((item, index) => {
                                const bgColor = active === item.id ? linearGradientActive : linearGradient;
                                return (
                                    <div
                                        onMouseOver={(event) => handleMouseOverHover('flagGameShow', item, event)} onMouseLeave={(event) => handleMouseOverHover('flagGameHidden', item, event)}
                                        className={active === item.id ? 'item tabactive' : 'item' }
                                        onClick={() => handleTabItem(item, index+1)} key={item.id}
                                        style={{background: `${bgColor}, url(${item.img}) no-repeat ${item.styles.lr} ${item.styles.tb}/cover`}}
                                    >
                                        {/*<div className="ititle">{item.contents.desc.title}</div>*/}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="more" onClick={handleLinkJumpGames}>
                            <div className="moreAnimation">
                                <div className="scrollText">
                                    <span> {localesReducer.home.pageThree.plusMore} </span>
                                    <span> {localesReducer.home.pageThree.plusMore} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="four">
                    <div className="gamesBorderTextBox" ref={fourLRenderDrawTextRef}>
                        { lRenderDrawFlag ? (
                            <LatestNewsText
                                config={{
                                    css: {
                                        width: whCss(isWidth ? 1722 : 1272.9),
                                        height: whCss(isWidth ? hMax : 134.01),
                                    }
                                }}
                            />
                        ) : null }
                    </div>
                    <div className="content">
                        <BlockContents config={{
                            title: localesReducer.home.pageFour.title,       // 标题
                            list: localesReducer.home.pageFour.subTitle,     // 副标题
                            flag: false,                 // 状态
                            alignItems: 'flex-start', // flex-start / flex-end
                            status: 1, // 状态 1， 2， 3
                            titleCss: {
                                fontSize: `${20 / 100}rem`,
                                color: '#B0A27C',
                                marginBottom: `${16 / 100}rem`,
                            },
                            listCss: {
                                width: '',
                                height: '',
                                fontSize: `${56 / 100}rem`,
                                textAlign: 'left',
                                color: '#1A1A1A',
                                lineHeight: `${80 / 100}rem`
                            },
                            fLCss: {
                                firstColor: '',
                                lastColor: '',
                            }
                        }} />
                    </div>
                    <div className="tabs">
                        <div className="tleft">
                            <div
                                className={`tfirst ${!firstFlag ? (listTotal === 1 ? null : 'tNextFirstAction') : null}`}
                                onMouseOver={() => handleShowMouse('firstShow')}
                                onMouseLeave={() => handleShowMouse('firstHidden')}
                                onClick={handleTFirst}
                                style={{opacity: listTotal === 1 ? 0.3 : 1}}
                            >
                                <img src={!firstFlag ? (listTotal === 1 ? vectorLeftBlack : vectorLeftWith)  : vectorLeftBlack} style={{transform: `rotate(${!firstFlag ? (listTotal === 1 ? 0 : 180 ) : 0}deg)`}} alt="left" />
                            </div>
                            <div
                                className={`tnext ${!nextFlag ? (listTotal === listFourData.length ? null : 'tNextFirstAction')  : null}`}
                                onMouseOver={() => handleShowMouse('nextShow')}
                                onMouseLeave={() => handleShowMouse('nextHidden')}
                                onClick={handleTNext}
                                style={{opacity: listTotal === listFourData.length ? 0.3 : 1}}
                            >
                                <img src={!nextFlag ? (listTotal === listFourData.length ? vectorRightBlack : vectorLeftWith) : vectorRightBlack} alt="right" />
                            </div>
                        </div>
                        <div className="tright">
                            <div className="tlist" ref={tListEl}>
                                {listFourData.map((item, index) => {
                                    return (
                                        <div key={item.id} className="titem" onClick={() => handleLinkJump({item, index})}>
                                            <div className="timg" style={{background: `url(${item.img}) no-repeat ${item.styles.lr} ${item.styles.tb}/cover`}} />
                                            <div className="tdesc">
                                                <div className="tag"><span>{item.tag}</span></div>
                                                <p className="ttext">{item.title}</p>
                                                <p className="ttime">{item.time}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="tprogre">
                                <div className="tbar" style={{width: `${progreVal}%`}} />
                            </div>
                        </div>
                    </div>

                    {/*<ScrollLRNews
                        config={{
                            data: listFourData,
                        }}
                    />*/}
                </div>
                <div className="five" style={fiveBg}>
                    <div className="gamesBorderTextBox" ref={fiveDRenderDrawTextRef}>
                        { dRenderDrawFlag ? (
                            <DropAMessageText
                                config={{
                                    css: {
                                        width: whCss(isWidth ? 2206 : 1635.27),
                                        height: whCss(isWidth ? hMax : 134.01),
                                    }
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="content">
                        <BlockContents config={{
                            title: localesReducer.home.pageFives.title,       // 标题
                            // list: [`${localesReducer.home.pageFives.subTitle[0]}${localesReducer.home.pageFives.subTitle[1]}`],     // 副标题
                            list: isLang ? localesReducer.home.pageFives.subTitle : [`${localesReducer.home.pageFives.subTitle[0]}${localesReducer.home.pageFives.subTitle[1]}`],     // 副标题
                            flag: false,                 // 状态
                            alignItems: 'flex-end', // flex-start / flex-end
                            status: 1, // 状态 1， 2， 3
                            titleCss: {
                                fontSize: `${20 / 100}rem`,
                                color: '#B0A27C',
                                marginBottom: `${16 / 100}rem`,
                            },
                            listCss: {
                                width: '',
                                height: '',
                                fontSize: `${56 / 100}rem`,
                                textAlign: 'right',
                                color: '#FFF',
                                lineHeight: `${80 / 100}rem`
                            },
                            fLCss: {
                                firstColor: '#FFF',
                                lastColor: '',
                            }
                        }} />
                    </div>
                    <div className="ics">
                        {listIcsData.map((item) => {
                            return (
                                <div key={item.id} className="iitem">
                                    <div className="iicon"><img src={item.img} alt="img" /></div>
                                    <p className="ititle">{item.title}</p>
                                    <p className="iemail" onClick={() => mailtoSubmit(item.email)}>{item.email}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="six">
                    <WelcomeVisit
                        config={{
                            scrollNum: cshVal,
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderH5 = () => {
        const { pageOne, pageTwo } = localesReducer && localesReducer.home;
        const isLang = localesReducer['navbar.lang'] === 'English';
        let isHeight = isLang ? { height: whCss(257) } : null;

        const isSlogan = (type) => {
            if(type === 'slogan'){
                return {
                    width: whCss(isLang ? 360 : 300),
                    height: whCss(isLang ? 46 : 25.71),
                    marginLeft: whCss(isLang ? -(360/2) : -(300/2)),
                }
            }
            return {
                width: whCss(isLang ? 360 : 300),
                height: whCss(isLang ? 46 : 25.71),
            }
        };

    return (
        <div className={styles.normalH5}>
            <div className="one">
                <div className="content">
                    <VideoDisplay
                        config={{
                            img: '',           // 封面图像
                            source: mobileVideoMp4,  // 视频资源文件
                            css: {
                                position: 'absolute',
                                top: whCss(-118),
                            },
                            videoCss: {
                                // width: `100vw`, // 等比的宽度
                                width: `130vw`, // 等比的宽度
                                transform: `translate(-12%, -8%)`,
                            }
                        }}
                    />
                    <div className="slogan" style={isSlogan('slogan')}><img style={isSlogan('img')} src={isLang ? pageOne.sloganM : pageOne.slogan} alt="slogan"/></div>
                </div>
                <div className="groupScroll">
                    <GroupRectangle config={{
                        innerLineH: 165,
                        css: {
                            height: whCss(165),
                        }
                    }} />
                </div>
                <div className="bot" />
            </div>
            <div className="two">
                <div className="info">
                    <div className="left">
                        <div className="content">
                            <BlockContents config={{
                                title: pageTwo.title,       // 标题
                                list: isLang ? [`${pageTwo.subTitle[0]}${pageTwo.subTitle[1]}`] : pageTwo.subTitle,     // 副标题
                                flag: true,                 // 状态
                                alignItems: 'flex-start', // flex-start / flex-end
                                status: 1, // 状态 1， 2， 3
                                titleCss: {
                                    fontSize: `${17 / 100}rem`,
                                    color: '#B0A27C',
                                    marginBottom: `${16 / 100}rem`,
                                },
                                listCss: {
                                    width: '',
                                    height: '',
                                    fontSize: `${32 / 100}rem`,
                                    textAlign: 'left',
                                    color: '#1A1A1A',
                                    lineHeight: `${40 / 100}rem`
                                },
                                fLCss: {
                                    firstColor: '',
                                    lastColor: '',
                                }
                            }} />
                        </div>
                    </div>
                    <div className="right" style={isHeight}>
                        <div className="content">
                            <div className="aboutlogo">
                                <img src={aboutLogo} alt="aboutLogo"/>
                            </div>
                            <div className="desc">
                                {pageTwo.desc.map((item, index) => {
                                    return (<p key={index} className={isLang ? 'latoFont' : null}>{item}</p>);
                                })}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="borderTextBox" ref={twoHuanXiangRefM}>
                    {
                        huanXiangFlagM ? (
                            <HuanXiangText
                                config={{
                                    huanTextCss: {
                                        width: whCss(166),
                                        height: whCss(68),
                                        marginRight: whCss(10),
                                    },
                                    XiangTextCss: {
                                        width: whCss(189),
                                        height: whCss(68),
                                    }
                                }}
                            />
                        ) : null
                    }

                </div>
                <div className="btn">{pageTwo.readMore}</div>
            </div>
            <div className="three" ref={threeEl}>
                <VerticalLine
                    config={{
                        css: {
                            height: refThreeEl,
                            left: whCss(24),
                        }
                    }}
                />
                <VerticalLine
                    config={{
                        css: {
                            height: refThreeEl,
                            left: whCss(207),
                        }
                    }}
                />
                <VerticalLine
                    config={{
                        css: {
                            height: refThreeEl,
                            right: whCss(24),
                        }
                    }}
                />
                <div className="pi">
                    <div className="blockContents">
                        <BlockContents config={{
                            title: threeTabsItemData.title,       // 标题
                            list: isLang ? threeTabsItemData.subTitle : [threeTabsItemData.subTitle[0], threeTabsItemData.subTitle[1]],     // 副标题
                            flag: false,                 // 状态
                            alignItems: 'flex-end', // flex-start / flex-end
                            status: 1, // 状态 1， 2， 3
                            titleCss: {
                                fontSize: `${17 / 100}rem`,
                                color: '#B0A27C',
                                marginBottom: `${16 / 100}rem`,
                            },
                            listCss: {
                                width: '',
                                height: '',
                                whiteSpace: 'nowrap',
                                fontSize: `${32 / 100}rem`,
                                textAlign: 'right',
                                color: '#1A1A1A',
                                lineHeight: `${40 / 100}rem`
                            },
                            fLCss: {
                                firstColor: '',
                                lastColor: '',
                            }
                        }} />
                    </div>
                </div>
                <div className="gamesBorderTextBox" ref={threeGamesTextRefM}>
                    { gamesFlagM ? (
                        <GamesText
                            config={{
                                css: {
                                    width: whCss(68),
                                    height: whCss(208),
                                }
                            }}
                        />
                    ) : null }
                </div>
                <div className="gamesbox">
                    <div className="content">
                        <div className="imgbox" style={{background: `url(${threeTabsItemData.contents.img}) no-repeat 100% 23%/cover`}} />
                        <div className="descbox">
                            <div className="left">
                                <p className="desctitle">{threeTabsItemData.contents.desc.title}</p>
                                <div className="line" />
                                <p className="desctext">{threeTabsItemData.contents.desc.subTitle}</p>
                            </div>
                            <div className="right">
                                <div className="rleft">
                                    <p className="ltype">{threeTabsItemData.contents.list[0].name}</p>
                                    <p className="lshooting">{threeTabsItemData.contents.list[0].desc}</p>
                                </div>
                                <div className="rleft">
                                    <p className="ltype">{threeTabsItemData.contents.list[2].name}</p>
                                    <p className="lshooting">{threeTabsItemData.contents.list[2].desc}</p>
                                </div>
                                <div className="rleft">
                                    <p className="ltype">{threeTabsItemData.contents.list[1].name}</p>
                                    <p className="lshooting">{threeTabsItemData.contents.list[1].desc}</p>
                                </div>
                                <div className="rleft">
                                    <p className="ltype">{threeTabsItemData.contents.list[3].name}</p>
                                    <p className="lshooting">{threeTabsItemData.contents.list[3].desc}</p>
                                </div>
                            </div>
                            <div className="btnText">
                                <span className="gbtn" onClick={() => handleJumpToOfficialWebsite(threeTabsItemData.contents.desc.url)}>{threeTabsItemData.contents.desc.btnText}</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="gamestabbox">
                    <div className="mathnum">
                        <span className="next">0{threeTabsNum}</span>
                        <span className="botline"/>
                        <span className="total">0{threeTabsListData.length}</span>
                    </div>
                    <div className="tabs">
                        <div className="tabsBox">
                            {threeTabsListData.map((item, index) => {
                                let bgCss = {
                                    background: `${active === item.id ? linearGradientActive : linearGradient}, url(${item.img}) no-repeat 0% 60%/cover`,
                                }
                                return (
                                    <div className={active === item.id ? 'item tabactive' : 'item' } onClick={() => handleTabItem(item, index+1)} key={item.id} style={bgCss}>
                                        {/*<div className="ititle">{item.contents.desc.title}</div>*/}
                                    </div>
                                );
                            })}
                            <div className="more" onClick={handleLinkJumpGames}>
                                {localesReducer.home.pageThree.plusMore}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className="four">
                <div className="gamesBorderTextBox" ref={fourLRenderDrawTextRefM}>
                    { lRenderDrawFlagM ? (
                        <LatestNewsText
                            config={{
                                css: {
                                    width: whCss(402),
                                    height: whCss(68),
                                }
                            }}
                        />
                    ) : null }
                </div>
                <div className="content">
                    <BlockContents config={{
                        title: localesReducer.home.pageFour.title,       // 标题
                        list: localesReducer.home.pageFour.subTitle,     // 副标题
                        flag: false,                 // 状态
                        alignItems: 'flex-start', // flex-start / flex-end
                        status: 1, // 状态 1， 2， 3
                        titleCss: {
                            fontSize: `${17 / 100}rem`,
                            color: '#B0A27C',
                            marginBottom: `${16 / 100}rem`,
                        },
                        listCss: {
                            width: '',
                            height: '',
                            fontSize: `${32 / 100}rem`,
                            textAlign: 'left',
                            color: '#1A1A1A',
                            lineHeight: `${40 / 100}rem`
                        },
                        fLCss: {
                            firstColor: '',
                            lastColor: '',
                        }
                    }} />
                </div>
                <div className="tabs">
                    <div className="tright">
                        <div className="tlist">
                            {listFourData.map((item, index) => {
                                return (
                                    <div key={item.id} className="titem" onClick={() => handleLinkJump({item, index})}>
                                        <div className="timg" style={{background: `url(${item.img}) no-repeat ${item.styles.lr} ${item.styles.tb}/cover`}} />
                                        <div className="tdesc">
                                            <div className="tag"><span>{item.tag}</span></div>
                                            <p className="ttext">{item.title}</p>
                                            <p className="ttime">{item.time}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="five" style={fiveBgM}>
                <div className="gamesBorderTextBox" ref={fiveDRenderDrawTextRefM}>
                    { dRenderDrawFlagM ? (
                        <DropAMessageText
                            config={{
                                css: {
                                    width: whCss(515),
                                    height: whCss(68),
                                }
                            }}
                        />
                    ) : null}
                </div>
                <div className="content">
                    <BlockContents config={{
                        title: localesReducer.home.pageFives.title,       // 标题
                        list: isLang ? [`${localesReducer.home.pageFives.subTitle[0]}${localesReducer.home.pageFives.subTitle[1]}`] : localesReducer.home.pageFives.subTitle,     // 副标题
                        flag: false,                 // 状态
                        alignItems: 'flex-end', // flex-start / flex-end
                        status: 1, // 状态 1， 2， 3
                        titleCss: {
                            fontSize: `${17 / 100}rem`,
                            color: '#B0A27C',
                            marginBottom: `${16 / 100}rem`,
                        },
                        listCss: {
                            width: '',
                            height: '',
                            fontSize: `${32 / 100}rem`,
                            textAlign: 'right',
                            color: '#FFF',
                            lineHeight: `${40 / 100}rem`
                        },
                        fLCss: {
                            firstColor: '#FFF',
                            lastColor: '',
                        }
                    }} />
                </div>
                <div className="ics">
                    {listIcsData.map((item) => {
                        return (
                            <div key={item.id} className="iitem">
                                <div className="iicon"><img src={item.img} alt="img" /></div>
                                <p className="ititle">{item.title}</p>
                                <p className="iemail" onClick={() => mailtoSubmit(item.email)}>{item.email}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="six">
                <WelcomeVisit
                    config={{
                        scrollNum: cshVal,
                    }}
                />
            </div>

        </div>
    );
};

if(Object.keys(localesReducer.home).length){
    return recReSizeReducer > 750 ? renderPC() : renderH5();
}else{
    return null;
}

});

export default Home;