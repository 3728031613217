import React, { memo, useEffect, useState, Suspense } from 'react';
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { actions } from '@/models/stores/actions';
import { recalcRM, handleScroll } from '../utils';
import { routers } from '../routers';
import Header from './Header';
import Footer from './Footer';

const Layouts = memo(() => {
    const [ scrollNum, setScrollNum ] = useState(0);
    const [ getCHSTopVal, setCHSTopVal ] = useState(0);
    const dispatch = useDispatch();
    const { selectScrollNum, handleRecReSize } = actions;
    const handleRecalcSize = () => {
        recalcRM({
            cab:(cwSize) => {
                dispatch(handleRecReSize({value: cwSize}));
            }
        });
        window.onresize = () => {
            recalcRM({
                cab:(cwSize) => {
                    dispatch(handleRecReSize({value: cwSize}));
                }
            });
        }
    }
    useEffect(() => {
        handleRecalcSize();

        handleScroll({
            cab: ({scrollVal, cHSTopVal}) => {
                setScrollNum(scrollVal);
                setCHSTopVal(cHSTopVal);
            }
        });

        dispatch(selectScrollNum({value: scrollNum, cshVal: getCHSTopVal}));
        
        return () => {
            handleRecalcSize();
            handleScroll({
                cab: ({scrollVal, cHSTopVal}) => {
                    setScrollNum(scrollVal);
                    setCHSTopVal(cHSTopVal);
                }
            });
            dispatch(selectScrollNum({value: scrollNum, cshVal: getCHSTopVal}));
        }
    }, [dispatch, getCHSTopVal, handleRecalcSize, scrollNum, selectScrollNum]);

    return (
        <Router>
            <Header scrollNum={scrollNum}/>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {
                        routers.map((routes, index) => {
                            return (
                                <Route key={index} {...routes} />
                            );
                        })
                    }
                    <Redirect to="/" />
                </Switch>
            </Suspense>
            <Footer />
        </Router>
    );
});

export default Layouts;