// home主页 start
import slogan from '../../assets/home/one/slogan_en.svg';
import cipwyh from '../../assets/home/one/cipwyh.svg';
// pageThree
// pageThree
import threeImg1_0 from "../../assets/home/three/gimg1/g100.png";
import threeImg2_0 from "../../assets/home/three/gimg2/g100.png";
import threeImg2_1 from "../../assets/home/three/gimg2/g101.png";
import threeImg3_0 from "../../assets/home/three/gimg3/g100.png";
import threeImg4_0 from "../../assets/home/three/gimg4/g100.png";
import threeImg5_0 from "../../assets/home/three/gimg5/g100.png";

import threeNewImg1_0 from "../../assets/home/three/gimg1/ng100.jpg";
import threeNewImg2_0 from "../../assets/home/three/gimg2/ng100.jpg";
import threeNewImg3_0 from "../../assets/home/three/gimg3/ng100.jpg";
import threeNewImg4_0 from "../../assets/home/three/gimg4/ng100.jpg";
import threeNewImg5_0 from "../../assets/home/three/gimg5/ng100.jpg";

// pageFour
import titem1 from "../../assets/home/four/titem_1.png";
import titem2 from "../../assets/home/four/titem_2.png";
import titem3 from "../../assets/home/four/titem_3.png";
import titem4 from "../../assets/home/four/titem_4.png";

// pageFives
import fiveIcon1 from "../../assets/home/five/icon_1.svg";
import fiveIcon2 from "../../assets/home/five/icon_2.svg";
import fiveIcon3 from "../../assets/home/five/icon_3.svg";
// home主页 end

// about关于我们 start
// pageOne
import since from "../../assets/about/card2/since.png";
import sinceh5 from "../../assets/about/card2/sinceh5.svg";
import istockphoto_1 from "../../assets/about/card3/istockphoto_1.jpeg";
import istockphoto_2 from "../../assets/about/card3/istockphoto_2.png";
// pageTwo
import culturesIcon01 from "../../assets/about/card4/cultures/c1001.svg";
import culturesIcon02 from "../../assets/about/card4/cultures/c1002.svg";
import culturesIcon03 from "../../assets/about/card4/cultures/c1003.svg";
import culturesIcon04 from "../../assets/about/card4/cultures/c1004.svg";
import linecirclefirst from "../../assets/about/card5/img_1/linecirclefirst.svg";
import linecirclefirsth5 from "../../assets/about/card5/img_1/linecirclefirstenh5.svg";
import timeImg1 from "../../assets/about/card5/img_1/img_1.png";
import timeImg2 from "../../assets/about/card5/img_1/img_2.png";
import timeImg3 from "../../assets/about/card5/img_1/img_3.png";
import timeImg4 from "../../assets/about/card5/img_1/img_4.png";
import timeImg5 from "../../assets/about/card5/img_1/img_5.png";
import timeImg6 from "../../assets/about/card5/img_1/img_6.png";
import linecirclelast from "../../assets/about/card5/img_1/linecirclelast.svg";
import linecirclelasth5 from "../../assets/about/card5/img_1/linecirclelastenh5.svg";
// about关于我们 end

// news新闻动态 start
import newsImg01 from "../../assets/news/list/news/titem_1.png";
import newsImg02 from "../../assets/news/list/news/titem_3.png";
import newsImg03 from "../../assets/news/list/news/titem_4.png";
import productImg01 from "../../assets/news/list/product/img01.png";
import productImg02 from "../../assets/news/list/product/img02.png";
import productImg03 from "../../assets/news/list/product/img03.png";
// news新闻动态 end

// game游戏产品 start
import img01 from "../../assets/game/list/img01.jpeg";
import img02 from "../../assets/game/list/img02.png";
import img03 from "../../assets/game/list/img03.png";
import img04 from "../../assets/game/list/img04.png";
import img05 from "../../assets/game/list/img05.png";

import imgNg01 from "../../assets/game/list/ng100.jpg";
import imgNg02 from "../../assets/game/list/ng200.jpg";
import imgNg03 from "../../assets/game/list/ng300.jpg";
// game游戏产品 end

// contact联系我们 end
import icon1 from '../../assets/contact/email/icon01.svg';
import icon2 from '../../assets/contact/email/icon02.svg';
import icon3 from '../../assets/contact/email/icon03.svg';
// contact联系我们 start

// join加入我们 start
import benefits1 from "../../assets/join/card1/list/benefits1.svg";
import benefits2 from "../../assets/join/card1/list/benefits2.svg";
import benefits3 from "../../assets/join/card1/list/benefits3.svg";
import benefits4 from "../../assets/join/card1/list/benefits4.svg";
import benefits5 from "../../assets/join/card1/list/benefits5.svg";
import benefits6 from "../../assets/join/card1/list/benefits6.svg";
import benefits7 from "../../assets/join/card1/list/benefits7.svg";
import benefits8 from "../../assets/join/card1/list/benefits8.svg";
// pageThree
import imga101 from "../../assets/join/card2/lista/img1.png";
import imga102 from "../../assets/join/card2/lista/img2.png";
import imga201 from "../../assets/join/card2/lista/img3.png";
import imga202 from "../../assets/join/card2/lista/img4.png";
import imga301 from "../../assets/join/card2/lista/img5.jpeg";
import imga302 from "../../assets/join/card2/lista/img6.png";


import imgb101 from "../../assets/join/card2/listb/img1.png";
import imgb102 from "../../assets/join/card2/listb/img2.png";
import imgb201 from "../../assets/join/card2/listb/img3.png";
import imgb202 from "../../assets/join/card2/listb/img4.png";
import imgb301 from "../../assets/join/card2/listb/img5.png";
import imgb302 from "../../assets/join/card2/listb/img6.png";
// pageFour
import program1 from "../../assets/join/card3/list/program1.svg";
import program2 from "../../assets/join/card3/list/program2.svg";
import program3 from "../../assets/join/card3/list/program3.svg";
import program4 from "../../assets/join/card3/list/program4.svg";
import program5 from "../../assets/join/card3/list/program5.svg";


// join加入我们 end

// game游戏产品 style start
const flexLeft = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}
const flexRight = {
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}
const mlr = {margin: `0 ${56 / 100}rem`}
// game游戏产品 style end

// global config file start
const {
  logos: {
    header,             // 头部导航 LOGO
    footer,             // 底部导航 LOGO
  },
  websiteRecord,        // 网站备案
  companyName,          // 公司名字
  companyAddress,       // 公司地址
  contactInformation,   // 联系方式
  emailsTo,             // 品牌合作/商业计划书/个人简历/招聘邮箱
} = window.globalConfig;
// global config file end

const enUS = {
  'navbar.lang': 'English',
  navbar: {
    headerLogo: header.wordImgEN,
    menus:[
      {
        id: 1001,
        name: 'HOME',
        pcw: 54,
        h5w: 50,
      },
      {
        id: 1002,
        name: 'ABOUT',
        pcw: 63,
        h5w: 53,
      },
      {
        id: 1003,
        name: 'NEWS',
        pcw: 54,
        h5w: 54,
      },
      {
        id: 1004,
        name: 'GAMES',
        pcw: 65,
        h5w: 65,
      },
      {
        id: 1005,
        name: 'CONTACT US',
        pcw: 118,
        h5w: 118,
      },
      {
        id: 1006,
        name: 'JOIN US',
        pcw: 73,
        h5w: 73,
      },
    ]
  },
  footer: {
    title: companyName.abbreviationEN,
    footerLogo: footer.logoEN,
    fameList:[
      'Healthy game advice: resist bad games and refuse pirated games. Pay attention to self-protection and beware of being cheated. Moderate games benefit the brain, addicted games hurt the body. Reasonable arrangement of time, enjoy a healthy life.',
      `${websiteRecord.en}`,
      `Internet illegal and undesirable information reporting phone number: ${contactInformation.reportPhone}`,
      `${companyName.fullNameEN} ©️Copyright`
    ],
    fameListMax:[
      'Healthy game advice: resist bad games and refuse pirated games. Pay attention to self-protection and beware of being cheated. Moderate games benefit the brain, addicted games hurt the body. Reasonable arrangement of time, enjoy a healthy life.',
      `${websiteRecord.en}`,
      `Internet illegal and undesirable information reporting phone number: ${contactInformation.reportPhone}`,
      `${companyName.fullNameEN} ©️Copyright`
    ],
  },
  home: {
    title: 'HOME',
    pageOne:{
      'slogan': slogan,
      'sloganM': cipwyh,
    },
    pageTwo: {
      title: companyName.abbreviationEN.replace('interaction', '').toUpperCase(),
      subTitle: [
        'Create joy and surprise for ',
        'players worldwide',
      ],
      texts: ['SINCE 2012', '____________'],
      desc: [
          'We have set up a strong R&D team, dedicated to independent development and localized distribution at home and abroad, to deliver more interesting game products to players around the world.',
      ],
      readMore: 'Read More',
    },
    pageThree: {
      plusMore: '+  MORE GAMES',
      threeNewTabsListData: [
        {
          id: 101,
          img: threeNewImg1_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg1_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: 'THE WEREWOLVES',
              subTitle: `THE WEREWOLVES is an O2O wolf killing game that combines both grid killing and face killing. Based on the conventional wolf killing on the grid, we break the space limitation of wolf killing, so that players can play seamlessly both offline and online at table games stores, thus achieving high profitability of seats at table games stores. `,
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'Casual Puzzle',
              },
              {
                name: 'Platform',
                desc: 'Android、iOS',
              },
              {
                name: 'Mode',
                desc: 'Multiplayer',
              },
              {
                name: 'Release',
                desc: '2021',
              },
            ],
          }
        },
        {
          id: 102,
          img: threeNewImg2_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg2_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: 'THE WEREWOLVES',
              subTitle: `THE WEREWOLVES is an O2O wolf killing game that combines both grid killing and face killing. Based on the conventional wolf killing on the grid, we break the space limitation of wolf killing, so that players can play seamlessly both offline and online at table games stores, thus achieving high profitability of seats at table games stores. `,
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'Casual Puzzle',
              },
              {
                name: 'Platform',
                desc: 'Android、iOS',
              },
              {
                name: 'Mode',
                desc: 'Multiplayer',
              },
              {
                name: 'Release',
                desc: '2021',
              },
            ],
          }
        },
        {
          id: 103,
          img: threeNewImg3_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg3_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: 'THE WEREWOLVES',
              subTitle: `THE WEREWOLVES is an O2O wolf killing game that combines both grid killing and face killing. Based on the conventional wolf killing on the grid, we break the space limitation of wolf killing, so that players can play seamlessly both offline and online at table games stores, thus achieving high profitability of seats at table games stores. `,
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'Casual Puzzle',
              },
              {
                name: 'Platform',
                desc: 'Android、iOS',
              },
              {
                name: 'Mode',
                desc: 'Multiplayer',
              },
              {
                name: 'Release',
                desc: '2021',
              },
            ],
          }
        },
        {
          id: 104,
          img: threeNewImg4_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg4_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: 'THE WEREWOLVES',
              subTitle: `THE WEREWOLVES is an O2O wolf killing game that combines both grid killing and face killing. Based on the conventional wolf killing on the grid, we break the space limitation of wolf killing, so that players can play seamlessly both offline and online at table games stores, thus achieving high profitability of seats at table games stores. `,
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'Casual Puzzle',
              },
              {
                name: 'Platform',
                desc: 'Android、iOS',
              },
              {
                name: 'Mode',
                desc: 'Multiplayer',
              },
              {
                name: 'Release',
                desc: '2021',
              },
            ],
          }
        },
        {
          id: 105,
          img: threeNewImg5_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg5_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: 'THE WEREWOLVES',
              subTitle: `THE WEREWOLVES is an O2O wolf killing game that combines both grid killing and face killing. Based on the conventional wolf killing on the grid, we break the space limitation of wolf killing, so that players can play seamlessly both offline and online at table games stores, thus achieving high profitability of seats at table games stores. `,
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'Casual Puzzle',
              },
              {
                name: 'Platform',
                desc: 'Android、iOS',
              },
              {
                name: 'Mode',
                desc: 'Multiplayer',
              },
              {
                name: 'Release',
                desc: '2021',
              },
            ],
          }
        },
      ],
      threeTabsListData: [
        {
          id: 101,
          img: threeImg1_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeImg1_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: 'THE OUTER WORLDS',
              subTitle: 'The Outer Worlds is an award-winning single-player RPG from Obsidian Entertainment and Private Division. As you explore a space colony, the character you decide to become will determine how this player-driven story unfolds. In the colony\'s corporate equation, you are the unplanned variable.',
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'RPG',
              },
              {
                name: 'Platform',
                desc: 'PC、PS4、Xbox One、Switch',
              },
              {
                name: 'Mode',
                desc: 'Single-player',
              },
              {
                name: 'Release',
                desc: '2021/08/10',
              },
            ],
          }
        },
        {
          id: 102,
          img: threeImg2_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `64%`,
            lr: `0%`
          },
          contents:{
            img: threeImg2_1,
            styles:{
              lr: `100%`,
              tb: `65%`,
            },
            desc: {
              title: 'OVERWATCH',
              subTitle: 'Reunite and stand together in a new age of heroes. Overwatch® 2 builds on an award-winning foundation of epic competitive play, and challenges the world’s heroes to team up, power up, and take on an overwhelming outbreak of threats around the globe.',
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'FPS',
              },
              {
                name: 'Platform',
                desc: 'PC、Mobile、PS4、Xbox One、Switch',
              },
              {
                name: 'Mode',
                desc: 'Multiplayer',
              },
              {
                name: 'Release',
                desc: '2021/08/10',
              },
            ],
          }
        },
        {
          id: 103,
          img: threeImg3_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `52%`,
            lr: `0%`
          },
          contents:{
            img: threeImg3_0,
            styles:{
              lr: `100%`,
              tb: `50%`,
            },
            desc: {
              title: 'DESTINY 2',
              subTitle: 'Destiny 2 is an action MMO with a single evolving world. Dive into the world of Destiny 2 to explore the solar system and experience responsive first-person shooter combat. Unlock elemental abilities and collect gear to customize your Guardian!',
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'FPS、MMORPG',
              },
              {
                name: 'Platform',
                desc: 'PC、PS4、Xbox One、Switch',
              },
              {
                name: 'Mode',
                desc: 'Single-player',
              },
              {
                name: 'Release',
                desc: '2021/08/10',
              },
            ],
          }
        },
        {
          id: 104,
          img: threeImg4_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `5%`,
            lr: `0%`
          },
          contents:{
            img: threeImg4_0,
            styles:{
              lr: `100%`,
              tb: `22%`,
            },
            desc: {
              title: 'GODFALL',
              subTitle: 'Aperion is on the precipice of ruin. You are the last of the Valorian knights, god-like warriors able to equip Valorplates, legendary armor sets that transform wielders into unstoppable masters of melee combat.',
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'RPG',
              },
              {
                name: 'Platform',
                desc: 'PC、PS4',
              },
              {
                name: 'Mode',
                desc: 'Single-player、Multiplayer',
              },
              {
                name: 'Release',
                desc: '2021/08/10',
              },
            ],
          }
        },
        {
          id: 105,
          img: threeImg5_0,
          title: 'PRODUCT',
          subTitle: [
            'Create a new era of ',
            'interactive entertainment',
            'for all'
          ],
          styles:{
            tb: `40%`,
            lr: `0%`
          },
          contents:{
            img: threeImg5_0,
            styles:{
              lr: `100%`,
              tb: `100%`,
            },
            desc: {
              title: 'STEEP',
              subTitle: 'Ride a massive open world of the Alps and Alaska, where the powder is always fresh and the run never ends. Defy and master the world’s most epic mountains with skis, wingsuit, snowboard, and paraglider.',
              btnText: 'Website',
              url: '',
            },
            list: [
              {
                name: 'Genere',
                desc: 'Sports',
              },
              {
                name: 'Platform',
                desc: 'PC、PS4、Xbox One',
              },
              {
                name: 'Mode',
                desc: 'Single-player、Multiplayer',
              },
              {
                name: 'Release',
                desc: '2021/08/10',
              },
            ],
          }
        },
      ]
    },
    pageFour: {
      title: 'NEWS',
      subTitle: [
        'Stay up to date',
        'with the latest events',
      ],
      listFourData: [
        {
          id: 101,
          img: titem1,
          tag: 'Company',
          title: 'Super Combustion debuted at ChinaJoy, with fantasies and interactive',
          time: '2021.07.21',
          styles: {
            tb: `58%`,
            lr: `100%`,
          }
        },
        {
          id: 102,
          img: titem2,
          tag: 'Product',
          title: `${companyName.abbreviationEN} Official Account Game Pack is now online!`,
          time: '2021.05.13',
          styles: {
            tb: `58%`,
            lr: `100%`,
          }
        },
        {
          id: 103,
          img: titem3,
          tag: 'Company',
          title: `${companyName.abbreviationEN} brings a variety of new boutique mobile games to`,
          time: '2021.03.11',
          styles: {
            tb: `58%`,
            lr: `100%`,
          }
        },
        {
          id: 104,
          img: titem4,
          tag: 'Company',
          title: 'Statement on criminals fraudulently using the name of the company to',
          time: '2021.02.08',
          styles: {
            tb: `58%`,
            lr: `100%`,
          }
        },
      ]
    },
    pageFives: {
      title: 'CONTACT US',
      subTitle: [
        'You are welcome to contact us',
        'through the following ways',
      ],
      listIcsData: [
        {
          id: 101,
          img: fiveIcon1,
          title: 'Business Cooperation',
          email: emailsTo.businessCooperation,
        },
        {
          id: 102,
          img: fiveIcon2,
          title: 'Customer Service',
          email: emailsTo.customerService,
        },
        {
          id: 103,
          img: fiveIcon3,
          title: 'Resume Delivery',
          email: emailsTo.resumeDelivery,
        },
      ]
    },
    pageSix: {
      title: 'FIND US',
      subTitle: [
        'Welcome to visit,',
        'we are at 23°N,113°E',
      ],
      info: {
        nation: contactInformation.countryRegionEN,
        list: [
          {
            name: 'Address',
            desc: companyAddress.addressEN,
          },
          {
            name: 'Phone',
            desc: contactInformation.tel,
          },
          {
            name: 'Fax',
            desc: contactInformation.fox,
          },
        ]
      }
    },
  },
  about: {
    title: 'ABOUT',
    pageOne:{
      title: 'ABOUT US',
      subTitle: [
        'Let the game have',
        'unlimited possibilities',
      ],
      info: {
        since: {
          title: 'SINCE 2012',
          desc: `${companyName.fullNameEN} was established in May 2012, and its business covers the entire game industry chain, including game platforms, game publishing, and game research and development. At the same time, with creativity as the core and quality as the foundation, we will create a highly competitive game ecosystem and continue to create high-quality content and interactive experiences for fans.`,
          img: since,
          imgH: sinceh5,
        },
        la: {
          title: 'Leading Advantages',
          desc: `${companyName.abbreviationEN} is based on the domestic market, while actively deploying overseas. Relying on our game quality advantages, complete R&D and operational strength, we have successively launched a number of high-quality mobile games that are popular around the world, with a cumulative user volume of more than 200 million, and have formed a great influence among players. ${companyName.abbreviationEN}'s game distribution map covers mainstream game markets in China, Japan, South Korea, Southeast Asia, Europe, America, and Russia, and has established its own core competitiveness and leading advantages in overseas markets.`,
          img: istockphoto_1,
        },
        cv: {
          title: 'Core Value',
          desc: 'Adhering to the corporate philosophy of efficiency, focus, innovation, and respect, we have gathered a high-quality, high-cohesive management team and a professional and efficient technical team. In the future, we will take product research and development as the core value of corporate development, and successively launch a series of leading products with different cultural attributes, gameplay cores and market positioning, to provide game players around the world with endless fun entertainment experience.',
          img: istockphoto_2,
        }
      }
    },
    pageTwo: {
      title: 'COMPANY CULTURE',
      subTitle: [
        'User-centered, ',
        'product-centered',
      ],
      cultureListData: [
        {
          id: 1001,
          mTop: 0,
          card: {
            img: culturesIcon01,
            title: '高效',
            text: 'Efficient'
          },
          desc: {
            line: '––',
            num: '01',
            text: 'Focus on the target and execute efficiently.',
          }
        },
        {
          id: 1002,
          mTop: 40,
          card: {
            img: culturesIcon02,
            title: '专注',
            text: 'Focus'
          },
          desc: {
            line: '––',
            num: '02',
            text: 'If you have a certain heart, you will have a reward.',
          }
        },
        {
          id: 1003,
          mTop: 80,
          card: {
            img: culturesIcon03,
            title: '创新',
            text: 'Innovation'
          },
          desc: {
            line: '––',
            num: '03',
            text: 'Always curious, always love.',
          }
        },
        {
          id: 1004,
          mTop: 120,
          card: {
            img: culturesIcon04,
            title: '尊重',
            text: 'Respect'
          },
          desc: {
            line: '––',
            num: '04',
            text: 'Respect the opponent, appreciate the opponent.',
          }
        },
      ],
    },
    pageThree: {
      title: 'DEVELOPMENT PATH',
      subTitle: [
        'Keeping up with the times, ',
        'our journey is full of challenges',
      ],
      timelineListData: [
        {
          id: 1001,
          year: '2012',
          month: 'JUL.',
          desc: `${companyName.fullNameEN} was established in Guangzhou, focusing on the research and development of mobile games.`,
          img: timeImg1,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1002,
          year: '2015',
          month: 'FEB.',
          desc: 'The card product series was launched, ranking Top30 for a long time in party-type games in the global mainstream App market.',
          img: timeImg2,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1003,
          year: '2016',
          month: 'APR.',
          desc: 'It received angel round investment, and the company successfully transformed from a pure developer to a game manufacturer integrating research and transportation.',
          img: timeImg3,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1004,
          year: '2018',
          month: 'NOV.',
          desc: 'An overseas market operation team was established to start the overseas development journey',
          img: timeImg4,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1005,
          year: '2019',
          month: 'FEB.',
          desc: 'There are hundreds of classic games and more than 200 million users worldwide',
          img: timeImg5,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1006,
          year: '2020',
          month: 'OCT.',
          desc: 'The launch of the new product on the market received a huge response, and the App Store game rankings ranked top in many regions, and the sustainable development ability was verified',
          img: timeImg6,
          line: linecirclelast,
          lineH5: linecirclelasth5,
          lineHeight: 326,
        },
      ]
    },
  },
  news: {
    title: 'NEWS',
    subTitle: 'NEWS/DETAIL',
    pageOne:{
      title: 'NEWS',
      subTitle: [
        'Stay up to date',
        'with the latest events',
      ],
      loadMore: 'More',
      tabListData:[
        {
          id: 1001,
          title: 'All',
          type: 'all',
          list: [],
        },
        {
          id: 1002,
          title: 'Company',
          type: 'news',
          list: [
            {
              id: 100201,
              type: 'news',
              img: newsImg01,
              tag: 'Company',
              title: 'Super Combustion debuted at ChinaJoy, with fantasies and interactive',
              desc: 'On July 31, 2020 ChinaJoy officially opened at the Shanghai New International Expo Center. Huan xiang...',
              time: '2021.07.21',
              list: [
                `The most authoritative selection activity in China's game industry-the 2020 Excellent Game Selection Competition (the 15th Golden Ling Award) has now officially kicked off. For fifteen years, the Golden Ling Award, as an industry event that "gathers the love of players", has the reputation of the "Oscar" in the domestic game industry, and has been widely recognized by the Chinese game industry for its authority and fairness.`,
                `${companyName.abbreviationEN} will bring a variety of its game products to this annual event in the game industry with game enthusiasts across the country, competing for the 2020 Golden Ling Award "players' most anticipated mobile online game" and "players' favorite mobile online game" Awards.`,
                `${companyName.abbreviationEN} is based on the domestic market, while actively deploying overseas, and quickly completed the combing and integration of the three major business lines of R&D, operation, and platform, and established a complete industrialized R&D and refined operation system, and created a system including product project, A complete industrial chain including IP adaptation, testing and tuning, promotion and distribution, and long-term operations. We have been committed to providing users with more high-quality entertainment content and services, and following the development of the times and the industry, we have developed several mobile games of various types and themes. Adhering to the integrated concept of high-quality research and development and professional distribution, we will continue to contribute fun and high-quality games to players.`,
                `Since its establishment in 2005, the Golden Ling Award has made great contributions to the development of the domestic game industry, and has also become a vane for domestic players to understand the ranking of game products and manufacturers to express their product concepts. In order to fully reflect the aspirations of the majority of gamers, the 2020 Golden Ling Awards will still be selected by national players through online voting to select the final winning works, manufacturers and media. We look forward to players casting votes for their favorite excellent products and media. We also believe that in the current Golden Ling Awards competition, ${companyName.abbreviationEN} will not disappoint everyone's expectations!`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
            {
              id: 100202,
              type: 'news',
              img: newsImg02,
              tag: 'Company',
              title: `${companyName.abbreviationEN} brings a variety of new boutique mobile games to`,
              desc: `The most authoritative selection activity in China's game industry-the 2020 Excellent Game Selection...`,
              time: '2021.03.11',
              list: [
                `The most authoritative selection activity in China's game industry-the 2020 Excellent Game Selection Competition (the 15th Golden Ling Award) has now officially kicked off. For fifteen years, the Golden Ling Award, as an industry event that "gathers the love of players", has the reputation of the "Oscar" in the domestic game industry, and has been widely recognized by the Chinese game industry for its authority and fairness.`,
                `${companyName.abbreviationEN} will bring a variety of its game products to this annual event in the game industry with game enthusiasts across the country, competing for the 2020 Golden Ling Award "players' most anticipated mobile online game" and "players' favorite mobile online game" Awards.`,
                `${companyName.abbreviationEN} is based on the domestic market, while actively deploying overseas, and quickly completed the combing and integration of the three major business lines of R&D, operation, and platform, and established a complete industrialized R&D and refined operation system, and created a system including product project, A complete industrial chain including IP adaptation, testing and tuning, promotion and distribution, and long-term operations. We have been committed to providing users with more high-quality entertainment content and services, and following the development of the times and the industry, we have developed several mobile games of various types and themes. Adhering to the integrated concept of high-quality research and development and professional distribution, we will continue to contribute fun and high-quality games to players.`,
                `Since its establishment in 2005, the Golden Ling Award has made great contributions to the development of the domestic game industry, and has also become a vane for domestic players to understand the ranking of game products and manufacturers to express their product concepts. In order to fully reflect the aspirations of the majority of gamers, the 2020 Golden Ling Awards will still be selected by national players through online voting to select the final winning works, manufacturers and media. We look forward to players casting votes for their favorite excellent products and media. We also believe that in the current Golden Ling Awards competition, ${companyName.abbreviationEN} will not disappoint everyone's expectations!`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
            {
              id: 100203,
              type: 'news',
              img: newsImg03,
              tag: 'Company',
              title: 'Statement on criminals fraudulently using the name of the company to',
              desc: `Recently, criminals fraudulently used the name of Guangzhou ${companyName.abbreviationEN} Technology Co.,...`,
              time: '2021.02.08',
              list: [
                `The most authoritative selection activity in China's game industry-the 2020 Excellent Game Selection Competition (the 15th Golden Ling Award) has now officially kicked off. For fifteen years, the Golden Ling Award, as an industry event that "gathers the love of players", has the reputation of the "Oscar" in the domestic game industry, and has been widely recognized by the Chinese game industry for its authority and fairness.`,
                `${companyName.abbreviationEN} will bring a variety of its game products to this annual event in the game industry with game enthusiasts across the country, competing for the 2020 Golden Ling Award "players' most anticipated mobile online game" and "players' favorite mobile online game" Awards.`,
                `${companyName.abbreviationEN} is based on the domestic market, while actively deploying overseas, and quickly completed the combing and integration of the three major business lines of R&D, operation, and platform, and established a complete industrialized R&D and refined operation system, and created a system including product project, A complete industrial chain including IP adaptation, testing and tuning, promotion and distribution, and long-term operations. We have been committed to providing users with more high-quality entertainment content and services, and following the development of the times and the industry, we have developed several mobile games of various types and themes. Adhering to the integrated concept of high-quality research and development and professional distribution, we will continue to contribute fun and high-quality games to players.`,
                `Since its establishment in 2005, the Golden Ling Award has made great contributions to the development of the domestic game industry, and has also become a vane for domestic players to understand the ranking of game products and manufacturers to express their product concepts. In order to fully reflect the aspirations of the majority of gamers, the 2020 Golden Ling Awards will still be selected by national players through online voting to select the final winning works, manufacturers and media. We look forward to players casting votes for their favorite excellent products and media. We also believe that in the current Golden Ling Awards competition, ${companyName.abbreviationEN} will not disappoint everyone's expectations!`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
          ],
        },
        {
          id: 1003,
          title: 'Product',
          type: 'product',
          list: [
            {
              id: 100301,
              type: 'product',
              img: productImg01,
              tag: 'Product',
              title: `${companyName.abbreviationEN} Official Account Game Pack is now online!`,
              desc: 'In order to facilitate the players to receive the gift packs of various games, the function of Huan xiang...',
              time: '2021.05.13',
              list: [
                `The most authoritative selection activity in China's game industry-the 2020 Excellent Game Selection Competition (the 15th Golden Ling Award) has now officially kicked off. For fifteen years, the Golden Ling Award, as an industry event that "gathers the love of players", has the reputation of the "Oscar" in the domestic game industry, and has been widely recognized by the Chinese game industry for its authority and fairness.`,
                `${companyName.abbreviationEN} will bring a variety of its game products to this annual event in the game industry with game enthusiasts across the country, competing for the 2020 Golden Ling Award "players' most anticipated mobile online game" and "players' favorite mobile online game" Awards.`,
                `${companyName.abbreviationEN} is based on the domestic market, while actively deploying overseas, and quickly completed the combing and integration of the three major business lines of R&D, operation, and platform, and established a complete industrialized R&D and refined operation system, and created a system including product project, A complete industrial chain including IP adaptation, testing and tuning, promotion and distribution, and long-term operations. We have been committed to providing users with more high-quality entertainment content and services, and following the development of the times and the industry, we have developed several mobile games of various types and themes. Adhering to the integrated concept of high-quality research and development and professional distribution, we will continue to contribute fun and high-quality games to players.`,
                `Since its establishment in 2005, the Golden Ling Award has made great contributions to the development of the domestic game industry, and has also become a vane for domestic players to understand the ranking of game products and manufacturers to express their product concepts. In order to fully reflect the aspirations of the majority of gamers, the 2020 Golden Ling Awards will still be selected by national players through online voting to select the final winning works, manufacturers and media. We look forward to players casting votes for their favorite excellent products and media. We also believe that in the current Golden Ling Awards competition, ${companyName.abbreviationEN} will not disappoint everyone's expectations!`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
            {
              id: 100302,
              type: 'product',
              img: productImg02,
              tag: 'Product',
              title: `${companyName.abbreviationEN} 2021 Labor Day event introduction`,
              desc: 'Labor Day is here! Here I wish you all a happy holiday! In order to thank all the players for their continued...',
              time: '2021.05.01',
              list: [
                `The most authoritative selection activity in China's game industry-the 2020 Excellent Game Selection Competition (the 15th Golden Ling Award) has now officially kicked off. For fifteen years, the Golden Ling Award, as an industry event that "gathers the love of players", has the reputation of the "Oscar" in the domestic game industry, and has been widely recognized by the Chinese game industry for its authority and fairness.`,
                `${companyName.abbreviationEN} will bring a variety of its game products to this annual event in the game industry with game enthusiasts across the country, competing for the 2020 Golden Ling Award "players' most anticipated mobile online game" and "players' favorite mobile online game" Awards.`,
                `${companyName.abbreviationEN} is based on the domestic market, while actively deploying overseas, and quickly completed the combing and integration of the three major business lines of R&D, operation, and platform, and established a complete industrialized R&D and refined operation system, and created a system including product project, A complete industrial chain including IP adaptation, testing and tuning, promotion and distribution, and long-term operations. We have been committed to providing users with more high-quality entertainment content and services, and following the development of the times and the industry, we have developed several mobile games of various types and themes. Adhering to the integrated concept of high-quality research and development and professional distribution, we will continue to contribute fun and high-quality games to players.`,
                `Since its establishment in 2005, the Golden Ling Award has made great contributions to the development of the domestic game industry, and has also become a vane for domestic players to understand the ranking of game products and manufacturers to express their product concepts. In order to fully reflect the aspirations of the majority of gamers, the 2020 Golden Ling Awards will still be selected by national players through online voting to select the final winning works, manufacturers and media. We look forward to players casting votes for their favorite excellent products and media. We also believe that in the current Golden Ling Awards competition, ${companyName.abbreviationEN} will not disappoint everyone's expectations!`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
            {
              id: 100303,
              type: 'product',
              img: productImg03,
              tag: 'Product',
              title: `${companyName.abbreviationEN} mobile game is recommended by South Korea’s Google`,
              desc: 'With the rapid development of the mobile game industry and intensified market competition, the boom...',
              time: '2020.12.08',
              list: [
                `The most authoritative selection activity in China's game industry-the 2020 Excellent Game Selection Competition (the 15th Golden Ling Award) has now officially kicked off. For fifteen years, the Golden Ling Award, as an industry event that "gathers the love of players", has the reputation of the "Oscar" in the domestic game industry, and has been widely recognized by the Chinese game industry for its authority and fairness.`,
                `${companyName.abbreviationEN} will bring a variety of its game products to this annual event in the game industry with game enthusiasts across the country, competing for the 2020 Golden Ling Award "players' most anticipated mobile online game" and "players' favorite mobile online game" Awards.`,
                `${companyName.abbreviationEN} is based on the domestic market, while actively deploying overseas, and quickly completed the combing and integration of the three major business lines of R&D, operation, and platform, and established a complete industrialized R&D and refined operation system, and created a system including product project, A complete industrial chain including IP adaptation, testing and tuning, promotion and distribution, and long-term operations. We have been committed to providing users with more high-quality entertainment content and services, and following the development of the times and the industry, we have developed several mobile games of various types and themes. Adhering to the integrated concept of high-quality research and development and professional distribution, we will continue to contribute fun and high-quality games to players.`,
                `Since its establishment in 2005, the Golden Ling Award has made great contributions to the development of the domestic game industry, and has also become a vane for domestic players to understand the ranking of game products and manufacturers to express their product concepts. In order to fully reflect the aspirations of the majority of gamers, the 2020 Golden Ling Awards will still be selected by national players through online voting to select the final winning works, manufacturers and media. We look forward to players casting votes for their favorite excellent products and media. We also believe that in the current Golden Ling Awards competition, ${companyName.abbreviationEN} will not disappoint everyone's expectations!`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
          ],
        },
      ]
    },
  },
  game: {
    title: 'GAMES',
    pageOne:{
      title: 'PRODUCT',
      subTitle: [
        'Create a new era of interactive',
        'entertainment for all',
      ],
      listNewData: [
        {
          id: 1001,
          title: 'THE WEREWOLVES',
          en: 'THE WEREWOLVES',
          direction: 'left',
          img: imgNg01,
          subLineText: {
            role: 'Casual Puzzle',
            party: 'Multiplayer',
            platform: 'Android、iOS',
          },
          desc: `THE WEREWOLVES is an O2O wolf killing game that combines both grid killing and face killing. Based on the conventional wolf killing on the grid, we break the space limitation of wolf killing, so that players can play seamlessly both offline and online at table games stores, thus achieving high profitability of seats at table games stores. `,
          btnText: 'Website',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          },
        },
        {
          id: 1002,
          title: '',
          en: '',
          direction: 'left',
          img: imgNg02,
          subLineText: {
            role: '',
            party: '',
            platform: '',
          },
          desc: `At the same time, in order to enhance the gaming experience of players in offline table games stores, we also provide abundant intelligent ambient hardware devices and game sound effects and solve the problem of human effort cost of wolf and wolf killing judges in offline stores. This This undoubtedly also adds to the special features of the store for table games stores. At present, the game offers 35 different wolf-measure boards, and additional ones are still being added. In the future, we will also introduce script killing to further satisfy the needs and gaming experience of the offline board game market. `,
          btnText: '',
          url: '',
          css: {
            flex: flexRight,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          },
        },
        {
          id: 1003,
          title: '',
          en: '',
          direction: 'left',
          img: imgNg03,
          subLineText: {
            role: '',
            party: '',
            platform: '',
          },
          desc: `In addition to being a game designed for wolf killing players, THE WEREWOLVES is also a product designed for offline table games businesses. In addition to enriching the game experience and adding special features to the store, we also provide a series of benefits such as revenue sharing around the game, profit sharing from wireless traffic and online store display to attract traffic, aiming to enhance the profitability and customer flow of the store.`,
          btnText: '',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          },
        },
      ],
      listData: [
        {
          id: 1001,
          title: '天外世界',
          en: 'THE OUTER WORLDS',
          direction: 'left',
          img: img01,
          subLineText: {
            role: 'RPG',
            party: 'Single-player',
            platform: 'PC、PS4、Xbox One、Switch',
          },
          desc: `The Outer Worlds is an award-winning single-player RPG from Obsidian Entertainment and Private Division. As you explore a space colony, the character you decide to become will determine how this player-driven story unfolds. In the colony's corporate equation, you are the unplanned variable.`,
          btnText: 'Website',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          },
        },
        {
          id: 1002,
          title: '守望先锋',
          en: 'OVERWATCH',
          direction: 'left',
          img: img02,
          subLineText: {
            role: 'FPS',
            party: 'Multiplayer',
            platform: 'PC、Mobile、PS4、Xbox One、Switch',
          },
          desc: `Reunite and stand together in a new age of heroes. Overwatch® 2 builds on an award-winning foundation of epic competitive play, and challenges the world’s heroes to team up, power up, and take on an overwhelming outbreak of threats around the globe.`,
          btnText: 'Website',
          url: '',
          css: {
            flex: flexRight,
            margin: mlr,
            tb: '50%',
            lr: '100%',
          },
        },
        {
          id: 1003,
          title: '命运2',
          en: 'DESTINY 2',
          direction: 'left',
          img: img03,
          subLineText: {
            role: 'FPS、MMORPG',
            party: 'Single-player',
            platform: 'PC、PS4、Xbox One、Switch',
          },
          desc: `Destiny 2 is an action MMO with a single evolving world. Dive into the world of Destiny 2 to explore the solar system and experience responsive first-person shooter combat. Unlock elemental abilities and collect gear to customize your Guardian!`,
          btnText: 'Website',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '47%',
            lr: '100%',
          },
        },
        {
          id: 1004,
          title: '众神陨落',
          en: 'GODFALL',
          direction: 'left',
          img: img04,
          subLineText: {
            role: 'RPG',
            party: 'Single-player、Multiplayer',
            platform: 'PC、PS4',
          },
          desc: `Aperion is on the precipice of ruin. You are the last of the Valorian knights, god-like warriors able to equip Valorplates, legendary armor sets that transform wielders into unstoppable masters of melee combat. `,
          btnText: 'Website',
          url: '',
          css: {
            flex: flexRight,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          },
        },
        {
          id: 1005,
          title: '极限巅峰',
          en: 'STEEP',
          direction: 'left',
          img: img05,
          subLineText: {
            role: 'Sports',
            party: 'Single-player、Multiplayer',
            platform: 'PC、PS4、Xbox One',
          },
          desc: `Ride a massive open world of the Alps and Alaska, where the powder is always fresh and the run never ends. Defy and master the world’s most epic mountains with skis, wingsuit, snowboard, and paraglider. `,
          btnText: 'Website',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '82%',
            lr: '100%',
          },
        },
      ],
    },
  },
  contact: {
    title: 'CONTACT US',
    pageOne:{
      title: 'CONTACT US',
      subTitle: [
        'You are welcome to contact us ',
        'through the following ways',
      ],
      list: [
        {
          id: 1001,
          title: 'Business Cooperation',
          email: emailsTo.businessCooperation,
          icon: icon1,
        },
        {
          id: 1002,
          title: 'Customer Service',
          email: emailsTo.customerService,
          icon: icon2,
        },
        {
          id: 1003,
          title: 'Resume Delivery',
          email: emailsTo.recruitmentEmail,
          icon: icon3,
        },
      ]
    },
    pageTwo: {},
    pageThree: {},
    pageFour: {},
    pageFives: {},
    pageSix: {},
    pageSeven: {},
    pageEight: {},
    pageNine: {},
    pageTen: {},
  },
  join: {
    title: 'JOIN US',
    subTitle: 'JOIN US/DETAIL',
    pageOne:{
      subTitle: [
        'Looking for excellent you to create',
        'unlimited',
        'future together!',
      ],
    },
    pageTwo: {
      title: 'COMPANY BENEFITS',
      subTitle: [
        'Compensation, benefits, development, we meet all your expectations!',
      ],
      benefitsListData: [
        {
          id: 1001,
          icon: benefits1,
          title: 'Salary & Welfare',
          list: [
            'Annual salary adjustment ',
            'Incentive bonus'
          ],
        },
        {
          id: 1002,
          icon: benefits2,
          title: 'Insurance & Housing Fund',
          list: [
            'Pay in accordance with national',
            'standards, ensuring safety'
          ],
        },
        {
          id: 1003,
          icon: benefits3,
          title: 'Flexible Work',
          list: [
            'Flexible work system to avoid',
            'peak periods'
          ],
        },
        {
          id: 1004,
          icon: benefits4,
          title: 'Career Development',
          list: [
            'Organize various trainings, there',
            'is huge room for promotion'
          ],
        },
        {
          id: 1005,
          icon: benefits5,
          title: 'Living Welfare',
          list: [
            'Free snacks and beverages, working',
            'meal subsidy'
          ],
        },
        {
          id: 1006,
          icon: benefits6,
          title: 'Holiday Benefits',
          list: [
            'Every festive season, there are gifts',
          ],
        },
        {
          id: 1007,
          icon: benefits7,
          title: 'Annual Benefits',
          list: [
            'Health checkup, annual luxury tour',
          ],
        },
        {
          id: 1008,
          icon: benefits8,
          title: 'Team Activities',
          list: [
            'Spring outing club activities,',
            'sports meet up'
          ],
        },
      ]
    },
    pageThree: {
      title: 'OFFICE ENVIRONMENT',
      subTitle: [
        'You have to be comfortable in a place',
        'where you have to stay for 8 hours a day',
      ],
      oneOfficeEnvironmentData: [
        {
          id: 1001,
          desc: `The functional design and space division of the office are scientific, focusing on environmental protection and safety. All furniture strictly adopts imported E0-grade panels, which meet the top international environmental protection standards.`,
          list: [
            {
              key: 'img01',
              imgFirst: imga101,
              imgNext: imga102,
              style: {
                tb: '100%',
                lr: '50%',
              },
            },
            {
              key: 'img02',
              imgFirst: imga201,
              imgNext: imga202,
              style: {
                tb: '35%',
                lr: '100%',
              },
            },
            {
              key: 'img03',
              imgFirst: imga301,
              imgNext: imga302,
              style: {
                tb: '55%',
                lr: '100%',
              },
            },
          ],
        },
        {
          id: 1002,
          desc: `The functional design and space division of the office are scientific, focusing on environmental protection and safety. All furniture strictly adopts imported E0-grade panels, which meet the top international environmental protection standards.`,
          list: [
            {
              key: 'img01',
              imgFirst: imga101,
              imgNext: imga102,
              style: {
                tb: '100%',
                lr: '50%',
              },
            },
            {
              key: 'img02',
              imgFirst: imga201,
              imgNext: imga202,
              style: {
                tb: '35%',
                lr: '100%',
              },
            },
            {
              key: 'img03',
              imgFirst: imga301,
              imgNext: imga302,
              style: {
                tb: '55%',
                lr: '100%',
              },
            },
          ],
        },
      ],
      twoOfficeEnvironmentData: [
        {
          id: 1001,
          desc: `The public area has many composite functional designs, and modules such as leisure area, pantry, maternity and baby room, and game area are all available, which fully embodies the people-oriented value concept of contemporary Internet companies.`,
          list: [
            {
              key: 'img01',
              imgFirst: imgb101,
              imgNext: imgb102,
              style: {
                tb: '100%',
                lr: '55%',
              },
            },
            {
              key: 'img02',
              imgFirst: imgb201,
              imgNext: imgb202,
              style: {
                tb: '100%',
                lr: '100%',
              },
            },
            {
              key: 'img03',
              imgFirst: imgb301,
              imgNext: imgb302,
              style: {
                tb: '100%',
                lr: '100%',
              },
            },
          ],
        },
        {
          id: 1002,
          desc: `The public area has many composite functional designs, and modules such as leisure area, pantry, maternity and baby room, and game area are all available, which fully embodies the people-oriented value concept of contemporary Internet companies.`,
          list: [
            {
              key: 'img01',
              imgFirst: imgb101,
              imgNext: imgb102,
              style: {
                tb: '100%',
                lr: '55%',
              },
            },
            {
              key: 'img02',
              imgFirst: imgb201,
              imgNext: imgb202,
              style: {
                tb: '100%',
                lr: '100%',
              },
            },
            {
              key: 'img03',
              imgFirst: imgb301,
              imgNext: imgb302,
              style: {
                tb: '100%',
                lr: '100%',
              },
            },
          ],
        },
      ]
    },
    pageFour: {
      title: 'JOB LIST',
      subTitle: [
        ' Looking for excellent you to create',
        'unlimited future together!',
      ],
      programListData: [
        {
          id: 101,
          icon: program1,
          title: 'Program',
          thead: [
            {id: 1, title: 'Position'},
            {id: 2, title: 'Release'},
            {id: 3, title: 'Headcount'},
          ],
          list: [
            {
              key: 10101,
              positionName: 'C++ server development engineer', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: 'POSITION DETAIL',
              address: 'Guangzhou',
              emailName: 'Recruitment email',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: 'Job Responsibilities',
                  descList: [
                    `1. Design and implement the corresponding functions of the game server;`,
                    `2. Optimize the performance logic of the game server;`,
                    `3. Make and improve related game tools;`,
                    `4. Comply with code specifications, and write and maintain development documents.`,
                  ]
                },
                requirements:{
                  title: 'Job Requirements',
                  descList: [
                    `1. Computer-related major, more than 3 years of game server development experience;`,
                    `2. Familiar with C/C++ programming language under linux/windows environment, familiar with script language lua;`,
                    `3. Familiar with data structure and algorithm, familiar with TCP/IP network programming;`,
                    `4. Familiar with mysql.redis database;`,
                    `5. Proactive, good team awareness and communication skills, strong logical thinking and independent problem solving skills.`,
                  ]
                }
              }
            },
            {
              key: 10102,
              positionName: 'U3D client development engineer', // 职位名称
              releaseTime: '2021-05-01', // 发布时间
              numberRecruits: '3', // 招聘人数
              detailsTitle: 'POSITION DETAIL',
              address: 'Guangzhou',
              emailName: 'Recruitment email',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: 'Job Responsibilities',
                  descList: [
                    `1. Responsible for the program development of the system logic, UI, tools, and special effects of the Unity3D project;`,
                    `2. Cooperate with superiors to continuously optimize and improve software development;`,
                    `3. Complete the development of project auxiliary management tools.`,
                  ]
                },
                requirements:{
                  title: 'job requirements',
                  descList: [
                    `1. More than two years of Unity3D development project experience, who has fully participated in the online game project is preferred;`,
                    `2. Familiar with C# language, with more than 1 year of C# software development work experience;`,
                    `3. Have a solid software foundation, master commonly used data structures and algorithms;`,
                    `4. Familiar with lua scripting language users, familiar with ios, Android program release process is preferred;`,
                    `5. Enthusiastic about technology research, have a good team spirit and ability to withstand pressure, work steadfastly, earnestly, and responsible.`,
                  ]
                }
              }
            },
          ],
        },
        {
          id: 102,
          icon: program2,
          title: 'Fine Arts',
          thead: [
            {id: 1, title: 'Position'},
            {id: 2, title: 'Release'},
            {id: 3, title: 'Headcount'},
          ],
          list: [
            {
              key: 10101,
              positionName: 'The role of the original artist', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: 'POSITION DETAIL',
              address: 'Guangzhou',
              emailName: 'Recruitment email',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: 'Job Responsibilities',
                  descList: [
                    `1. Responsible for the original design of the game character's original painting, stand-up illustrations and publicity related paintings`,
                    `2. Actively adjust the creative content according to actual needs, and can cooperate with 3D personnel to carry out some supplementary design work.`,
                  ]
                },
                requirements:{
                  title: 'job requirements',
                  descList: [
                    `1. Have a good art foundation and level of art, able to adapt to the adjustment of style and content drawing;`,
                    `2. Familiar with various art softwares, familiar with or wish to understand game art design work specifications and processes;`,
                    `3. Have good communication skills, are willing to actively put forward their own opinions and accept others' opinions, work carefully and carefully, have a high sense of responsibility and teamwork spirit;`,
                    `4. Have a good understanding of Japanese ACG and love to make related creations in this style;`,
                    `5. The resume needs to provide recent works so that we can fully understand you.`,
                  ]
                }
              }
            },
          ],
        },
        {
          id: 103,
          icon: program3,
          title: 'Product Planning',
          thead: [
            {id: 1, title: 'Position'},
            {id: 2, title: 'Release'},
            {id: 3, title: 'Headcount'},
          ],
          list: [
            {
              key: 10101,
              positionName: 'Commercial product manager', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: 'POSITION DETAIL',
              address: 'Guangzhou',
              emailName: 'Recruitment email',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: 'Job Responsibilities',
                  descList: [
                    `1. Understand the status of similar software products in the market through market research;`,
                    `2. Responsible for analyzing and researching products, optimizing income sources and channels, and improving flow realization efficiency;`,
                    `3. Responsible for proposing suitable monetization plans for different traffic patterns;`,
                    `4. Be good at using the company's internal resources for cross-departmental cooperation.`,
                  ]
                },
                requirements:{
                  title: 'job requirements',
                  descList: [
                    `1. Full-time bachelor degree or above, more than 3 years of experience in Internet commercial products, and experience in commercialized traffic monetization;`,
                    `2. Have independent product planning and technical follow-up capabilities, be good at discovering and solving problems, and be able to summarize problems;`,
                    `3. Have the spirit of pursuing the ultimate in products, and have a sense of innovation;`,
                    `4. Excellent logical thinking and analysis skills, excellent communication and coordination skills, good at coordination and integration of resources;`,
                    `5. Mastering tool products such as xmind and ppt is preferred.`,
                  ]
                }
              }
            },
          ],
        },
        {
          id: 104,
          icon: program4,
          title: 'Issuing operations',
          thead: [
            {id: 1, title: 'Position'},
            {id: 2, title: 'Release'},
            {id: 3, title: 'Headcount'},
          ],
          list: [
            {
              key: 10101,
              positionName: 'Advertising', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: 'POSITION DETAIL',
              address: 'Guangzhou',
              emailName: 'Recruitment email',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: 'Job Responsibilities',
                  descList: [
                    `1. Responsible for the company's mobile game product advertising plan formulation and daily delivery, timely follow-up of the material delivery effect, continuous optimization, and regular summary of the material delivery effect;`,
                    `2. Responsible for the execution and management of daily advertising, to ensure accurate advertising, follow up the effect of advertising, and optimize in time;`,
                    `3. Regularly conduct an overall analysis of the effect of the investment channels, optimize the advertising plan based on the data analysis results, and continue to make accurate advertising.`,
                  ]
                },
                requirements:{
                  title: 'job requirements',
                  descList: [
                    `1. Have certain experience in advertising on mainstream advertising platforms (such as Guangdiantong, Toutiao, Baidu Bidding, etc.);`,
                    `2. Experience in Tencent Guangdiantong is preferred; experience in game advertising is preferred;`,
                    `3. Clear thinking, familiar with excel, good at data statistics, analysis and processing;`,
                    `4. Cheerful personality, proactive and practical at work, able to withstand work pressure and intensity;`,
                    `5. Love the game industry and have a certain understanding of games.`,
                  ]
                }
              }
            },
          ],
        },
        {
          id: 105,
          icon: program5,
          title: 'Function',
          thead: [
            {id: 1, title: 'Position'},
            {id: 2, title: 'Release'},
            {id: 3, title: 'Headcount'},
          ],
          list: [
            {
              key: 10101,
              positionName: 'Game customer service', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: 'POSITION DETAIL',
              address: 'Guangzhou',
              emailName: 'Recruitment email',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: 'Job Responsibilities',
                  descList: [
                    `1. Daily QQ business and telephone consultation response;`,
                    `2. In-depth game business, record customer feedback suggestions and timely feedback;`,
                    `3. Actively maintain major game players and reduce user loss.`,
                  ]
                },
                requirements:{
                  title: 'job requirements',
                  descList: [
                    `1. More than 1 year of customer service experience, with a strong sense of service;`,
                    `2. Have a sense of responsibility, patience, and good emotional control ability;`,
                    `3. Strong receptive ability, good communication skills and skills;`,
                    `4. Standard Mandarin, responsive, able to cope with various questions and queries;`,
                    `5. Accept evening shifts and schedules.`,
                  ]
                }
              }
            },
          ],
        },
      ],
    },
    pageFives: {},
    pageSix: {},
    pageSeven: {},
    pageEight: {},
    pageNine: {},
    pageTen: {},
  },
}
export default enUS;