import { createSlice } from '@reduxjs/toolkit';

// recReSize.js
export const recReSizeSlice = createSlice({
    name: 'recReSize',
    initialState: {
      value: 0
    },
    reducers: {
        handleRecReSize: (state, action) => {
            state.value = action.payload.value;
        }
    }
  });