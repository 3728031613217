import React, { memo} from 'react';
import styles from './Index.module.scss';

const Index = memo(({config}) => {

    const renderTemple= () => {
        const { css } = config;
        return (
            <div className={styles.normal}>
                <svg style={css} width="898" height="137" viewBox="0 0 898 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-outside-1_2130_9255" maskUnits="userSpaceOnUse" x="0.399902" y="0" width="898" height="137" fill="black">
                        <rect className={`${styles.svgPath} ${styles.svgPathAnimation}`} fill="white" x="0.399902" width="898" height="137"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M97.0699 99.64C97.7899 99.64 98.5099 99.79 99.2299 100.09C99.9499 100.33 100.64 100.78 101.3 101.44L113.36 114.13C108.08 121.03 101.45 126.25 93.4699 129.79C85.5499 133.27 76.1599 135.01 65.2999 135.01C55.3399 135.01 46.3999 133.33 38.4799 129.97C30.6199 126.55 23.9299 121.87 18.4099 115.93C12.9499 109.93 8.7499 102.85 5.8099 94.69C2.8699 86.47 1.3999 77.56 1.3999 67.96C1.3999 58.18 2.9899 49.21 6.1699 41.05C9.3499 32.83 13.8199 25.75 19.5799 19.81C25.3399 13.87 32.2399 9.25 40.2799 5.95C48.3199 2.65 57.1699 1 66.8299 1C71.7499 1 76.3699 1.45 80.6899 2.35C85.0699 3.19 89.1499 4.38999 92.9299 5.95C96.7099 7.45 100.19 9.28 103.37 11.44C106.55 13.6 109.37 15.94 111.83 18.46L101.57 32.23C100.91 33.07 100.13 33.85 99.2299 34.57C98.3299 35.23 97.0699 35.56 95.4499 35.56C94.3699 35.56 93.3499 35.32 92.3899 34.84C91.4299 34.36 90.4099 33.79 89.3299 33.13C88.2499 32.41 87.0499 31.66 85.7299 30.88C84.4699 30.04 82.9399 29.29 81.1399 28.63C79.3999 27.91 77.3299 27.31 74.9299 26.83C72.5899 26.35 69.8299 26.11 66.6499 26.11C61.6099 26.11 56.9899 27.04 52.7899 28.9C48.6499 30.76 45.0499 33.49 41.9899 37.09C38.9899 40.63 36.6499 45.01 34.9699 50.23C33.3499 55.39 32.5399 61.3 32.5399 67.96C32.5399 74.68 33.4399 80.65 35.2399 85.87C37.0999 91.09 39.5899 95.5 42.7099 99.1C45.8899 102.64 49.5799 105.34 53.7799 107.2C57.9799 109.06 62.4799 109.99 67.2799 109.99C70.0399 109.99 72.5599 109.87 74.8399 109.63C77.1199 109.33 79.2499 108.88 81.2299 108.28C83.2099 107.62 85.0699 106.78 86.8099 105.76C88.5499 104.68 90.3199 103.33 92.1199 101.71C92.8399 101.11 93.6199 100.63 94.4599 100.27C95.2999 99.85 96.1699 99.64 97.0699 99.64Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M261.351 67.96C261.351 77.44 259.701 86.26 256.401 94.42C253.161 102.58 248.571 109.69 242.631 115.75C236.691 121.75 229.521 126.46 221.121 129.88C212.781 133.3 203.511 135.01 193.311 135.01C183.111 135.01 173.811 133.3 165.411 129.88C157.011 126.46 149.811 121.75 143.811 115.75C137.871 109.69 133.251 102.58 129.951 94.42C126.711 86.26 125.091 77.44 125.091 67.96C125.091 58.48 126.711 49.66 129.951 41.5C133.251 33.34 137.871 26.26 143.811 20.26C149.811 14.26 157.011 9.55 165.411 6.13C173.811 2.71 183.111 1 193.311 1C203.511 1 212.781 2.74 221.121 6.22C229.521 9.64 236.691 14.35 242.631 20.35C248.571 26.35 253.161 33.43 256.401 41.59C259.701 49.75 261.351 58.54 261.351 67.96ZM230.211 67.96C230.211 61.48 229.371 55.66 227.691 50.5C226.011 45.28 223.581 40.87 220.401 37.27C217.281 33.67 213.441 30.91 208.881 28.99C204.321 27.07 199.131 26.11 193.311 26.11C187.431 26.11 182.181 27.07 177.561 28.99C173.001 30.91 169.131 33.67 165.951 37.27C162.831 40.87 160.431 45.28 158.751 50.5C157.071 55.66 156.231 61.48 156.231 67.96C156.231 74.5 157.071 80.38 158.751 85.6C160.431 90.76 162.831 95.14 165.951 98.74C169.131 102.34 173.001 105.1 177.561 107.02C182.181 108.88 187.431 109.81 193.311 109.81C199.131 109.81 204.321 108.88 208.881 107.02C213.441 105.1 217.281 102.34 220.401 98.74C223.581 95.14 226.011 90.76 227.691 85.6C229.371 80.38 230.211 74.5 230.211 67.96Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M402.732 2.44V133.57H386.892C384.552 133.57 382.572 133.21 380.952 132.49C379.392 131.71 377.832 130.39 376.272 128.53L314.442 50.32C314.682 52.66 314.832 54.94 314.892 57.16C315.012 59.32 315.072 61.36 315.072 63.28V133.57H288.252V2.44H304.272C305.592 2.44 306.702 2.5 307.602 2.62C308.502 2.74 309.312 2.98 310.032 3.34C310.752 3.64 311.442 4.09 312.102 4.69C312.762 5.29 313.512 6.1 314.352 7.12L376.722 85.87C376.422 83.35 376.212 80.92 376.092 78.58C375.972 76.18 375.912 73.93 375.912 71.83V2.44H402.732Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M531.404 26.56H494.504V133.57H464.084V26.56H427.184V2.44H531.404V26.56Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M612.33 83.44L600.18 47.53C599.28 45.25 598.32 42.58 597.3 39.52C596.28 36.4 595.26 33.04 594.24 29.44C593.34 33.1 592.38 36.49 591.36 39.61C590.34 42.73 589.38 45.43 588.48 47.71L576.42 83.44H612.33ZM660.66 133.57H637.08C634.44 133.57 632.31 132.97 630.69 131.77C629.07 130.51 627.84 128.92 627 127L619.26 104.14H569.4L561.66 127C561 128.68 559.8 130.21 558.06 131.59C556.38 132.91 554.28 133.57 551.76 133.57H528L578.76 2.44H609.9L660.66 133.57Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M763.984 99.64C764.704 99.64 765.424 99.79 766.144 100.09C766.864 100.33 767.554 100.78 768.214 101.44L780.274 114.13C774.994 121.03 768.364 126.25 760.384 129.79C752.464 133.27 743.074 135.01 732.214 135.01C722.254 135.01 713.314 133.33 705.394 129.97C697.534 126.55 690.844 121.87 685.324 115.93C679.864 109.93 675.664 102.85 672.724 94.69C669.784 86.47 668.314 77.56 668.314 67.96C668.314 58.18 669.904 49.21 673.084 41.05C676.264 32.83 680.734 25.75 686.494 19.81C692.254 13.87 699.154 9.25 707.194 5.95C715.234 2.65 724.084 1 733.744 1C738.664 1 743.284 1.45 747.604 2.35C751.984 3.19 756.064 4.38999 759.844 5.95C763.624 7.45 767.104 9.28 770.284 11.44C773.464 13.6 776.284 15.94 778.744 18.46L768.484 32.23C767.824 33.07 767.044 33.85 766.144 34.57C765.244 35.23 763.984 35.56 762.364 35.56C761.284 35.56 760.264 35.32 759.304 34.84C758.344 34.36 757.324 33.79 756.244 33.13C755.164 32.41 753.964 31.66 752.644 30.88C751.384 30.04 749.854 29.29 748.054 28.63C746.314 27.91 744.244 27.31 741.844 26.83C739.504 26.35 736.744 26.11 733.564 26.11C728.524 26.11 723.904 27.04 719.704 28.9C715.564 30.76 711.964 33.49 708.904 37.09C705.904 40.63 703.564 45.01 701.884 50.23C700.264 55.39 699.454 61.3 699.454 67.96C699.454 74.68 700.354 80.65 702.154 85.87C704.014 91.09 706.504 95.5 709.624 99.1C712.804 102.64 716.494 105.34 720.694 107.2C724.894 109.06 729.394 109.99 734.194 109.99C736.954 109.99 739.474 109.87 741.754 109.63C744.034 109.33 746.164 108.88 748.144 108.28C750.124 107.62 751.984 106.78 753.724 105.76C755.464 104.68 757.234 103.33 759.034 101.71C759.754 101.11 760.534 100.63 761.374 100.27C762.214 99.85 763.084 99.64 763.984 99.64Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M896.783 26.56H859.883V133.57H829.463V26.56H792.563V2.44H896.783V26.56Z"/>
                    </mask>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M97.0699 99.64C97.7899 99.64 98.5099 99.79 99.2299 100.09C99.9499 100.33 100.64 100.78 101.3 101.44L113.36 114.13C108.08 121.03 101.45 126.25 93.4699 129.79C85.5499 133.27 76.1599 135.01 65.2999 135.01C55.3399 135.01 46.3999 133.33 38.4799 129.97C30.6199 126.55 23.9299 121.87 18.4099 115.93C12.9499 109.93 8.7499 102.85 5.8099 94.69C2.8699 86.47 1.3999 77.56 1.3999 67.96C1.3999 58.18 2.9899 49.21 6.1699 41.05C9.3499 32.83 13.8199 25.75 19.5799 19.81C25.3399 13.87 32.2399 9.25 40.2799 5.95C48.3199 2.65 57.1699 1 66.8299 1C71.7499 1 76.3699 1.45 80.6899 2.35C85.0699 3.19 89.1499 4.38999 92.9299 5.95C96.7099 7.45 100.19 9.28 103.37 11.44C106.55 13.6 109.37 15.94 111.83 18.46L101.57 32.23C100.91 33.07 100.13 33.85 99.2299 34.57C98.3299 35.23 97.0699 35.56 95.4499 35.56C94.3699 35.56 93.3499 35.32 92.3899 34.84C91.4299 34.36 90.4099 33.79 89.3299 33.13C88.2499 32.41 87.0499 31.66 85.7299 30.88C84.4699 30.04 82.9399 29.29 81.1399 28.63C79.3999 27.91 77.3299 27.31 74.9299 26.83C72.5899 26.35 69.8299 26.11 66.6499 26.11C61.6099 26.11 56.9899 27.04 52.7899 28.9C48.6499 30.76 45.0499 33.49 41.9899 37.09C38.9899 40.63 36.6499 45.01 34.9699 50.23C33.3499 55.39 32.5399 61.3 32.5399 67.96C32.5399 74.68 33.4399 80.65 35.2399 85.87C37.0999 91.09 39.5899 95.5 42.7099 99.1C45.8899 102.64 49.5799 105.34 53.7799 107.2C57.9799 109.06 62.4799 109.99 67.2799 109.99C70.0399 109.99 72.5599 109.87 74.8399 109.63C77.1199 109.33 79.2499 108.88 81.2299 108.28C83.2099 107.62 85.0699 106.78 86.8099 105.76C88.5499 104.68 90.3199 103.33 92.1199 101.71C92.8399 101.11 93.6199 100.63 94.4599 100.27C95.2999 99.85 96.1699 99.64 97.0699 99.64Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M261.351 67.96C261.351 77.44 259.701 86.26 256.401 94.42C253.161 102.58 248.571 109.69 242.631 115.75C236.691 121.75 229.521 126.46 221.121 129.88C212.781 133.3 203.511 135.01 193.311 135.01C183.111 135.01 173.811 133.3 165.411 129.88C157.011 126.46 149.811 121.75 143.811 115.75C137.871 109.69 133.251 102.58 129.951 94.42C126.711 86.26 125.091 77.44 125.091 67.96C125.091 58.48 126.711 49.66 129.951 41.5C133.251 33.34 137.871 26.26 143.811 20.26C149.811 14.26 157.011 9.55 165.411 6.13C173.811 2.71 183.111 1 193.311 1C203.511 1 212.781 2.74 221.121 6.22C229.521 9.64 236.691 14.35 242.631 20.35C248.571 26.35 253.161 33.43 256.401 41.59C259.701 49.75 261.351 58.54 261.351 67.96ZM230.211 67.96C230.211 61.48 229.371 55.66 227.691 50.5C226.011 45.28 223.581 40.87 220.401 37.27C217.281 33.67 213.441 30.91 208.881 28.99C204.321 27.07 199.131 26.11 193.311 26.11C187.431 26.11 182.181 27.07 177.561 28.99C173.001 30.91 169.131 33.67 165.951 37.27C162.831 40.87 160.431 45.28 158.751 50.5C157.071 55.66 156.231 61.48 156.231 67.96C156.231 74.5 157.071 80.38 158.751 85.6C160.431 90.76 162.831 95.14 165.951 98.74C169.131 102.34 173.001 105.1 177.561 107.02C182.181 108.88 187.431 109.81 193.311 109.81C199.131 109.81 204.321 108.88 208.881 107.02C213.441 105.1 217.281 102.34 220.401 98.74C223.581 95.14 226.011 90.76 227.691 85.6C229.371 80.38 230.211 74.5 230.211 67.96Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M402.732 2.44V133.57H386.892C384.552 133.57 382.572 133.21 380.952 132.49C379.392 131.71 377.832 130.39 376.272 128.53L314.442 50.32C314.682 52.66 314.832 54.94 314.892 57.16C315.012 59.32 315.072 61.36 315.072 63.28V133.57H288.252V2.44H304.272C305.592 2.44 306.702 2.5 307.602 2.62C308.502 2.74 309.312 2.98 310.032 3.34C310.752 3.64 311.442 4.09 312.102 4.69C312.762 5.29 313.512 6.1 314.352 7.12L376.722 85.87C376.422 83.35 376.212 80.92 376.092 78.58C375.972 76.18 375.912 73.93 375.912 71.83V2.44H402.732Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M531.404 26.56H494.504V133.57H464.084V26.56H427.184V2.44H531.404V26.56Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M612.33 83.44L600.18 47.53C599.28 45.25 598.32 42.58 597.3 39.52C596.28 36.4 595.26 33.04 594.24 29.44C593.34 33.1 592.38 36.49 591.36 39.61C590.34 42.73 589.38 45.43 588.48 47.71L576.42 83.44H612.33ZM660.66 133.57H637.08C634.44 133.57 632.31 132.97 630.69 131.77C629.07 130.51 627.84 128.92 627 127L619.26 104.14H569.4L561.66 127C561 128.68 559.8 130.21 558.06 131.59C556.38 132.91 554.28 133.57 551.76 133.57H528L578.76 2.44H609.9L660.66 133.57Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M763.984 99.64C764.704 99.64 765.424 99.79 766.144 100.09C766.864 100.33 767.554 100.78 768.214 101.44L780.274 114.13C774.994 121.03 768.364 126.25 760.384 129.79C752.464 133.27 743.074 135.01 732.214 135.01C722.254 135.01 713.314 133.33 705.394 129.97C697.534 126.55 690.844 121.87 685.324 115.93C679.864 109.93 675.664 102.85 672.724 94.69C669.784 86.47 668.314 77.56 668.314 67.96C668.314 58.18 669.904 49.21 673.084 41.05C676.264 32.83 680.734 25.75 686.494 19.81C692.254 13.87 699.154 9.25 707.194 5.95C715.234 2.65 724.084 1 733.744 1C738.664 1 743.284 1.45 747.604 2.35C751.984 3.19 756.064 4.38999 759.844 5.95C763.624 7.45 767.104 9.28 770.284 11.44C773.464 13.6 776.284 15.94 778.744 18.46L768.484 32.23C767.824 33.07 767.044 33.85 766.144 34.57C765.244 35.23 763.984 35.56 762.364 35.56C761.284 35.56 760.264 35.32 759.304 34.84C758.344 34.36 757.324 33.79 756.244 33.13C755.164 32.41 753.964 31.66 752.644 30.88C751.384 30.04 749.854 29.29 748.054 28.63C746.314 27.91 744.244 27.31 741.844 26.83C739.504 26.35 736.744 26.11 733.564 26.11C728.524 26.11 723.904 27.04 719.704 28.9C715.564 30.76 711.964 33.49 708.904 37.09C705.904 40.63 703.564 45.01 701.884 50.23C700.264 55.39 699.454 61.3 699.454 67.96C699.454 74.68 700.354 80.65 702.154 85.87C704.014 91.09 706.504 95.5 709.624 99.1C712.804 102.64 716.494 105.34 720.694 107.2C724.894 109.06 729.394 109.99 734.194 109.99C736.954 109.99 739.474 109.87 741.754 109.63C744.034 109.33 746.164 108.88 748.144 108.28C750.124 107.62 751.984 106.78 753.724 105.76C755.464 104.68 757.234 103.33 759.034 101.71C759.754 101.11 760.534 100.63 761.374 100.27C762.214 99.85 763.084 99.64 763.984 99.64Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M896.783 26.56H859.883V133.57H829.463V26.56H792.563V2.44H896.783V26.56Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M97.0699 99.64C97.7899 99.64 98.5099 99.79 99.2299 100.09C99.9499 100.33 100.64 100.78 101.3 101.44L113.36 114.13C108.08 121.03 101.45 126.25 93.4699 129.79C85.5499 133.27 76.1599 135.01 65.2999 135.01C55.3399 135.01 46.3999 133.33 38.4799 129.97C30.6199 126.55 23.9299 121.87 18.4099 115.93C12.9499 109.93 8.7499 102.85 5.8099 94.69C2.8699 86.47 1.3999 77.56 1.3999 67.96C1.3999 58.18 2.9899 49.21 6.1699 41.05C9.3499 32.83 13.8199 25.75 19.5799 19.81C25.3399 13.87 32.2399 9.25 40.2799 5.95C48.3199 2.65 57.1699 1 66.8299 1C71.7499 1 76.3699 1.45 80.6899 2.35C85.0699 3.19 89.1499 4.38999 92.9299 5.95C96.7099 7.45 100.19 9.28 103.37 11.44C106.55 13.6 109.37 15.94 111.83 18.46L101.57 32.23C100.91 33.07 100.13 33.85 99.2299 34.57C98.3299 35.23 97.0699 35.56 95.4499 35.56C94.3699 35.56 93.3499 35.32 92.3899 34.84C91.4299 34.36 90.4099 33.79 89.3299 33.13C88.2499 32.41 87.0499 31.66 85.7299 30.88C84.4699 30.04 82.9399 29.29 81.1399 28.63C79.3999 27.91 77.3299 27.31 74.9299 26.83C72.5899 26.35 69.8299 26.11 66.6499 26.11C61.6099 26.11 56.9899 27.04 52.7899 28.9C48.6499 30.76 45.0499 33.49 41.9899 37.09C38.9899 40.63 36.6499 45.01 34.9699 50.23C33.3499 55.39 32.5399 61.3 32.5399 67.96C32.5399 74.68 33.4399 80.65 35.2399 85.87C37.0999 91.09 39.5899 95.5 42.7099 99.1C45.8899 102.64 49.5799 105.34 53.7799 107.2C57.9799 109.06 62.4799 109.99 67.2799 109.99C70.0399 109.99 72.5599 109.87 74.8399 109.63C77.1199 109.33 79.2499 108.88 81.2299 108.28C83.2099 107.62 85.0699 106.78 86.8099 105.76C88.5499 104.68 90.3199 103.33 92.1199 101.71C92.8399 101.11 93.6199 100.63 94.4599 100.27C95.2999 99.85 96.1699 99.64 97.0699 99.64Z" stroke="#CCCCCC" stroke-width="2" mask="url(#path-1-outside-1_2130_9255)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M261.351 67.96C261.351 77.44 259.701 86.26 256.401 94.42C253.161 102.58 248.571 109.69 242.631 115.75C236.691 121.75 229.521 126.46 221.121 129.88C212.781 133.3 203.511 135.01 193.311 135.01C183.111 135.01 173.811 133.3 165.411 129.88C157.011 126.46 149.811 121.75 143.811 115.75C137.871 109.69 133.251 102.58 129.951 94.42C126.711 86.26 125.091 77.44 125.091 67.96C125.091 58.48 126.711 49.66 129.951 41.5C133.251 33.34 137.871 26.26 143.811 20.26C149.811 14.26 157.011 9.55 165.411 6.13C173.811 2.71 183.111 1 193.311 1C203.511 1 212.781 2.74 221.121 6.22C229.521 9.64 236.691 14.35 242.631 20.35C248.571 26.35 253.161 33.43 256.401 41.59C259.701 49.75 261.351 58.54 261.351 67.96ZM230.211 67.96C230.211 61.48 229.371 55.66 227.691 50.5C226.011 45.28 223.581 40.87 220.401 37.27C217.281 33.67 213.441 30.91 208.881 28.99C204.321 27.07 199.131 26.11 193.311 26.11C187.431 26.11 182.181 27.07 177.561 28.99C173.001 30.91 169.131 33.67 165.951 37.27C162.831 40.87 160.431 45.28 158.751 50.5C157.071 55.66 156.231 61.48 156.231 67.96C156.231 74.5 157.071 80.38 158.751 85.6C160.431 90.76 162.831 95.14 165.951 98.74C169.131 102.34 173.001 105.1 177.561 107.02C182.181 108.88 187.431 109.81 193.311 109.81C199.131 109.81 204.321 108.88 208.881 107.02C213.441 105.1 217.281 102.34 220.401 98.74C223.581 95.14 226.011 90.76 227.691 85.6C229.371 80.38 230.211 74.5 230.211 67.96Z" stroke="#CCCCCC" stroke-width="2" mask="url(#path-1-outside-1_2130_9255)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M402.732 2.44V133.57H386.892C384.552 133.57 382.572 133.21 380.952 132.49C379.392 131.71 377.832 130.39 376.272 128.53L314.442 50.32C314.682 52.66 314.832 54.94 314.892 57.16C315.012 59.32 315.072 61.36 315.072 63.28V133.57H288.252V2.44H304.272C305.592 2.44 306.702 2.5 307.602 2.62C308.502 2.74 309.312 2.98 310.032 3.34C310.752 3.64 311.442 4.09 312.102 4.69C312.762 5.29 313.512 6.1 314.352 7.12L376.722 85.87C376.422 83.35 376.212 80.92 376.092 78.58C375.972 76.18 375.912 73.93 375.912 71.83V2.44H402.732Z" stroke="#CCCCCC" stroke-width="2" mask="url(#path-1-outside-1_2130_9255)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M531.404 26.56H494.504V133.57H464.084V26.56H427.184V2.44H531.404V26.56Z" stroke="#CCCCCC" stroke-width="2" mask="url(#path-1-outside-1_2130_9255)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M612.33 83.44L600.18 47.53C599.28 45.25 598.32 42.58 597.3 39.52C596.28 36.4 595.26 33.04 594.24 29.44C593.34 33.1 592.38 36.49 591.36 39.61C590.34 42.73 589.38 45.43 588.48 47.71L576.42 83.44H612.33ZM660.66 133.57H637.08C634.44 133.57 632.31 132.97 630.69 131.77C629.07 130.51 627.84 128.92 627 127L619.26 104.14H569.4L561.66 127C561 128.68 559.8 130.21 558.06 131.59C556.38 132.91 554.28 133.57 551.76 133.57H528L578.76 2.44H609.9L660.66 133.57Z" stroke="#CCCCCC" stroke-width="2" mask="url(#path-1-outside-1_2130_9255)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M763.984 99.64C764.704 99.64 765.424 99.79 766.144 100.09C766.864 100.33 767.554 100.78 768.214 101.44L780.274 114.13C774.994 121.03 768.364 126.25 760.384 129.79C752.464 133.27 743.074 135.01 732.214 135.01C722.254 135.01 713.314 133.33 705.394 129.97C697.534 126.55 690.844 121.87 685.324 115.93C679.864 109.93 675.664 102.85 672.724 94.69C669.784 86.47 668.314 77.56 668.314 67.96C668.314 58.18 669.904 49.21 673.084 41.05C676.264 32.83 680.734 25.75 686.494 19.81C692.254 13.87 699.154 9.25 707.194 5.95C715.234 2.65 724.084 1 733.744 1C738.664 1 743.284 1.45 747.604 2.35C751.984 3.19 756.064 4.38999 759.844 5.95C763.624 7.45 767.104 9.28 770.284 11.44C773.464 13.6 776.284 15.94 778.744 18.46L768.484 32.23C767.824 33.07 767.044 33.85 766.144 34.57C765.244 35.23 763.984 35.56 762.364 35.56C761.284 35.56 760.264 35.32 759.304 34.84C758.344 34.36 757.324 33.79 756.244 33.13C755.164 32.41 753.964 31.66 752.644 30.88C751.384 30.04 749.854 29.29 748.054 28.63C746.314 27.91 744.244 27.31 741.844 26.83C739.504 26.35 736.744 26.11 733.564 26.11C728.524 26.11 723.904 27.04 719.704 28.9C715.564 30.76 711.964 33.49 708.904 37.09C705.904 40.63 703.564 45.01 701.884 50.23C700.264 55.39 699.454 61.3 699.454 67.96C699.454 74.68 700.354 80.65 702.154 85.87C704.014 91.09 706.504 95.5 709.624 99.1C712.804 102.64 716.494 105.34 720.694 107.2C724.894 109.06 729.394 109.99 734.194 109.99C736.954 109.99 739.474 109.87 741.754 109.63C744.034 109.33 746.164 108.88 748.144 108.28C750.124 107.62 751.984 106.78 753.724 105.76C755.464 104.68 757.234 103.33 759.034 101.71C759.754 101.11 760.534 100.63 761.374 100.27C762.214 99.85 763.084 99.64 763.984 99.64Z" stroke="#CCCCCC" stroke-width="2" mask="url(#path-1-outside-1_2130_9255)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M896.783 26.56H859.883V133.57H829.463V26.56H792.563V2.44H896.783V26.56Z" stroke="#CCCCCC" stroke-width="2" mask="url(#path-1-outside-1_2130_9255)"/>
                </svg>
            </div>
        );
    }

    return renderTemple();
});
export default Index;