import { configureStore } from '@reduxjs/toolkit'
import { reduces } from './reduce';
// import logger from "redux-logger";

export const store = configureStore({
    reducer: {
        userInfoReducer: reduces.userInfoSlice.reducer,
        aboutInfoReducer: reduces.aboutInfoSlice.reducer,
        localesSlice: reduces.localesSlice.reducer,
        localesReducer: reduces.localesSlice.reducer,
        scrollNumReducer: reduces.scrollNumSlice.reducer,
        newsDetailReducer: reduces.newsDetailSlice.reducer,
        jobDetailReducer: reduces.jobDetailSlice.reducer,
        recReSizeReducer: reduces.recReSizeSlice.reducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
