import React, {memo, useMemo, useEffect, useState, useCallback, useRef} from 'react';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'qs';
import { actions } from '../../models/stores/actions';

import {
    whCss,
    getOffset,
    getParentElement,
    delay,
    storage,
    backTo,
} from '@/utils';
import BlockContents from "../../components/BlockContents";
import styles from './Index.module.scss';
// vector icon
import vectorLeftBlack from '../../assets/public/vector-left-black.svg'; // 左⬅️
import vectorRightBlack from '../../assets/public/vector-right-black.svg'; // 右➡️
import arrowIcon from '../../assets/join/arrowicon.svg';
import g3dps from "../../assets/join/card1/g3dps.png";
import {
    JoinUsText,
    BeneFitsText,
    OfficeEnvironmentText,
    RecruitText,
} from "../../components/FontStrokeCommTexts";
import CommonPurposeModulePage from "../../components/CommonPurposeModulePage";
import vectorLeftWith from "../../assets/public/vercor-left-with.svg";


const Join = memo((props) => {

    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const { value: scrollNumReducer, cshVal } = useSelector(({scrollNumReducer}) => scrollNumReducer);
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const { handleJobDetail } = actions;
    const dispatch = useDispatch();
    const [programId, setProgramId] = useState(101);
    const [listATotal, setlistATotal] = useState(0);
    const [listBTotal, setlistBTotal] = useState(0);

    const [officeEnvironmentData, setOfficeEnvironmentData] = useState(localesReducer.join.pageThree);
    const [programListData, setProgramListData] = useState(localesReducer.join.pageFour.programListData);
    const [oneOeListData, setOneOeListData] = useState(officeEnvironmentData.oneOfficeEnvironmentData[0]);
    const [twoOeListData, setTwoOeListData] = useState(officeEnvironmentData.twoOfficeEnvironmentData[0]);


    const [theadList, setTheadList] = useState(programListData[0].thead);
    const [listData, setListData] = useState(programListData[0].list);

    // TODO: carda start
    const cardAoCRenderDrawTextRef = useRef();
    const [cardAoCRenderDrawFlag, setCardAoCRenderDrawFlag] = useState(false);
    const cardAoCRenderDrawTextRefM = useRef();
    const [cardAoCRenderDrawFlagM, setCardAoCRenderDrawFlagM] = useState(false);

    // PC端-动画
    const cardAoCRenderDrawAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight+ getOffset(A1).top;
        if(A <= B){
            setCardAoCRenderDrawFlag(true);
        }else{
            setCardAoCRenderDrawFlag(false);
        }
    }
    // 移动端-动画
    const cardAoCRenderDrawAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight+ getOffset(A1).top;
        if(A <= B){
            setCardAoCRenderDrawFlagM(true);
        }else{
            setCardAoCRenderDrawFlagM(true);
        }
    }

    // PC和移动端进行判断
    const cardDAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            cardAoCRenderDrawAnimateScroll(cardAoCRenderDrawTextRef);
        }else{
            // H5
            cardAoCRenderDrawAnimateScrollM(cardAoCRenderDrawTextRefM);
        }
    }

    // TODO: carda start

    // TODO: cardb start
    const cardBOfficeEnvironmentTextRef = useRef();
    const [cardBOfficeEnvironmentFlag, setCardBOfficeEnvironmentFlag] = useState(false);
    const cardBOfficeEnvironmentTextRefM = useRef();
    const [cardBOfficeEnvironmentFlagM, setCardBOfficeEnvironmentFlagM] = useState(false);

    // PC端-动画
    const cardBoCRenderDrawAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight+ getOffset(A1).top;
        if(A <= B){
            setCardBOfficeEnvironmentFlag(true);
        }else{
            setCardBOfficeEnvironmentFlag(false);
        }
    }
    // 移动端-动画
    const cardBoCRenderDrawAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight+ getOffset(A1).top;
        if(A <= B){
            setCardBOfficeEnvironmentFlagM(true);
        }else{
            setCardBOfficeEnvironmentFlagM(false);
        }
    }
    // PC和移动端进行判断
    const cardBAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            cardBoCRenderDrawAnimateScroll(cardBOfficeEnvironmentTextRef);
        }else{
            // H5
            cardBoCRenderDrawAnimateScrollM(cardBOfficeEnvironmentTextRefM);
        }
    }

    // TODO: cardb start


    // TODO: cardc start
    const cardCRecruitTextRef = useRef();
    const [cardCRecruitFlag, setCardCRecruitFlag] = useState(false);
    const cardCRecruitTextRefM = useRef();
    const [cardCRecruitFlagM, setCardCRecruitFlagM] = useState(false);

    // PC端-动画
    const cardCRecruitAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight + getOffset(A1).top;
        if(A <= B){
            setCardCRecruitFlag(true);

        }else{
            setCardCRecruitFlag(false);
        }
    }
    // 移动端-动画
    const cardCRecruitAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight + getOffset(A1).top;
        if(A <= B){
            setCardCRecruitFlagM(true);

        }else{
            setCardCRecruitFlagM(false);
        }
    }
    // PC和移动端进行判断
    const cardCAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            cardCRecruitAnimateScroll(cardCRecruitTextRef);
        }else{
            // H5
            cardCRecruitAnimateScrollM(cardCRecruitTextRefM);
        }
    }

    // TODO: cardc start

    useEffect(() => {
        cardDAnimateScroll();
        cardBAnimateScroll();
        cardCAnimateScroll();
    }, [cshVal]);

    const refLCardItemOneA = useRef(); // 0 - 339
    const refLCardItemOneB = useRef(); // 0 - 429
    const refLCardItemOneC = useRef(); // 0 - 388
    const refRCardItemTwoA = useRef();
    const refRCardItemTwoB = useRef();
    const refRCardItemTwoC = useRef();
    const handleRefLCardItemOne = (event) => {
    }

    const handleSetOffsetRefValueOne = (i, type) => {
        if(i){
            refLCardItemOneA.current.style.transform = `translateX(${-50}%)`;
            refLCardItemOneB.current.style.transform = `translateX(${-50}%)`;
            refLCardItemOneC.current.style.transform = `translateX(${-50}%)`;
        }else{
            refLCardItemOneA.current.style.transform = `translateX(${0}%)`;
            refLCardItemOneB.current.style.transform = `translateX(${0}%)`;
            refLCardItemOneC.current.style.transform = `translateX(${0}%)`;
        }
    }

    const handleSetOffsetRefValueTwo = (i, type) => {
        if(i){
            refRCardItemTwoA.current.style.transform = `translateX(${-50}%)`;
            refRCardItemTwoB.current.style.transform = `translateX(${-50}%)`;
            refRCardItemTwoC.current.style.transform = `translateX(${-50}%)`;
        }else{
            refRCardItemTwoA.current.style.transform = `translateX(${0}%)`;
            refRCardItemTwoB.current.style.transform = `translateX(${0}%)`;
            refRCardItemTwoC.current.style.transform = `translateX(${0}%)`;
        }
    }

    useEffect(() => {
        delay(300, () => {
            refLCardItemOneA.current.style.transform = `translateX(${0}%)`;
            refLCardItemOneB.current.style.transform = `translateX(${0}%)`;
            refLCardItemOneC.current.style.transform = `translateX(${0}%)`;

            refRCardItemTwoA.current.style.transform = `translateX(${0}%)`;
            refRCardItemTwoB.current.style.transform = `translateX(${0}%)`;
            refRCardItemTwoC.current.style.transform = `translateX(${0}%)`;
        });

    }, []);

    const handleFirstNext = useCallback((i, type) => {

        if(type === 'typeOne'){
            setOneOeListData(officeEnvironmentData.oneOfficeEnvironmentData[i]);
            setlistATotal(i);
            handleSetOffsetRefValueOne(i, type);
        }else{
            setTwoOeListData(officeEnvironmentData.twoOfficeEnvironmentData[i]);
            setlistBTotal(i);
            handleSetOffsetRefValueTwo(i, type);
        }
    }, [officeEnvironmentData]);

    const handleProgramOnClick = useCallback((item) => {
        const { history } = props;
        setProgramId(item.id);
        setTheadList(item.thead);
        setListData(item.list);
        history.push(`/join?id=${item.id}`);
    }, [props]);

    useEffect(() => {
        const { location } = props;
        const queryUrlParams = location.search.split('?')[1];
        let isProgramId = queryUrlParams ? parseInt(qs.parse(queryUrlParams).id) : 101;
        const data = programListData.filter((item) => item.id === isProgramId);
        setProgramId(isProgramId);
        setTheadList(data[0].thead);
        setListData(data[0].list);

        setOfficeEnvironmentData(localesReducer.join.pageThree);
        setOneOeListData(localesReducer.join.pageThree.oneOfficeEnvironmentData[0]);
        setTwoOeListData(localesReducer.join.pageThree.twoOfficeEnvironmentData[0]);
        setProgramListData(localesReducer.join.pageFour.programListData);

    }, [localesReducer, officeEnvironmentData, programListData]);


    const handleJobDetails = (item) => {
        if(Object.keys(item.details).length){
            const { history } = props;
            dispatch(handleJobDetail(item));
            history.push(`/join/detail?id=${programId}&key=${item.key}`);
            memoStorage();
        }
    }



    useEffect(() => {
        delay(200, () =>{
            backTo();
        });
    }, []);

    const memoStorage = () => {
        storage('scrollTopKey', scrollNumReducer).setStorage();  // 记录来当前的页面滚动的位置
    }

    const tUlWidth = useMemo(() => {
        let num = 0;
        programListData.forEach((item, i) => {
            num = ++i;
        });
        return {
            width: `${((num * 128)+(19*num)) / 100}rem`
        }
    }, [programListData]);


    const [firstFlag, setFirstFlag] = useState(true);
    const [nextFlag, setNextFlag] = useState(true);
    const [firstFlagT, setFirstFlagT] = useState(true);
    const [nextFlagT, setNextFlagT] = useState(true);
    const handleShowMouse = useCallback((type) => {
        if (type === 'firstShow'){
            setFirstFlag(false);
        }
        if (type === 'firstHidden'){
            setFirstFlag(true);
        }

        if (type === 'nextShow'){
            setNextFlag(false);
        }
        if (type === 'nextHidden'){
            setNextFlag(true);
        }
        // -------------------------------
        if (type === 'firstShowT'){
            setFirstFlagT(false);
        }
        if (type === 'firstHiddenT'){
            setFirstFlagT(true);
        }

        if (type === 'nextShowT'){
            setNextFlagT(false);
        }
        if (type === 'nextHiddenT'){
            setNextFlagT(true);
        }

    },[]);




    const renderPC = () => {
        const isLang = localesReducer['navbar.lang'] === 'English';
        const isNormal = recReSizeReducer >= 1920 ? styles.normalMax : styles.normal;
        const isWidth = recReSizeReducer >= 1920;
        const hMax = 176.76;

        return (
            <div className={isNormal}>
                <div className="carda">
                    <div className="content">
                        <div className="rowa">
                            <CommonPurposeModulePage
                                config={{
                                    status: 1, // 1 ， 2， 3， 4 ，5
                                    normalCss: {
                                        width: `100%`,
                                        background: '#FFF',
                                        height: localesReducer['navbar.lang'] === 'English' ? whCss(isWidth ? 804 : 750) : whCss(isWidth ? 804 : 750),
                                        marginBottom: whCss(50),
                                    },
                                    rightBox: {
                                        img: g3dps,
                                        css: {
                                            width: whCss(isWidth ? 788 : 639),
                                            height: whCss(isWidth ? 550 : 446),
                                            overflow: 'hidden',
                                        },
                                    },
                                    purPoseTitle: () => {
                                        return (
                                            <JoinUsText
                                                config={{
                                                    css: {
                                                        width: whCss(isWidth ? 1002 : 744),
                                                        height: whCss(isWidth ? hMax : 134.01),
                                                        marginBottom: whCss(50),
                                                    }
                                                }}
                                            />
                                        )
                                    },
                                    purPoseContent: () => {
                                        const SubTitlePure = () => {
                                            return (
                                                <>
                                                    <p>
                                                        {localesReducer.join.pageOne.subTitle[0]}
                                                    </p>
                                                    <p>
                                                        <span style={isLang ? {color: `#b0a27b`} : null}>{localesReducer.join.pageOne.subTitle[1]}{isLang ? ' ':''}</span>
                                                        <span style={!isLang ? {color: `#b0a27b`} : null}>{localesReducer.join.pageOne.subTitle[2]}</span>
                                                       {localesReducer.join.pageOne.subTitle[3]}
                                                    </p>
                                                </>
                                            );
                                        };
                                        return (
                                            <BlockContents config={{
                                                title: '',       // 标题
                                                list: [<SubTitlePure />],     // 副标题
                                                flag: false,                 // 状态
                                                alignItems: 'flex-start', // flex-start / flex-end
                                                status: 1, // 状态 1， 2， 3
                                                titleCss: {
                                                    fontFamily: `Noto Sans SC`,
                                                    fontSize: whCss(20),
                                                    color: '#B0A27C',
                                                    marginBottom: whCss(16),
                                                },
                                                listCss: {
                                                    width: '',
                                                    height: '',
                                                    fontFamily: `Noto Sans SC`,
                                                    fontSize: whCss(56),
                                                    textAlign: 'left',
                                                    color: '#1A1A1A',
                                                    lineHeight: whCss(80),
                                                },
                                                fLCss: {
                                                    firstColor: '#1A1A1A',
                                                    lastColor: '#B0A27C',
                                                }
                                            }} />
                                        )
                                    },
                                    leftContentCss: {
                                        position: 'absolute',
                                        top: whCss(isWidth ? 386 : 375),
                                        left: whCss(isWidth ? 220 : 80),
                                        zIndex: 6,
                                    },
                                    rightContent: {
                                        contentCss: {
                                            position: 'absolute',
                                            top: whCss(204),
                                            right: whCss(0),
                                            width: whCss(isWidth ? 788 : 639),
                                            height: whCss(isWidth ? 550 : 446),
                                        },
                                        comModuleBgCss: {
                                            position: 'relative',
                                            width: whCss(isWidth ? 788 : 639),
                                            height: whCss(isWidth ? 550 : 446),
                                        }

                                    }
                                }}
                            />
                        </div>
                        <div className="rowb">
                            <div className="textanimate" ref={cardAoCRenderDrawTextRef}>
                                { cardAoCRenderDrawFlag ? (
                                    <BeneFitsText
                                        config={{
                                            css: {
                                                width: whCss(isWidth ? 1197 : 879.22),
                                                height: whCss(isWidth ? hMax : 134.01),
                                            }
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className="ccb">
                                <BlockContents config={{
                                    title: localesReducer.join.pageTwo.title,       // 标题
                                    list: localesReducer.join.pageTwo.subTitle,     // 副标题
                                    flag: false,                 // 状态
                                    alignItems: 'flex-start', // flex-start / flex-end
                                    status: 1, // 状态 1， 2， 3
                                    titleCss: {
                                        fontSize: `${20 / 100}rem`,
                                        color: '#B0A27C',
                                        marginBottom: `${16 / 100}rem`,
                                    },
                                    listCss: {
                                        width: isWidth ? whCss(757) : whCss(957), // 757
                                        height: '',
                                        fontSize: `${56 / 100}rem`,
                                        textAlign: 'left',
                                        color: '#1A1A1A',
                                        lineHeight: `${80 / 100}rem`
                                    },
                                    fLCss: {
                                        firstColor: '#1A1A1A',
                                        lastColor: '#B0A27C',
                                    }
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className="list">
                        <ul className="cul">
                            {localesReducer.join.pageTwo.benefitsListData.map((item) => (
                                <li key={item.id} className="cli">
                                    <div className="libox">
                                        <div className="icon"><img src={item.icon} alt={item.title} /></div>
                                        <h5 className={isLang ? 'h5en' : 'h5zh'}>{item.title}</h5>
                                        <div className="innerTexts" style={{ height: isLang ? whCss(92) : whCss(64)}}>
                                            {item.list.map((k, i) => (
                                                <p key={i}>{k}</p>
                                            ))}
                                        </div>
                                    </div>
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
                <div className="cardb">
                    <div className="contentb">
                        <div className="binfor">
                            <div className="textanimate" ref={cardBOfficeEnvironmentTextRef}>
                                { cardBOfficeEnvironmentFlag ? (
                                    <OfficeEnvironmentText
                                        config={{
                                            css: {
                                                width: whCss(isWidth ? 2904 : 2169.4),
                                                height: whCss(isWidth ? hMax : 134.01),
                                            }
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className="ccb">
                                <BlockContents config={{
                                    title: localesReducer.join.pageThree.title,       // 标题
                                    list: localesReducer.join.pageThree.subTitle,     // 副标题
                                    flag: false,                 // 状态
                                    alignItems: 'flex-start', // flex-start / flex-end
                                    status: 1, // 状态 1， 2， 3
                                    titleCss: {
                                        fontSize: `${20 / 100}rem`,
                                        color: '#B0A27C',
                                        marginBottom: `${16 / 100}rem`,
                                    },
                                    listCss: {
                                        width: '',
                                        height: '',
                                        fontSize: `${56 / 100}rem`,
                                        textAlign: 'left',
                                        color: '#1A1A1A',
                                        lineHeight: `${80 / 100}rem`
                                    },
                                    fLCss: {
                                        firstColor: '#1A1A1A',
                                        lastColor: '#B0A27C',
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="taba">
                        <div className="lcontent">
                            {localesReducer.join.pageThree.oneOfficeEnvironmentData[0].list.map((item, index) => {
                                let isRefLCardItemOne;
                                switch (item.key){
                                    case 'img01':
                                        isRefLCardItemOne = refLCardItemOneA;
                                        break;
                                    case 'img02':
                                        isRefLCardItemOne = refLCardItemOneB;
                                        break;
                                    default:
                                        isRefLCardItemOne = refLCardItemOneC;
                                }
                                return (
                                    <div
                                        key={item.key}
                                        className="lcarditem"
                                        onScroll={handleRefLCardItemOne}
                                    >
                                        <div
                                            className="lcarditemBox"
                                            ref={isRefLCardItemOne}
                                        >
                                            <div className="lcarditemChiren" style={{background: `url(${item.imgFirst}) no-repeat ${item.style.lr} ${item.style.tb}/112%`}} />
                                            <div className="lcarditemChiren" style={{background: `url(${item.imgNext}) no-repeat ${item.style.lr} ${item.style.tb}/cover`}} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="rcontent">
                            <div className="rdesc">
                                <p>{oneOeListData && oneOeListData.desc}</p>
                            </div>
                            <div className="rbtn">
                                <div
                                    className={`tfirst ${!firstFlag ? (listATotal === 0 ? null : 'tNextFirstAction') : null}`}
                                    onMouseOver={() => handleShowMouse('firstShow')}
                                    onMouseLeave={() => handleShowMouse('firstHidden')}
                                    onClick={() => handleFirstNext(0, 'typeOne')}
                                    style={{opacity: listATotal === 0 ? 0.3 : 1}}
                                >
                                    <img src={!firstFlag ? (listATotal === 0 ? vectorLeftBlack : vectorLeftWith) : vectorLeftBlack} style={{transform: `rotate(${!firstFlag ? (listATotal === 0 ? 0 : 180) : 0}deg)`}} alt="left" />
                                </div>
                                <div
                                    className={`tnext ${!nextFlag ?( listATotal !== 0 ? null : 'tNextFirstAction') : null}`}
                                    onMouseOver={() => handleShowMouse('nextShow')}
                                    onMouseLeave={() => handleShowMouse('nextHidden')}
                                    onClick={() => handleFirstNext(1, 'typeOne')} style={{opacity: listATotal !== 0 ? 0.3 : 1}}
                                >
                                    <img src={!nextFlag ? (listATotal !== 0 ? vectorRightBlack : vectorLeftWith)  : vectorRightBlack} alt="right" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tabb">
                        <div className="rcontent">
                            <div className="rdesc">
                                <p>{twoOeListData.desc}</p>
                            </div>
                            <div className="rbtn">
                                <div
                                    className={`tfirst ${!firstFlagT ? (listBTotal === 0 ? null : 'tNextFirstAction') : null}`}
                                    onMouseOver={() => handleShowMouse('firstShowT')}
                                    onMouseLeave={() => handleShowMouse('firstHiddenT')}
                                    onClick={() => handleFirstNext(0, 'typeTwo')}
                                    style={{opacity: listBTotal === 0 ? 0.3 : 1}}
                                >
                                    <img src={!firstFlagT ? (listBTotal === 0 ? vectorLeftBlack : vectorLeftWith) : vectorLeftBlack} style={{transform: `rotate(${!firstFlagT ? (listBTotal === 0 ? 0 : 180)  : 0}deg)`}} alt="left" />
                                </div>
                                <div
                                    className={`tnext ${!nextFlagT ? (listBTotal !== 0 ? null : 'tNextFirstAction') : null}`}
                                    onMouseOver={() => handleShowMouse('nextShowT')}
                                    onMouseLeave={() => handleShowMouse('nextHiddenT')}
                                    onClick={() => handleFirstNext(1, 'typeTwo')}
                                    style={{opacity: listBTotal !== 0 ? 0.3 : 1}}
                                >
                                    <img src={!nextFlagT ? (listBTotal !== 0 ? vectorRightBlack : vectorLeftWith) : vectorRightBlack} alt="right" />
                                </div>
                            </div>
                        </div>
                        <div className="lcontent">
                            {localesReducer.join.pageThree.twoOfficeEnvironmentData[0].list.map((item, index) => {
                                let isRefRCardItemTwo;
                                switch (item.key){
                                    case 'img01':
                                        isRefRCardItemTwo = refRCardItemTwoA;
                                        break;
                                    case 'img02':
                                        isRefRCardItemTwo = refRCardItemTwoB;
                                        break;
                                    default:
                                        isRefRCardItemTwo = refRCardItemTwoC;
                                }
                                return (
                                    <div
                                        key={item.key}
                                        className="lcarditem"
                                        onScroll={handleRefLCardItemOne}
                                    >
                                        <div
                                            className="lcarditemBox"
                                            ref={isRefRCardItemTwo}
                                        >
                                            <div className="lcarditemChiren" style={{background: `url(${item.imgFirst}) no-repeat ${item.style.lr} ${item.style.tb}/cover`}} />
                                            <div className="lcarditemChiren" style={{background: `url(${item.imgNext}) no-repeat ${item.style.lr} ${item.style.tb}/cover`}} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="cardc">
                    <div className="contentc">
                        <div className="cinfor">
                            <div className="textanimate" ref={cardCRecruitTextRef}>
                                { cardCRecruitFlag ? (
                                    <RecruitText
                                        config={{
                                            css: {
                                                width: whCss(isWidth ? 1078 : 792.64),
                                                height: whCss(isWidth ? hMax : 134.01),
                                            }
                                        }}
                                    />
                                ) : null }
                            </div>
                            <div className="ccb">
                                <BlockContents config={{
                                    title: localesReducer.join.pageFour.title,       // 标题
                                    list: localesReducer.join.pageFour.subTitle,     // 副标题
                                    flag: false,                 // 状态
                                    alignItems: 'flex-start', // flex-start / flex-end
                                    status: 1, // 状态 1， 2， 3
                                    titleCss: {
                                        fontSize: `${20 / 100}rem`,
                                        color: '#B0A27C',
                                        marginBottom: `${16 / 100}rem`,
                                    },
                                    listCss: {
                                        width: '',
                                        height: '',
                                        fontSize: `${56 / 100}rem`,
                                        textAlign: 'left',
                                        color: '#1A1A1A',
                                        lineHeight: `${80 / 100}rem`
                                    },
                                    fLCss: {
                                        firstColor: '#1A1A1A',
                                        lastColor: '#B0A27C',
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="tabs">
                        <div className="tag">
                            <ul className="tul" style={{ width: 'auto' }}>
                                {programListData.map((item) => {
                                    return (
                                        <li key={item.id} onClick={() => handleProgramOnClick(item)} className={ item.id === programId ? 'tli tliactive' : 'tli'}>
                                            <div className="icon"><img  src={item.icon} alt="icon" /></div>
                                            <p>{item.title}</p>
                                            {/*{item.id === programId ? <i className="triangle" /> : null}*/}
                                            <i className="triangle" />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="table">
                            <table>
                                <thead>
                                <tr>
                                    {theadList.map((item) => {
                                        return (
                                            <th key={item.id}>{item.title}</th>
                                        )
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {listData.map((item) => {
                                    return (
                                        <tr key={item.key} className="tractive" onClick={() => handleJobDetails(item)}>
                                            <td>{item.positionName}</td>
                                            <td>{item.releaseTime}</td>
                                            <td>
                                                <div className="jobdetails">
                                                    <span>{item.numberRecruits}</span>
                                                    <span className="arrowIcon"><img src={arrowIcon} alt="line" /></span>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderH5 = () => {
        const isLang = localesReducer['navbar.lang'] === 'English';
        return (
            <div className={styles.normalH5}>
                <div className="carda">
                    <div className="content">
                        <div className="rowa">
                            <CommonPurposeModulePage
                                config={{
                                    status: 1, // 1 ， 2， 3， 4 ，5
                                    normalCss: {
                                        width: `100%`,
                                        background: '#FFF',
                                        height: isLang ? whCss(463) : whCss(422),
                                        marginBottom: whCss(50),
                                    },
                                    rightBox: {
                                        img: g3dps,
                                        css: {
                                            width: whCss(236.4),
                                            height: whCss(165),
                                            overflow: 'hidden',
                                        },
                                    },
                                    purPoseTitle: () => {
                                        return (
                                            <JoinUsText
                                                config={{
                                                    css: {
                                                        width: whCss(234),
                                                        height: whCss(68),
                                                        marginBottom: whCss(30),
                                                    }
                                                }}
                                            />
                                        )
                                    },
                                    purPoseContent: () => {
                                        const SubTitlePure = () => {
                                            const pCss = {
                                                fontFamily: `Lato`,
                                                fontStyle: 'normal',
                                                fontSize: whCss(32),
                                                textAlign: 'left',
                                                color: '#1A1A1A',
                                                lineHeight: whCss(40),
                                            }
                                            return isLang ? (
                                                <>
                                                    <span style={pCss}>{localesReducer.join.pageOne.subTitle[0]} </span>
                                                    <span style={{color: `#b0a27b`}}>{localesReducer.join.pageOne.subTitle[1]} </span>
                                                    <span>{localesReducer.join.pageOne.subTitle[2]}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <p style={pCss}>
                                                        {localesReducer.join.pageOne.subTitle[0]}
                                                    </p>
                                                    <p style={pCss}>
                                                        {localesReducer.join.pageOne.subTitle[1]}
                                                        <span style={{color: `#b0a27b`}}>{localesReducer.join.pageOne.subTitle[2]}</span>
                                                        {localesReducer.join.pageOne.subTitle[3]}
                                                    </p>
                                                </>
                                            );
                                        };
                                        return (
                                            <BlockContents config={{
                                                title: '',       // 标题
                                                list: [<SubTitlePure />],     // 副标题
                                                flag: false,                 // 状态
                                                alignItems: 'flex-start', // flex-start / flex-end
                                                status: 1, // 状态 1， 2， 3
                                                titleCss: {
                                                    fontFamily: `Noto Sans SC`,
                                                    fontSize: whCss(20),
                                                    color: '#B0A27C',
                                                    marginBottom: whCss(16),
                                                },
                                                listCss: {
                                                    width: 'auto',
                                                    height: '',
                                                    fontFamily: `Lato`,
                                                    fontStyle: 'normal',
                                                    fontSize: whCss(32),
                                                    textAlign: 'left',
                                                    color: '#1A1A1A',
                                                    lineHeight: whCss(40),
                                                },
                                                fLCss: {
                                                    firstColor: '#1A1A1A',
                                                    lastColor: '#B0A27C',
                                                }
                                            }} />
                                        )
                                    },
                                    leftContentCss: {
                                        position: 'absolute',
                                        top: whCss(215),
                                        left: whCss(24),
                                        zIndex: 6,
                                    },
                                    rightContent: {
                                        contentCss: {
                                            position: 'absolute',
                                            top: whCss(142),
                                            right: whCss(0),
                                            width: whCss(236.4),
                                            height: whCss(165),
                                        },
                                        comModuleBgCss: {
                                            position: 'relative',
                                            width: whCss(236.4),
                                            height: whCss(165),
                                        }

                                    }
                                }}
                            />
                        </div>
                        <div className="rowb">
                            <div className="textanimate" ref={cardAoCRenderDrawTextRefM}>
                                { cardAoCRenderDrawFlagM ? (
                                    <BeneFitsText
                                        config={{
                                            css: {
                                                width: whCss(280),
                                                height: whCss(68),
                                            }
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className="ccb">
                                <BlockContents config={{
                                    title: localesReducer.join.pageTwo.title,       // 标题
                                    list: isLang ? localesReducer.join.pageTwo.subTitle : [`${localesReducer.join.pageTwo.subTitle[0]}${localesReducer.join.pageTwo.subTitle[1]}`],     // 副标题
                                    flag: false,                 // 状态
                                    alignItems: 'flex-start', // flex-start / flex-end
                                    status: 1, // 状态 1， 2， 3
                                    titleCss: {
                                        fontSize: `${20 / 100}rem`,
                                        color: '#B0A27C',
                                        marginBottom: `${16 / 100}rem`,
                                    },
                                    listCss: {
                                        width: whCss( isLang ? 261 : 253),
                                        height: '',
                                        fontSize: `${32 / 100}rem`,
                                        // textAlign: 'justify',
                                        fontFamily: 'Noto Sans SC',
                                        fontStyle: 'normal',
                                        color: '#1A1A1A',
                                        lineHeight: `${40 / 100}rem`,
                                    },
                                    fLCss: {
                                        firstColor: '#1A1A1A',
                                        lastColor: '#B0A27C',
                                    }
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className="list">
                        <ul className="cul">
                            {localesReducer.join.pageTwo.benefitsListData.map((item) => (
                                <li key={item.id} className="cli">
                                    <div className="libox">
                                        <div className="icon"><img src={item.icon} alt={item.title} /></div>
                                        {/*<h5 className={isLang ? 'h5en' : 'h5zh'}>{item.title}</h5>*/}
                                        <div className={isLang ? 'h5en' : 'h5zh'}>
                                            <h5>{item.title}</h5>
                                        </div>
                                        <div className="innerTexts" style={{ height: isLang ? whCss(128) : whCss(64)}}>
                                            {item.list.map((k, i) => (
                                                <p key={i}>{k}</p>
                                            ))}
                                        </div>
                                    </div>
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>

                <div className="cardb">
                    <div className="contentb">
                        <div className="binfor">
                            <div className="textanimate" ref={cardBOfficeEnvironmentTextRefM}>
                                { cardBOfficeEnvironmentFlagM ? (
                                    <OfficeEnvironmentText
                                        config={{
                                            css: {
                                                width: whCss(678),
                                                height: whCss(68),
                                            }
                                        }}
                                    />
                                ) : null }
                            </div>
                            <div className="ccb">
                                <BlockContents config={{
                                    title: localesReducer.join.pageThree.title,       // 标题
                                    list: localesReducer.join.pageThree.subTitle,     // 副标题
                                    flag: false,                 // 状态
                                    alignItems: 'flex-start', // flex-start / flex-end
                                    status: 1, // 状态 1， 2， 3
                                    titleCss: {
                                        fontSize: `${20 / 100}rem`,
                                        color: '#B0A27C',
                                        marginBottom: `${16 / 100}rem`,
                                    },
                                    listCss: {
                                        width: '',
                                        height: '',
                                        fontSize: `${32 / 100}rem`,
                                        fontFamily: 'Noto Sans SC',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                        color: '#1A1A1A',
                                        lineHeight: `${40 / 100}rem`
                                    },
                                    fLCss: {
                                        firstColor: '#1A1A1A',
                                        lastColor: '#B0A27C',
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="taba">
                        <div className="lcontent">
                            {localesReducer.join.pageThree.oneOfficeEnvironmentData[0].list.map((item, index) => {
                                let isRefLCardItemOne;
                                switch (item.key){
                                    case 'img01':
                                        isRefLCardItemOne = refLCardItemOneA;
                                        break;
                                    case 'img02':
                                        isRefLCardItemOne = refLCardItemOneB;
                                        break;
                                    default:
                                        isRefLCardItemOne = refLCardItemOneC;
                                }
                                return (
                                    <div
                                        key={item.key}
                                        className="lcarditem"
                                        onScroll={handleRefLCardItemOne}
                                    >
                                        <div
                                            className="lcarditemBox"
                                            ref={isRefLCardItemOne}
                                        >
                                            <div className="lcarditemChiren" style={{background: `url(${item.imgFirst}) no-repeat ${item.style.lr} ${item.style.tb}/cover`}} />
                                            <div className="lcarditemChiren" style={{background: `url(${item.imgNext}) no-repeat ${item.style.lr} ${item.style.tb}/cover`}} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="rcontent">
                            <div className="rbtn">
                                <div className="tfirst" onClick={() => handleFirstNext(0, 'typeOne')} style={{opacity: listATotal === 0 ? 0.3 : 1}}><img src={vectorLeftBlack} alt="left" /></div>
                                <div className="tnext" onClick={() => handleFirstNext(1, 'typeOne')} style={{opacity: listATotal !== 0 ? 0.3 : 1}}><img src={vectorRightBlack} alt="right" /></div>
                            </div>
                            <div className="rdesc">
                                <p>{oneOeListData && oneOeListData.desc}</p>
                            </div>
                        </div>
                    </div>
                    <div className="tabb">
                        <div className="rcontent">
                            <div className="rdesc">
                                <p>{twoOeListData.desc}</p>
                            </div>
                            <div className="rbtn">
                                <div className="tfirst" onClick={() => handleFirstNext(0, 'typeTwo')} style={{opacity: listBTotal === 0 ? 0.3 : 1}}><img src={vectorLeftBlack} alt="left" /></div>
                                <div className="tnext" onClick={() => handleFirstNext(1, 'typeTwo')} style={{opacity: listBTotal !== 0 ? 0.3 : 1}}><img src={vectorRightBlack} alt="right" /></div>
                            </div>
                        </div>
                        <div className="lcontent">
                            {/*{twoOeListData.list.map((item, index) => {
                                return (<div key={item.key} className="lcarditem" style={{ background: `url(${item.img}) no-repeat ${item.style.lr} ${item.style.tb}/cover`}} />);
                            })}*/}
                            {localesReducer.join.pageThree.twoOfficeEnvironmentData[0].list.map((item, index) => {
                                let isRefRCardItemTwo;
                                switch (item.key){
                                    case 'img01':
                                        isRefRCardItemTwo = refRCardItemTwoA;
                                        break;
                                    case 'img02':
                                        isRefRCardItemTwo = refRCardItemTwoB;
                                        break;
                                    default:
                                        isRefRCardItemTwo = refRCardItemTwoC;
                                }
                                return (
                                    <div
                                        key={item.key}
                                        className="lcarditem"
                                        onScroll={handleRefLCardItemOne}
                                    >
                                        <div
                                            className="lcarditemBox"
                                            ref={isRefRCardItemTwo}
                                        >
                                            <div className="lcarditemChiren" style={{background: `url(${item.imgFirst}) no-repeat ${item.style.lr} ${item.style.tb}/cover`}} />
                                            <div className="lcarditemChiren" style={{background: `url(${item.imgNext}) no-repeat ${item.style.lr} ${item.style.tb}/cover`}} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="cardc">
                    <div className="contentc">
                        <div className="cinfor">
                            <div className="textanimate" ref={cardCRecruitTextRefM}>
                                { cardCRecruitFlagM ? (
                                    <RecruitText
                                        config={{
                                            css: {
                                                width: whCss(252),
                                                height: whCss(68),
                                            }
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className="ccb">
                                <BlockContents config={{
                                    title: localesReducer.join.pageFour.title,       // 标题
                                    list: isLang ? [`${localesReducer.join.pageFour.subTitle[0]}${localesReducer.join.pageFour.subTitle[1]}`] : localesReducer.join.pageFour.subTitle,     // 副标题
                                    flag: false,                 // 状态
                                    alignItems: 'flex-start', // flex-start / flex-end
                                    status: 1, // 状态 1， 2， 3
                                    titleCss: {
                                        fontSize: `${20 / 100}rem`,
                                        color: '#B0A27C',
                                        marginBottom: `${16 / 100}rem`,
                                    },
                                    listCss: {
                                        width: '',
                                        height: '',
                                        fontSize: `${32 / 100}rem`,
                                        textAlign: 'left',
                                        color: '#1A1A1A',
                                        lineHeight: `${40 / 100}rem`
                                    },
                                    fLCss: {
                                        firstColor: '#1A1A1A',
                                        lastColor: '#B0A27C',
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="tabs">
                        <div className="tag">
                            <ul className="tul" style={tUlWidth}>
                                {programListData.map((item) => {
                                    return (
                                        <li key={item.id} onClick={() => handleProgramOnClick(item)} className={ item.id === programId ? 'tli tliactive' : 'tli'}>
                                            <div className="icon"><img  src={item.icon} alt="icon" /></div>
                                            <p className="pzh">{item.title}</p>
                                            <i className="triangle" />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="table">
                            <table>
                                <thead>
                                <tr>
                                    {theadList.map((item) => {
                                        return (
                                            <th key={item.id}>{item.title}</th>
                                        )
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {listData.map((item) => {
                                    return (
                                        <tr key={item.key} className="tractive" onClick={() => handleJobDetails(item)}>
                                            <td>{item.positionName}</td>
                                            <td>{item.releaseTime}</td>
                                            <td>
                                                <div className="jobdetails">
                                                    <span>{item.numberRecruits}</span>
                                                    {/*<span className="arrowIcon" style={{visibility: 'hidden'}}><img src={arrowIcon} alt="line" /></span>*/}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(Object.keys(localesReducer.join).length){
        return recReSizeReducer > 750 ? renderPC() : renderH5();
    }else{
        return null;
    }
});

export default withRouter(Join);