import React, { memo} from 'react';
// import {useSelector} from "react-redux";
import styles from './Index.module.scss';

const Index = memo(({config}) => {
    // const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    // const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);

    const renderTemple= () => {
        const { css } = config;
        return (
            <div className={styles.normal}>
                <svg style={css} width="613" height="137" viewBox="0 0 613 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-outside-1_2130:9224" maskUnits="userSpaceOnUse" x="0.490173" y="0.429932" width="612" height="137" fill="black">
                        <rect className={`${styles.svgPath} ${styles.svgPathAnimation}`} fill="white" x="0.490173" y="0.429932" width="612" height="137"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M128.85 134H98.4302C96.4502 134 94.8302 133.52 93.5702 132.56C92.3702 131.6 91.4102 130.46 90.6902 129.14L64.4102 83.2399C63.9302 84.5599 63.3902 85.6999 62.7902 86.6599L38.3102 129.14C37.4702 130.34 36.4802 131.45 35.3402 132.47C34.2002 133.49 32.7302 134 30.9302 134H2.49017L44.7002 66.4999L4.11017 2.86993H34.5302C36.5102 2.86993 37.9502 3.13993 38.8502 3.67993C39.8102 4.15993 40.6502 4.99993 41.3702 6.19993L67.4702 50.3899C67.7702 49.6099 68.1002 48.8599 68.4602 48.1399C68.8802 47.4199 69.3302 46.6699 69.8102 45.8899L92.6702 6.64993C93.5102 5.32993 94.3802 4.36993 95.2802 3.76993C96.2402 3.16993 97.4102 2.86993 98.7902 2.86993H127.95L86.8202 65.2399L128.85 134Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M180.228 134H149.628V2.86993H180.228V134Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M283.826 83.8699L271.676 47.9599C270.776 45.6799 269.816 43.0099 268.796 39.9499C267.776 36.8299 266.756 33.4699 265.736 29.8699C264.836 33.5299 263.876 36.9199 262.856 40.0399C261.836 43.1599 260.876 45.8599 259.976 48.1399L247.916 83.8699H283.826ZM332.156 134H308.576C305.936 134 303.806 133.4 302.186 132.2C300.566 130.94 299.336 129.35 298.496 127.43L290.756 104.57H240.896L233.156 127.43C232.496 129.11 231.296 130.64 229.556 132.02C227.876 133.34 225.776 134 223.256 134H199.496L250.256 2.86993H281.396L332.156 134Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M465.975 2.86993V134H450.135C447.795 134 445.815 133.64 444.195 132.92C442.635 132.14 441.075 130.82 439.515 128.96L377.685 50.7499C377.925 53.0899 378.075 55.3699 378.135 57.5899C378.255 59.7499 378.315 61.7899 378.315 63.7099V134H351.495V2.86993H367.515C368.835 2.86993 369.945 2.92993 370.845 3.04993C371.745 3.16993 372.555 3.40993 373.275 3.76993C373.995 4.06993 374.685 4.51993 375.345 5.11993C376.005 5.71993 376.755 6.52993 377.595 7.54993L439.965 86.2999C439.665 83.7799 439.455 81.3499 439.335 79.0099C439.215 76.6099 439.155 74.3599 439.155 72.2599V2.86993H465.975Z"/>
                        <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M563.417 66.0499H610.037V121.4C603.317 126.32 596.147 129.89 588.527 132.11C580.967 134.33 573.047 135.44 564.767 135.44C553.967 135.44 544.127 133.76 535.247 130.4C526.427 126.98 518.867 122.3 512.567 116.36C506.267 110.36 501.407 103.28 497.987 95.1199C494.567 86.8999 492.857 77.9899 492.857 68.3899C492.857 58.6099 494.477 49.6399 497.717 41.4799C500.957 33.2599 505.577 26.1799 511.577 20.2399C517.637 14.2999 524.927 9.67993 533.447 6.37993C542.027 3.07993 551.597 1.42993 562.157 1.42993C567.617 1.42993 572.747 1.87993 577.547 2.77993C582.347 3.67993 586.757 4.90993 590.777 6.46993C594.857 8.02993 598.577 9.88993 601.937 12.0499C605.297 14.2099 608.297 16.5499 610.937 19.0699L602.117 32.4799C600.737 34.5799 598.937 35.8699 596.717 36.3499C594.557 36.8299 592.217 36.2899 589.697 34.7299C587.297 33.2899 585.017 32.0599 582.857 31.0399C580.757 29.9599 578.597 29.0899 576.377 28.4299C574.157 27.7699 571.817 27.2899 569.357 26.9899C566.897 26.6899 564.137 26.5399 561.077 26.5399C555.377 26.5399 550.247 27.5599 545.687 29.5999C541.127 31.5799 537.227 34.3999 533.987 38.0599C530.807 41.7199 528.347 46.1299 526.607 51.2899C524.867 56.3899 523.997 62.0899 523.997 68.3899C523.997 75.3499 524.957 81.5299 526.877 86.9299C528.797 92.3299 531.497 96.8899 534.977 100.61C538.517 104.33 542.717 107.18 547.577 109.16C552.497 111.08 557.927 112.04 563.867 112.04C567.527 112.04 570.827 111.68 573.767 110.96C576.767 110.24 579.677 109.28 582.497 108.08V88.1899H569.537C567.617 88.1899 566.117 87.6799 565.037 86.6599C563.957 85.6399 563.417 84.3499 563.417 82.7899V66.0499Z"/>
                    </mask>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M128.85 134H98.4302C96.4502 134 94.8302 133.52 93.5702 132.56C92.3702 131.6 91.4102 130.46 90.6902 129.14L64.4102 83.2399C63.9302 84.5599 63.3902 85.6999 62.7902 86.6599L38.3102 129.14C37.4702 130.34 36.4802 131.45 35.3402 132.47C34.2002 133.49 32.7302 134 30.9302 134H2.49017L44.7002 66.4999L4.11017 2.86993H34.5302C36.5102 2.86993 37.9502 3.13993 38.8502 3.67993C39.8102 4.15993 40.6502 4.99993 41.3702 6.19993L67.4702 50.3899C67.7702 49.6099 68.1002 48.8599 68.4602 48.1399C68.8802 47.4199 69.3302 46.6699 69.8102 45.8899L92.6702 6.64993C93.5102 5.32993 94.3802 4.36993 95.2802 3.76993C96.2402 3.16993 97.4102 2.86993 98.7902 2.86993H127.95L86.8202 65.2399L128.85 134Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M180.228 134H149.628V2.86993H180.228V134Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M283.826 83.8699L271.676 47.9599C270.776 45.6799 269.816 43.0099 268.796 39.9499C267.776 36.8299 266.756 33.4699 265.736 29.8699C264.836 33.5299 263.876 36.9199 262.856 40.0399C261.836 43.1599 260.876 45.8599 259.976 48.1399L247.916 83.8699H283.826ZM332.156 134H308.576C305.936 134 303.806 133.4 302.186 132.2C300.566 130.94 299.336 129.35 298.496 127.43L290.756 104.57H240.896L233.156 127.43C232.496 129.11 231.296 130.64 229.556 132.02C227.876 133.34 225.776 134 223.256 134H199.496L250.256 2.86993H281.396L332.156 134Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M465.975 2.86993V134H450.135C447.795 134 445.815 133.64 444.195 132.92C442.635 132.14 441.075 130.82 439.515 128.96L377.685 50.7499C377.925 53.0899 378.075 55.3699 378.135 57.5899C378.255 59.7499 378.315 61.7899 378.315 63.7099V134H351.495V2.86993H367.515C368.835 2.86993 369.945 2.92993 370.845 3.04993C371.745 3.16993 372.555 3.40993 373.275 3.76993C373.995 4.06993 374.685 4.51993 375.345 5.11993C376.005 5.71993 376.755 6.52993 377.595 7.54993L439.965 86.2999C439.665 83.7799 439.455 81.3499 439.335 79.0099C439.215 76.6099 439.155 74.3599 439.155 72.2599V2.86993H465.975Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M563.417 66.0499H610.037V121.4C603.317 126.32 596.147 129.89 588.527 132.11C580.967 134.33 573.047 135.44 564.767 135.44C553.967 135.44 544.127 133.76 535.247 130.4C526.427 126.98 518.867 122.3 512.567 116.36C506.267 110.36 501.407 103.28 497.987 95.1199C494.567 86.8999 492.857 77.9899 492.857 68.3899C492.857 58.6099 494.477 49.6399 497.717 41.4799C500.957 33.2599 505.577 26.1799 511.577 20.2399C517.637 14.2999 524.927 9.67993 533.447 6.37993C542.027 3.07993 551.597 1.42993 562.157 1.42993C567.617 1.42993 572.747 1.87993 577.547 2.77993C582.347 3.67993 586.757 4.90993 590.777 6.46993C594.857 8.02993 598.577 9.88993 601.937 12.0499C605.297 14.2099 608.297 16.5499 610.937 19.0699L602.117 32.4799C600.737 34.5799 598.937 35.8699 596.717 36.3499C594.557 36.8299 592.217 36.2899 589.697 34.7299C587.297 33.2899 585.017 32.0599 582.857 31.0399C580.757 29.9599 578.597 29.0899 576.377 28.4299C574.157 27.7699 571.817 27.2899 569.357 26.9899C566.897 26.6899 564.137 26.5399 561.077 26.5399C555.377 26.5399 550.247 27.5599 545.687 29.5999C541.127 31.5799 537.227 34.3999 533.987 38.0599C530.807 41.7199 528.347 46.1299 526.607 51.2899C524.867 56.3899 523.997 62.0899 523.997 68.3899C523.997 75.3499 524.957 81.5299 526.877 86.9299C528.797 92.3299 531.497 96.8899 534.977 100.61C538.517 104.33 542.717 107.18 547.577 109.16C552.497 111.08 557.927 112.04 563.867 112.04C567.527 112.04 570.827 111.68 573.767 110.96C576.767 110.24 579.677 109.28 582.497 108.08V88.1899H569.537C567.617 88.1899 566.117 87.6799 565.037 86.6599C563.957 85.6399 563.417 84.3499 563.417 82.7899V66.0499Z" fill="#E7E3D8"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M128.85 134H98.4302C96.4502 134 94.8302 133.52 93.5702 132.56C92.3702 131.6 91.4102 130.46 90.6902 129.14L64.4102 83.2399C63.9302 84.5599 63.3902 85.6999 62.7902 86.6599L38.3102 129.14C37.4702 130.34 36.4802 131.45 35.3402 132.47C34.2002 133.49 32.7302 134 30.9302 134H2.49017L44.7002 66.4999L4.11017 2.86993H34.5302C36.5102 2.86993 37.9502 3.13993 38.8502 3.67993C39.8102 4.15993 40.6502 4.99993 41.3702 6.19993L67.4702 50.3899C67.7702 49.6099 68.1002 48.8599 68.4602 48.1399C68.8802 47.4199 69.3302 46.6699 69.8102 45.8899L92.6702 6.64993C93.5102 5.32993 94.3802 4.36993 95.2802 3.76993C96.2402 3.16993 97.4102 2.86993 98.7902 2.86993H127.95L86.8202 65.2399L128.85 134Z" stroke="#CCCCCC" strokeWidth="2" mask="url(#path-1-outside-1_2130:9224)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M180.228 134H149.628V2.86993H180.228V134Z" stroke="#CCCCCC" strokeWidth="2" mask="url(#path-1-outside-1_2130:9224)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M283.826 83.8699L271.676 47.9599C270.776 45.6799 269.816 43.0099 268.796 39.9499C267.776 36.8299 266.756 33.4699 265.736 29.8699C264.836 33.5299 263.876 36.9199 262.856 40.0399C261.836 43.1599 260.876 45.8599 259.976 48.1399L247.916 83.8699H283.826ZM332.156 134H308.576C305.936 134 303.806 133.4 302.186 132.2C300.566 130.94 299.336 129.35 298.496 127.43L290.756 104.57H240.896L233.156 127.43C232.496 129.11 231.296 130.64 229.556 132.02C227.876 133.34 225.776 134 223.256 134H199.496L250.256 2.86993H281.396L332.156 134Z" stroke="#CCCCCC" strokeWidth="2" mask="url(#path-1-outside-1_2130:9224)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M465.975 2.86993V134H450.135C447.795 134 445.815 133.64 444.195 132.92C442.635 132.14 441.075 130.82 439.515 128.96L377.685 50.7499C377.925 53.0899 378.075 55.3699 378.135 57.5899C378.255 59.7499 378.315 61.7899 378.315 63.7099V134H351.495V2.86993H367.515C368.835 2.86993 369.945 2.92993 370.845 3.04993C371.745 3.16993 372.555 3.40993 373.275 3.76993C373.995 4.06993 374.685 4.51993 375.345 5.11993C376.005 5.71993 376.755 6.52993 377.595 7.54993L439.965 86.2999C439.665 83.7799 439.455 81.3499 439.335 79.0099C439.215 76.6099 439.155 74.3599 439.155 72.2599V2.86993H465.975Z" stroke="#CCCCCC" strokeWidth="2" mask="url(#path-1-outside-1_2130:9224)"/>
                    <path className={`${styles.svgPath} ${styles.svgPathAnimation}`} d="M563.417 66.0499H610.037V121.4C603.317 126.32 596.147 129.89 588.527 132.11C580.967 134.33 573.047 135.44 564.767 135.44C553.967 135.44 544.127 133.76 535.247 130.4C526.427 126.98 518.867 122.3 512.567 116.36C506.267 110.36 501.407 103.28 497.987 95.1199C494.567 86.8999 492.857 77.9899 492.857 68.3899C492.857 58.6099 494.477 49.6399 497.717 41.4799C500.957 33.2599 505.577 26.1799 511.577 20.2399C517.637 14.2999 524.927 9.67993 533.447 6.37993C542.027 3.07993 551.597 1.42993 562.157 1.42993C567.617 1.42993 572.747 1.87993 577.547 2.77993C582.347 3.67993 586.757 4.90993 590.777 6.46993C594.857 8.02993 598.577 9.88993 601.937 12.0499C605.297 14.2099 608.297 16.5499 610.937 19.0699L602.117 32.4799C600.737 34.5799 598.937 35.8699 596.717 36.3499C594.557 36.8299 592.217 36.2899 589.697 34.7299C587.297 33.2899 585.017 32.0599 582.857 31.0399C580.757 29.9599 578.597 29.0899 576.377 28.4299C574.157 27.7699 571.817 27.2899 569.357 26.9899C566.897 26.6899 564.137 26.5399 561.077 26.5399C555.377 26.5399 550.247 27.5599 545.687 29.5999C541.127 31.5799 537.227 34.3999 533.987 38.0599C530.807 41.7199 528.347 46.1299 526.607 51.2899C524.867 56.3899 523.997 62.0899 523.997 68.3899C523.997 75.3499 524.957 81.5299 526.877 86.9299C528.797 92.3299 531.497 96.8899 534.977 100.61C538.517 104.33 542.717 107.18 547.577 109.16C552.497 111.08 557.927 112.04 563.867 112.04C567.527 112.04 570.827 111.68 573.767 110.96C576.767 110.24 579.677 109.28 582.497 108.08V88.1899H569.537C567.617 88.1899 566.117 87.6799 565.037 86.6599C563.957 85.6399 563.417 84.3499 563.417 82.7899V66.0499Z" stroke="#CCCCCC" strokeWidth="2" mask="url(#path-1-outside-1_2130:9224)"/>
                </svg>
            </div>
        );
    }

    return renderTemple();
});
export default Index;