import React, {memo, useEffect} from 'react';
import { useSelector } from 'react-redux';
import NewsTab from '../../components/NewsTab';
import {
    whCss,
    backTo,
    storage,
    delay,
} from '@/utils';
import {NewsText} from "../../components/FontStrokeCommTexts";
import CommonPurposeModulePage from "../../components/CommonPurposeModulePage";
import BlockContents from "../../components/BlockContents";
import styles from './Index.module.scss';

import ibn from "../../assets/news/ibn.png";


const News = memo(() => {
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const { value: scrollNumReducer } = useSelector(({scrollNumReducer}) => scrollNumReducer);

    useEffect(() => {
        delay(200, () =>{
            backTo();
        });
    }, []);

    const handleLinkJump = () => {
        storage('details', {type: 'news'}).setStorage();         // 记录来当前的页面
        storage('scrollTopKey', scrollNumReducer).setStorage();  // 记录来当前的页面滚动的位置
    }

    const renderPC = () => {
        // const isNormal = recReSizeReducer >= 1920 ? styles.normalMax : styles.normal;
        const isWidth = recReSizeReducer >= 1920;
        const hMax = 176.76;

        return (
            <div className={styles.normal}>
                <div className="carda">
                    <CommonPurposeModulePage
                        config={{
                            status: 1, // 1 ， 2， 3， 4 ，5
                            normalCss: {
                                width: `100%`,
                                // background: 'red',
                                height: localesReducer['navbar.lang'] === 'English' ? whCss(isWidth ? 823 : 767) : whCss(isWidth ? 828 : 773),
                            },
                            rightBox: {
                                img: ibn,
                                css: {
                                    width: whCss(isWidth ? 810 : 700),
                                    height: whCss(isWidth ? 539 : 437.08),
                                    overflow: 'hidden',
                                },
                            },
                            purPoseTitle: () => {
                                return (
                                    <NewsText
                                        config={{
                                            css: {
                                                width: whCss(isWidth ? 747 : 541.76),
                                                height: whCss(isWidth ? hMax : 134.01),
                                                marginBottom: whCss(30),
                                            }
                                        }}
                                    />
                                )
                            },
                            purPoseContent: () => {
                                return (
                                    <BlockContents config={{
                                        title: localesReducer.news.pageOne.title,       // 标题
                                        list: localesReducer.news.pageOne.subTitle,     // 副标题
                                        flag: false,                 // 状态
                                        alignItems: 'flex-start', // flex-start / flex-end
                                        status: 1, // 状态 1， 2， 3
                                        titleCss: {
                                            fontSize: whCss(20),
                                            color: '#B0A27C',
                                            marginBottom: whCss(16),
                                        },
                                        listCss: {
                                            width: '',
                                            height: '',
                                            fontSize: whCss(56),
                                            textAlign: 'left',
                                            color: '#1A1A1A',
                                            lineHeight: whCss(80),
                                        },
                                        fLCss: {
                                            firstColor: '#1A1A1A',
                                            lastColor: '#B0A27C',
                                        }
                                    }} />
                                )
                            },
                            leftContentCss: {
                                position: 'absolute',
                                top: whCss(isWidth ? 386 : 373),
                                left: whCss(isWidth ? 220 : 80),
                                zIndex: 6,
                            },
                            rightContent: {
                                contentCss: {
                                    position: 'absolute',
                                    top: whCss(isWidth ? 204 : 256.71),
                                    right: whCss(isWidth ? 0 : 89.4),
                                    width: whCss(isWidth ? 810 : 700),
                                    height: whCss(isWidth ? 539 : 437.08),
                                },
                                comModuleBgCss: {
                                    position: 'relative',
                                    width: whCss(isWidth ? 810 : 700),
                                    height: whCss(isWidth ? 539 : 437.08),
                                }

                            }
                        }}
                    />
                </div>

                <div className="cardb">
                    <NewsTab config={{handleLinkJump}} />
                </div>

            </div>
        );
    };

    const renderH5 = () => {
        return (
            <div className={styles.normalh5}>
                <div className="carda">
                    <CommonPurposeModulePage
                        config={{
                            status: 1, // 1 ， 2， 3， 4 ，5
                            normalCss: {
                                width: `100%`,
                                background: '#FFF',
                                height: localesReducer['navbar.lang'] === 'English' ? whCss(439) : whCss(443),
                            },
                            rightBox: {
                                img: ibn,
                                css: {
                                    width: whCss(236.4),
                                    height: whCss(165),
                                    overflow: 'hidden',
                                },
                            },
                            purPoseTitle: () => {
                                return (
                                    <NewsText
                                        config={{
                                            css: {
                                                width: whCss(175),
                                                height: whCss(68),
                                                marginBottom: whCss(30),
                                                paddingRight: whCss(2),
                                            }
                                        }}
                                    />
                                )
                            },
                            purPoseContent: () => {
                                return (
                                    <BlockContents config={{
                                        title: localesReducer.news.pageOne.title,       // 标题
                                        list: localesReducer.news.pageOne.subTitle,     // 副标题
                                        flag: false,                 // 状态
                                        alignItems: 'flex-start', // flex-start / flex-end
                                        status: 1, // 状态 1， 2， 3
                                        titleCss: {
                                            fontSize: whCss(17),
                                            color: '#B0A27C',
                                            marginBottom: whCss(16),
                                        },
                                        listCss: {
                                            width: '',
                                            height: '',
                                            fontSize: whCss(32),
                                            textAlign: 'left',
                                            color: '#1A1A1A',
                                            lineHeight: whCss(40),
                                        },
                                        fLCss: {
                                            firstColor: '#1A1A1A',
                                            lastColor: '#B0A27C',
                                        }
                                    }} />
                                )
                            },
                            leftContentCss: {
                                position: 'absolute',
                                top: whCss(195),
                                left: whCss(24),
                                zIndex: 6,
                            },
                            rightContent: {
                                contentCss: {
                                    position: 'absolute',
                                    top: whCss(102),
                                    right: whCss(0),
                                    width: whCss(236.4),
                                    height: whCss(165),
                                },
                                comModuleBgCss: {
                                    position: 'relative',
                                    width: whCss(236.4),
                                    height: whCss(165),
                                }

                            }
                        }}
                    />
                </div>

                <div className="cardb">
                    <NewsTab config={{handleLinkJump}} />
                </div>
            </div>
        );
    };

    if(Object.keys(localesReducer.about).length){
        return recReSizeReducer > 750 ? renderPC() : renderH5();
    }else{
        return null;
    }
});

export default News;