import { createSlice } from '@reduxjs/toolkit';

export const jobDetailSlice = createSlice({
  name: 'jobDetail',
  initialState: {
    data: {}
  },
  reducers: {
    handleJobDetail: (state, action) => {
          state.data = {
            ...state.data,
            ...action.payload,
          }
      }
  }
});