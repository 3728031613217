import React, { memo, } from 'react';
import { withRouter } from 'react-router-dom';
import HuanText from './HuanText';
import XiangText from './XiangText';

import styles from './Index.module.scss';

const Index = memo(({config}) => {
    const { huanTextCss, XiangTextCss } = config;

    return (
        <div className={styles.normal}>
            <HuanText
                config={{
                    css: {
                       ...huanTextCss
                    }
                }}
            />
            <XiangText
                config={{
                    css: {
                        ...XiangTextCss,
                    }
                }}
            />
        </div>
    );

});
export default withRouter(Index);
