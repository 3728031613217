import React, { memo, useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { actions } from '../../models/stores/actions';
import qs from 'qs';
import styles from './Index.module.scss';

const NewsTab = memo((props) => {
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);

    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const dispatch = useDispatch();
    const { handleNewsDetail } = actions;

    const [tabIndex, setTabIndex] = useState('all');
    const [listData, setListData] = useState([]);

    const handleTabClick = useCallback((item) => {
        const tabListData = localesReducer.news.pageOne.tabListData;
        setTabIndex(item.type);
        switch(item.type){
            case 'news':
                setListData(tabListData[1].list);
                break;
            case 'product':
                setListData(tabListData[2].list);
                break;
            default:
                const data = [...tabListData[1].list, ...tabListData[2].list];
                setListData(data);

        }
    }, [localesReducer]);
   
    const handleClickLoadMore = useCallback(() => {
        const tabListData = localesReducer.news.pageOne.tabListData;
        switch(tabIndex){
            case 'news':
                let newsAllPage = tabListData[1].list;
                setListData(newsAllPage);
                break;
            case 'product':
                let productAllPage = tabListData[2].list;
                setListData(productAllPage);
                break;
            default:
                let allPage = [...tabListData[1].list, ...tabListData[2].list];
                setListData(allPage);
        }
        
    }, [localesReducer, tabIndex]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const queryData = () => {
        const { location } = props;
        const tabListData = localesReducer.news.pageOne.tabListData.slice(0);
        // const data = [...tabListData[1].list, ...tabListData[2].list];
        // const list = data.sort(() => Math.random() - 0.5);
        const list = [...tabListData[1].list, ...tabListData[2].list];
        const queryUrlParams = location.search.split('?')[1];
        let isActive = queryUrlParams ? qs.parse(queryUrlParams).active : 'all';
        setTabIndex(isActive);
        if(isActive === 'all'){
            setListData(list);
        }else{
            let newData = list.filter((item) => item.type === isActive);
            setListData(newData);
        }
    }

    useEffect(() => {
        queryData();
    }, [localesReducer]);



    const handleLinkJump = useCallback( (item, tabIndex, index) => {
        const { history, config } = props;
        history.push(`/news/detail?active=${tabIndex}`);
        config.handleLinkJump();
        dispatch(handleNewsDetail({
            id: item.id,
            page: index,
            size: listData.length,
            list: listData,
        }));
    }, [dispatch, handleNewsDetail, listData, props]);

    const renderPC = () => {
        return (
            <div className={styles.normal}>
                <div className="tab">
                    {localesReducer.news.pageOne.tabListData.map((item, index) => {
                        return (<div key={item.id} onClick={() => handleTabClick(item, index)} className={item.type === tabIndex ? 'item active' : 'item'}>{item.title}</div>);
                    })}
                </div>
                <div className="list">
                    <ul>
                        {
                            listData.map((item, index) => {
                                return (
                                    <li key={index} onClick={() => handleLinkJump(item, tabIndex, index)}>
                                        <div className="pic">
                                            <img src={item.img} alt={item.title} />
                                        </div>
                                        <div className="content">
                                            <div className="tag">{item.tag}</div>
                                            <p className="title">{item.title}</p>
                                            <p className="subtext">{item.desc}</p>
                                            <p className="datatime">{item.time}</p>
                                        </div>
                                    </li>
                                )
                            })
                        }

                    </ul>
                </div>
                <div className="btn" onClick={handleClickLoadMore}>{localesReducer.news.pageOne.loadMore}</div>
            </div>
        );
    }

    const renderH5 = () => {
        return (
            <div className={styles.normalh5}>
                <div className="tab">
                    {localesReducer.news.pageOne.tabListData.map((item, index) => {
                        return (<div key={item.id} onClick={() => handleTabClick(item, index)} className={item.type === tabIndex ? 'item active' : 'item'}>{item.title}</div>);
                    })}
                </div>
                <div className="list">
                    <ul>
                        {
                            listData.map((item, index) => {
                                return (
                                    <li key={index} onClick={() => handleLinkJump(item, tabIndex, index)}>
                                        <div className="pic">
                                            <img src={item.img} alt={item.title} />
                                        </div>
                                        <div className="content">
                                            <div className="tag">{item.tag}</div>
                                            <p className="title">{item.title}</p>
                                            <p className="subtext">{item.desc}</p>
                                            <p className="datatime">{item.time}</p>
                                        </div>
                                    </li>
                                )
                            })
                        }

                    </ul>
                </div>
                <div className="btn" onClick={handleClickLoadMore}>{localesReducer.news.pageOne.loadMore}</div>
            </div>
        );
    }
    if(Object.keys(localesReducer.about).length){
        return recReSizeReducer > 750 ? renderPC() : renderH5();
    }else{
        return null;
    }
});
export default withRouter(NewsTab);
