import { createSlice } from '@reduxjs/toolkit';
import zhCN from '../../../locales/i18n/zh-CN';
import enUS from '../../../locales/i18n/en-US';

export const localesSlice = createSlice({
    name: 'locales',
    initialState: {
        i18n: zhCN,
        lang: 'zhCN',
    },
    reducers: {
        localesI18n: (state, action) => {
            switch(action.payload.lang){
                case 'zhCN':
                    state.i18n = zhCN;
                    state.lang = 'zhCN';
                    break;
                case 'enUS':
                    state.i18n = enUS;
                    state.lang = 'enUS';
                    break;
                default:
                    state.i18n = zhCN;
                    state.lang = 'zhCN';
            }
        }
    }
});