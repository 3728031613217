import React, { memo} from 'react';
import styles from './Index.module.scss';

const Index = memo(({config}) => {

    const renderTemple= () => {
        const {
            css,
            img,
        } = config;

        const imgCss = {
            width: css.width,
            height: css.height
        }
        return (
            <div className={styles.normal} style={css}>
                <img style={imgCss} src={img} alt=""/>
            </div>
        );
    }
    return renderTemple();
});
export default Index;