import React, {memo, useMemo, useRef, useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import BlockContents from '../../components/BlockContents';
import CommonPurposeModulePage from "../../components/CommonPurposeModulePage";
import {
    AboutUsText,
    CompanyCultureText,
    DevelopmentPathText,
} from '../../components/FontStrokeCommTexts';

import {
    whCss,
    getOffset,
    getParentElement,
    changeDispatchInner,
} from '@/utils';

import styles from './Index.module.scss';

import aboutUsOneBg from '../../assets/about/card1/aboutusbg.png';
import aboutUsBg from '../../assets/about/card1/vector_1.svg';


const About = memo(() => {
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const { cshVal } = useSelector(({scrollNumReducer}) => scrollNumReducer);

    // TODO: cardd start
    const cardDcRenderDrawTextRef = useRef();
    const [cardDcRenderDrawFlag, setCardDcRenderDrawFlag] = useState(false);
    const cardDcRenderDrawTextRefM = useRef();
    const [cardDcRenderDrawFlagM, setCardDcRenderDrawFlagM] = useState(false);
    const [cardDBg, setCardDBg] = useState({
        background: '#FFF',
        opacity: 0,
        transition: 'all 0.8s'
    });
    const [cardDBgM, setCardDBgM] = useState({
        background: '#FFF',
        opacity: 0,
        transition: 'all 0.8s'
    });

    // PC端-动画
    const cardDcRenderDrawAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight+ getOffset(A1).top;
        if(A <= B){
            setCardDcRenderDrawFlag(true);
            setCardDBg({
                background: '#1A1A1A',
                opacity: 1,
                transition: 'all 0.8s'
            });
        }else{
            setCardDcRenderDrawFlag(false);
            setCardDBg({
                background: '#FFF',
                opacity: 0,
                transition: 'all 0.8s'
            });
        }
    }
    // 移动端-动画
    const cardDcRenderDrawAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight+ getOffset(A1).top;
        if(A <= B){
            setCardDcRenderDrawFlagM(true);
            setCardDBgM({
                background: '#1A1A1A',
                opacity: 1,
                transition: 'all 0.8s'
            });
        }else{
            setCardDcRenderDrawFlagM(false);
            setCardDBgM({
                background: '#FFF',
                opacity: 0,
                transition: 'all 0.8s'
            });
        }
    }
    // PC和移动端进行判断
    const cardDAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            cardDcRenderDrawAnimateScroll(cardDcRenderDrawTextRef);
        }else{
            // H5
            cardDcRenderDrawAnimateScrollM(cardDcRenderDrawTextRefM);
        }
    }

    // TODO: cardd end

    // TODO: carde start
    const cardEdRenderDrawTextRef = useRef();
    const [cardEdRenderDrawFlag, setCardEdRenderDrawFlag] = useState(false);
    const cardEdRenderDrawTextRefM = useRef();
    const [cardEdRenderDrawFlagM, setCardEdRenderDrawFlagM] = useState(false);

    const cardEdRenderDrawAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        const A =  A1.offsetHeight+ getOffset(A1).top;
        if(A <= B){
            setCardEdRenderDrawFlag(true);

        }else{
            setCardEdRenderDrawFlag(false);
        }
    }

    // 移动端-动画
    const cardEdRenderDrawAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        // let B1 = getParentElement(A1.offsetParent);
        // const A = A1.offsetHeight+getOffset(B1).top;
        // const A =  (A1.offsetHeight*8.9) + getOffset(A1).top;
        const A =  A1.offsetHeight+ getOffset(A1).top;

        // console.log("A1: ", getOffset(A1).top, A1.offsetHeight, A1);
        // console.log("B1: ", getOffset(B1).top, B1.offsetHeight, B1);
        // console.log("打印的结果: ", "A：", A ,"B:", B, "A <= B: ",A <= B);
        if(A <= B){
            setCardEdRenderDrawFlagM(true);

        }else{
            setCardEdRenderDrawFlagM(false);
        }
    }

    // PC和移动端进行判断
    const cardEAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            cardEdRenderDrawAnimateScroll(cardEdRenderDrawTextRef);
        }else{
            // H5
            cardEdRenderDrawAnimateScrollM(cardEdRenderDrawTextRefM);
        }
    }

    // TODO: carde end

    useEffect(() => {
        cardDAnimateScroll();
        cardEAnimateScroll();
    }, [cshVal]);


    const renderPC = () => {
        const isNormal = recReSizeReducer >= 1920 ? styles.normalMax : styles.normal;
        const isWidth = recReSizeReducer >= 1920;
        const hMax = 176.76;
        let isCardBH = 737+500-72-98;

        const isCardCRightTop = {
            paddingTop: whCss(isWidth ? (
                localesReducer['navbar.lang'] === 'English' ? 188 : 202
            ) : localesReducer['navbar.lang'] === 'English' ? 120 : 150)
        }
        return (
            <div className={isNormal}>
                <div className="carda">
                    <CommonPurposeModulePage
                        config={{
                            status: 1, // 1 ， 2， 3， 4 ，5
                            normalCss: {
                                width: `100%`,
                                // background: 'red',
                                height: localesReducer['navbar.lang'] === 'English' ? whCss(isWidth ? 843 : 800) : whCss(isWidth ? 848 : 805),
                            },
                            rightBox: {
                                img: aboutUsBg,
                                css: {
                                    width: whCss(isWidth ? 578 : 708),
                                    height: whCss(isWidth ? 456 : 550),
                                    borderTopRightRadius: whCss(isWidth ? 280 : 280),
                                    borderBottomRightRadius: whCss(isWidth ? 280 : 280),
                                    overflow: 'hidden',
                                    background: `url(${aboutUsOneBg}) no-repeat 4% 100%/cover`
                                },
                            },
                            purPoseTitle: () => {
                                return (
                                    <AboutUsText
                                        config={{
                                            css: {
                                                width: whCss(isWidth ? 1298 : 970.54),
                                                height: whCss(isWidth ? hMax : 134.01),
                                                marginBottom: whCss(50),
                                            }
                                        }}
                                    />
                                )
                            },
                            purPoseContent: () => {
                                const Texts = () => {
                                    return (
                                        <>
                                            <span>{localesReducer.about.pageOne.subTitle[0]}</span>
                                            <span style={{color: '#b0a27b'}}>{localesReducer.about.pageOne.subTitle[1]}</span>
                                        </>
                                    )
                                }
                                return (
                                    <BlockContents config={{
                                        title: localesReducer.about.pageOne.title,       // 标题
                                        // list: [`${localesReducer.about.pageOne.subTitle[0]} ${localesReducer.about.pageOne.subTitle[1]}`],     // 副标题
                                        list: [<Texts />],     // 副标题
                                        flag: false,                 // 状态
                                        alignItems: 'flex-start', // flex-start / flex-end
                                        status: 1, // 状态 1， 2， 3
                                        titleCss: {
                                            fontSize: whCss(20),
                                            color: '#B0A27C',
                                            marginBottom: whCss(16),
                                        },
                                        listCss: {
                                            width: whCss(840),
                                            height: '',
                                            fontSize: whCss(56),
                                            textAlign: 'left',
                                            color: '#1A1A1A',
                                            lineHeight: whCss(80),
                                        },
                                        fLCss: {
                                            firstColor: '#1A1A1A',
                                            lastColor: '#B0A27C',
                                        }
                                    }} />
                                )
                            },
                            leftContentCss: {
                                position: 'absolute',
                                top: whCss(386),
                                left: whCss(isWidth ? 220 : 80),
                                zIndex: 6,
                            },
                            rightContent: {
                                contentCss: {
                                    position: 'absolute',
                                    top: whCss(204),
                                    right: whCss(80),
                                    width: whCss(isWidth ? 578 : 708),
                                    height: whCss(isWidth ? 456 : 550),
                                },
                                comModuleBgCss: {
                                    position: 'relative',
                                    width: whCss(isWidth ? 578 : 708),
                                    height: whCss(isWidth ? 456 : 550),
                                }

                            }
                        }}
                    />
                </div>
                <div className="cardb">
                    <div className="left">
                        <div className="linfo">
                            <p>{localesReducer.about.pageOne.info.since.title}</p>
                            <p>{localesReducer.about.pageOne.info.since.desc}</p>
                        </div>
                    </div>
                    <div className="right" style={{background: `url(${localesReducer.about.pageOne.info.since.img}) no-repeat 100% 100%/cover`}} />
                </div>
                <div className="cardc" style={{height: localesReducer['navbar.lang'] === 'English' ? whCss(isWidth ? 1172 : 1070) : whCss(isWidth ? isCardBH : 966)}}>
                    <div className="row">
                        <div className="left">
                            <div className="linfo">
                                <div className="limg" style={{background: `url(${localesReducer.about.pageOne.info.la.img}) no-repeat 4% 100%/cover`}} />
                            </div>
                        </div>
                        <div className="right" style={isCardCRightTop}>
                            <p className="title">{localesReducer.about.pageOne.info.la.title}</p>
                            <div className="rectangle"/>
                            <p className="text">{localesReducer.about.pageOne.info.la.desc}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">
                            <p className="title">{localesReducer.about.pageOne.info.cv.title}</p>
                            <div className="rectangle"/>
                            <p className="text">{localesReducer.about.pageOne.info.cv.desc}</p>
                        </div>
                        <div className="right">
                            <div className="rimg" style={{background: `url(${localesReducer.about.pageOne.info.cv.img}) no-repeat 100% 21% / 145%`}} />
                        </div>
                    </div>
                </div>
                <div className="cardd" style={cardDBg}>
                    <div className="gamesBorderTextBox" ref={cardDcRenderDrawTextRef}>
                        <div className="gamesBorderTextBoxPosition">
                            { cardDcRenderDrawFlag ? (
                                <CompanyCultureText
                                    config={{
                                        css: {
                                            width: whCss(isWidth ? 2515 : 1874.62),
                                            height: whCss(isWidth ? hMax : 134.01),
                                        }
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="enterpriseculture">
                        <BlockContents config={{
                            title: localesReducer.about.pageTwo.title,       // 标题
                            list: localesReducer.about.pageTwo.subTitle,     // 副标题
                            flag: false,                 // 状态
                            alignItems: 'flex-end', // flex-start / flex-end
                            status: 1, // 状态 1， 2， 3
                            titleCss: {
                                fontSize: `${20 / 100}rem`,
                                color: '#B0A27C',
                                marginBottom: `${16 / 100}rem`,
                            },
                            listCss: {
                                width: '',
                                height: '',
                                fontSize: `${56 / 100}rem`,
                                textAlign: 'right',
                                color: '#FFF',
                                lineHeight: `${80 / 100}rem`
                            },
                            fLCss: {
                                firstColor: '#FFF',
                                lastColor: '',
                            }
                        }} />
                    </div>
                    <ul className="culturelist">
                        {localesReducer.about.pageTwo.cultureListData.map((item) => {
                            return (
                                <li key={item.id} className="item" style={{marginTop: item.mTop}}>
                                    <div className="card">
                                        <div className="crcectangle">
                                            <div className="cicon"><img src={item.card.img} alt={item.card.title} /></div>
                                            <div className="info">
                                                <p>{item.card.title}</p>
                                                <p>{item.card.text}</p>
                                            </div>
                                        </div>
                                        <div className="cdesc">
                                            <p><i>{item.desc.line}</i><span>{item.desc.num}</span></p>
                                            <p>{item.desc.text}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="carde">
                    <div className="gamesBorderTextBox" ref={cardEdRenderDrawTextRef}>
                        <div className="gamesBorderTextBoxPosition">
                            { cardEdRenderDrawFlag ? (
                                <DevelopmentPathText
                                    config={{
                                        css: {
                                            width: whCss(isWidth ? 2655 : 1965.1),
                                            height: whCss(isWidth ? hMax : 134.01),
                                        }
                                    }}
                                />
                            ) : null }
                        </div>
                    </div>
                    <div className="cdh">
                        <BlockContents config={{
                            title: localesReducer.about.pageThree.title,       // 标题
                            list: localesReducer.about.pageThree.subTitle,     // 副标题
                            flag: false,                 // 状态
                            alignItems: 'flex-start', // flex-start / flex-end
                            status: 1, // 状态 1， 2， 3
                            titleCss: {
                                fontSize: `${20 / 100}rem`,
                                color: '#B0A27C',
                                marginBottom: `${16 / 100}rem`,
                            },
                            listCss: {
                                width: '',
                                height: '',
                                fontSize: `${56 / 100}rem`,
                                textAlign: 'left',
                                color: '#1A1A1A',
                                lineHeight: `${80 / 100}rem`
                            },
                            fLCss: {
                                firstColor: '',
                                lastColor: '',
                            }
                        }} />
                    </div>

                    <ul className="timeline">
                        {localesReducer.about.pageThree.timelineListData.map((item) => {
                            return (
                                <li key={item.id} className="item">
                                    <div className="year"><span>{item.year}</span></div>
                                    <div className={item.id === 1006 ? 'verticallinelast' : 'verticallinefirst'}>
                                        <div className="line"><img src={item.line} alt="line" /></div>
                                    </div>
                                    <div className="month"><span>{item.month}</span></div>
                                    <div className="descInfo">
                                        <div className="desc">
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a title={''}>{item.desc}</a>
                                            {/*<a title={item.desc}>{item.desc}</a>*/}
                                        </div>
                                        <div className="imgbox" style={{background: `url(${item.img}) no-repeat 100% 25% / 100%`}} />
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        );
    }

    const refCarDbBContentLeft = useRef();
    const refCarDbBContentRight = useRef();
    const [refCarDbBContentLeftHeight, setRefCarDbBContentLeft] = useState(0);
    const [refCarDbBContentRightHeight, setRefCarDbBContentRightLeft] = useState(0);
    const [carDBHeight, setCarDBHeight] = useState(0);


    useEffect(() => {
        changeDispatchInner(() => {
            setRefCarDbBContentLeft(refCarDbBContentLeft.current.offsetHeight);
            setRefCarDbBContentRightLeft(refCarDbBContentRight.current.offsetHeight);
            setCarDBHeight(refCarDbBContentLeft.current.offsetHeight+ refCarDbBContentRight.current.offsetHeight- 24);
        });

    }, [refCarDbBContentLeftHeight, refCarDbBContentRightHeight, carDBHeight]);


    const renderH5 = () => {
        const isCarDBHeightCss = {
            height: localesReducer['navbar.lang'] === 'English' ? `${480 / 100}rem` : `${284 / 100}rem`, // 480  'navbar.lang': 'English',
        }

        const isBContentCss = {
            height: localesReducer['navbar.lang'] === 'English' ? `${(480+150-24) / 100}rem` : `${(284+150-24) / 100}rem`, // 480  'navbar.lang': 'English',
        }


        return (
            <div className={styles.normalH5}>
                <div className="carda">
                    <CommonPurposeModulePage
                        config={{
                            status: 1, // 1 ， 2， 3， 4 ，5
                            normalCss: {
                                width: `100%`,
                                background: '#FFF',
                                height: localesReducer['navbar.lang'] === 'English' ? whCss(427) : whCss(351),
                            },
                            rightBox: {
                                img: aboutUsBg,
                                css: {
                                    width: whCss(212.14),
                                    height: whCss(165),
                                    borderTopRightRadius: whCss(230),
                                    borderBottomRightRadius: whCss(230),
                                    overflow: 'hidden',
                                    background: `url(${aboutUsOneBg}) no-repeat 4% 100%/cover`
                                },
                            },
                            purPoseTitle: () => {
                                return (
                                    <AboutUsText
                                        config={{
                                            css: {
                                                width: whCss(303),
                                                height: whCss(40),
                                                marginBottom: whCss(30),
                                                marginLeft: whCss(-8),
                                            }
                                        }}
                                    />
                                )
                            },
                            purPoseContent: () => {
                                return (
                                    <BlockContents config={{
                                        title: localesReducer.about.pageOne.title,       // 标题
                                        // list: localesReducer.about.pageOne.subTitle,     // 副标题
                                        list: localesReducer.about.pageOne.subTitle,     // 副标题
                                        flag: false,                 // 状态
                                        alignItems: 'flex-start', // flex-start / flex-end
                                        status: 2, // 状态 1， 2， 3
                                        titleCss: {
                                            fontSize: whCss(17),
                                            color: '#B0A27C',
                                            marginBottom: whCss(16),
                                        },
                                        listCss: {
                                            width: whCss(366),
                                            height: '',
                                            fontSize: whCss(32),
                                            textAlign: 'left',
                                            color: '#1A1A1A',
                                            lineHeight: whCss(40),
                                            display: 'inline-block !important',
                                            marginLeft: `0 !important`,
                                        },
                                        fLCss: {
                                            firstColor: '#1A1A1A',
                                            lastColor: '#B0A27C',

                                        }
                                    }} />
                                )
                            },
                            leftContentCss: {
                                position: 'absolute',
                                top: whCss(172),
                                left: whCss(24),
                                zIndex: 6,
                            },
                            rightContent: {
                                contentCss: {
                                    position: 'absolute',
                                    top: whCss(100),
                                    right: whCss(17.15),
                                    width: whCss(212.14),
                                    height: whCss(165),
                                },
                                comModuleBgCss: {
                                    position: 'relative',
                                    width: whCss(212.14),
                                    height: whCss(165),
                                }

                            }
                        }}
                    />
                </div>

                <div className="cardb">
                    <div className="bcontent" style={isBContentCss}>
                        <div className="left" style={isCarDBHeightCss} ref={refCarDbBContentLeft}>
                            <div className="linfo">
                                <p>{localesReducer.about.pageOne.info.since.title}</p>
                                <p>{localesReducer.about.pageOne.info.since.desc}</p>
                            </div>
                        </div>
                        <div className="right" ref={refCarDbBContentRight} style={{background: `url(${localesReducer.about.pageOne.info.since.img}) no-repeat 100% 100%/cover`}} />
                    </div>
                </div>

                <div className="cardc">
                    <div className="ccontent">
                        <div className="ldesc">
                            <p className="title">{localesReducer.about.pageOne.info.la.title}</p>
                            <div className="rectangle"/>
                            <p className="text">{localesReducer.about.pageOne.info.la.desc}</p>
                        </div>
                        <div className="cimgBox">
                            <div className="limg" style={{background: `url(${localesReducer.about.pageOne.info.la.img}) no-repeat 0% 100%/cover`}} />
                            <div className="rimg" style={{background: `url(${localesReducer.about.pageOne.info.cv.img}) no-repeat 100% 25% /150%`}}  />
                        </div>
                        <div className="rdesc">
                            <p className="title">{localesReducer.about.pageOne.info.cv.title}</p>
                            <div className="rectangle"/>
                            <p className="text">{localesReducer.about.pageOne.info.cv.desc}</p>
                        </div>
                    </div>
                </div>

                <div className="cardd" style={cardDBgM}>
                    <div className="gamesBorderTextBox" ref={cardDcRenderDrawTextRefM}>
                        { cardDcRenderDrawFlagM ? (
                            <CompanyCultureText
                                config={{
                                    css: {
                                        width: whCss(587.44),
                                        height: whCss(68),
                                    }
                                }}
                            />
                        ) : null }
                    </div>
                    <div className="enterpriseculture">
                        <BlockContents config={{
                            title: localesReducer.about.pageTwo.title,       // 标题
                            list: localesReducer.about.pageTwo.subTitle,     // 副标题
                            flag: false,                 // 状态
                            alignItems: 'flex-end', // flex-start / flex-end
                            status: 1, // 状态 1， 2， 3
                            titleCss: {
                                fontSize: `${17 / 100}rem`,
                                color: '#B0A27C',
                                marginBottom: `${16 / 100}rem`,
                            },
                            listCss: {
                                width: '',
                                height: '',
                                fontSize: `${32 / 100}rem`,
                                textAlign: 'right',
                                color: '#FFF',
                                lineHeight: `${40 / 100}rem`
                            },
                            fLCss: {
                                firstColor: '#FFF',
                                lastColor: '',
                            }
                        }} />
                    </div>
                    <ul className="culturelist">
                        {localesReducer.about.pageTwo.cultureListData.map((item) => {
                            return (
                                <li key={item.id} className="item">
                                    <div className="card">
                                        <div className="crcectangle">
                                            <div className="cicon"><img src={item.card.img} alt={item.card.title} /></div>
                                            <div className="info">
                                                <p>{item.card.title}</p>
                                                <p>{item.card.text}</p>
                                            </div>
                                        </div>
                                        <div className="cdesc">
                                            <p><i>{item.desc.line}</i><span>{item.desc.num}</span></p>
                                            <p>{item.desc.text}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="carde">
                    <div className="gamesBorderTextBox" ref={cardEdRenderDrawTextRefM}>
                        { cardEdRenderDrawFlagM ? (
                            <DevelopmentPathText
                                config={{
                                    css: {
                                        width: whCss(620),
                                        height: whCss(68),
                                    }
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="cdh">
                        <BlockContents config={{
                            title: localesReducer.about.pageThree.title,       // 标题
                            list: localesReducer.about.pageThree.subTitle,     // 副标题
                            flag: false,                 // 状态
                            alignItems: 'flex-start', // flex-start / flex-end
                            status: 1, // 状态 1， 2， 3
                            titleCss: {
                                fontSize: `${17 / 100}rem`,
                                color: '#B0A27C',
                                marginBottom: `${16 / 100}rem`,
                            },
                            listCss: {
                                width: '',
                                height: '',
                                fontSize: `${32 / 100}rem`,
                                textAlign: 'left',
                                color: '#1A1A1A',
                                lineHeight: `${40 / 100}rem`
                            },
                            fLCss: {
                                firstColor: '',
                                lastColor: '',
                            }
                        }} />
                    </div>

                    <ul className="timeline">
                        {localesReducer.about.pageThree.timelineListData.map((item) => {
                            const rowStyle = {
                                // height: `${item.lineHeight / 100}rem`,
                            };
                            return (
                                <li key={item.id} className="item" style={rowStyle}>
                                    <div className="year"><span>{item.year}</span></div>
                                    <div className={item.id === 1006 ? 'verticallinelast' : 'verticallinefirst'} style={rowStyle}>
                                        <div className="line" style={rowStyle}><img src={item.lineH5} style={rowStyle} alt="line" /></div>
                                    </div>
                                    <div className="info">
                                        <div className="month">
                                            <span>{item.month}</span>
                                        </div>
                                        <div className="desc">
                                            <p>{item.desc}</p>
                                        </div>
                                        <div className="imgbox">
                                            <div className="imgbox" style={{background: `url(${item.img}) no-repeat 100% 65% / 100%`}} />
                                        </div>
                                    </div>

                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        );
    }

    if(Object.keys(localesReducer.about).length){
        return recReSizeReducer > 750 ? renderPC() : renderH5();
    }else{
        return null;
    }



});

export default About;