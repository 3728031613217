import React, { memo } from 'react';
import {
    Route,
} from "react-router-dom";

import Home from '../pages/home';
import News from '../pages/news';
import NewsDetail from '../pages/news/Detail';
import Game from '../pages/game';
import About from '../pages/about';
import Join from '../pages/join';
import JoinDetail from '../pages/join/Details';
import Contact from '../pages/contact';

export const routers = [
    {
        path: "/",
        name: '首页',
        exact: true,
        component: Home,
    },
    {
        path: "/about",
        name: '关于我们',
        exact: true,
        component: About,
    },
    {
        path: "/news",
        name: '新闻动态',
        exact: true,
        component: News,
    },
    {
        path: "/news/detail",
        name: '新闻动态/详情',
        exact: true,
        component: NewsDetail,
    },
    {
        path: "/game",
        name: '游戏产品',
        exact: true,
        component: Game,
    },
    {
        path: "/contact",
        name: '联系我们',
        exact: true,
        component: Contact,
    },
    {
        path: "/join",
        name: '加入我们',
        exact: true,
        component: Join,
    },
    {
        path: "/join/detail",
        name: '加入我们/职位详情',
        exact: true,
        component: JoinDetail,
    },

];

const Routers = memo(() => {
    return routers.map((routes, index) => {
        return (
            <Route key={index} {...routes} />
        )
    });
});

export default Routers;