const EventEmitter = require('events');

export const recalcRM = ({cab}) => {
    let designSize = 1920;
    const html = document.documentElement;
    let cw = html.clientWidth;
    if (cw <= 750) {
        designSize = 414;
    }

    if (cw > 750 && cw <= 1440) {
        designSize = 1440;
    }

    if (cw > 1440 && cw <= 2560) {
        // designSize = 2560;
        designSize = cw;
    }

    const w = cw;
    const remSize = (w / designSize) * 100;
    html.style.fontSize = `${remSize}px`;
    cab(w);
};

const getScrollTop = ({callback}) => {
    const scrollTopVal = document.documentElement.scrollTop;
    const cHSTopVal = scrollTopVal + document.documentElement.clientHeight;
    let scrollVal;
    if (scrollTopVal <= 20) {
        scrollVal = 0;
    } else {
        scrollVal = scrollTopVal;
    }
    callback({scrollVal, cHSTopVal});
}

//获取文档内容的的高度
export const handleScroll = ({cab}) => {
    window.addEventListener('scroll', () => {
        getScrollTop({
            callback: ({scrollVal, cHSTopVal}) => {
                cab({scrollVal, cHSTopVal});
            }
        });
    }, false);
}


// 回到顶部
export const backTo = () => {
    const val = storage('scrollTopKey').getStorage();
    document.documentElement.scrollTop = val ? val : 0;
}

export const getBackTo = () => {
    document.documentElement.scrollTop = 0;
}


export const getOffset = (curEle) => {
    let par = curEle.offsetParent, // 获取父级参照物
        l = curEle.offsetLeft, // 获取左边距离我的参照物的距离
        t = curEle.offsetTop; // 获取上边距离我的参照物的距离

    // 循环
    while (par && par.tagName !== 'BODY') {
        if (!/MSIE 8\./.test(navigator.userAgent)) {
            l += par.clientWidth;
            t += par.clientHeight;
        }
        l += par.offsetLeft;
        t += par.offsetTop;
        par = par.offsetParent;
    }

    return {
        top: t,
        left: l,
    }

};

export const getParentElement = (parentEle) => {
    return parentEle;
};

export const delay = (ms, callback) => {
    setTimeout(() => {
        callback();
    }, ms);
};

export const delayM = (ms, callback) => {
    setTimeout(() => {
        callback();
    }, ms);
};

export const isScreenHeight = window.screen.height - window.screen.availHeight;

//是移动端返回true,否则false
export const isMobile = () => {
    return (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(navigator.userAgent))
};

export const storage = (key, val) => {
    return {
        setStorage: () => sessionStorage.setItem(key, JSON.stringify(val)),
        getStorage: () => JSON.parse(sessionStorage.getItem(key)),
        removeStorage: () => sessionStorage.removeItem(key),
    }
}


let innerAnimateScrollList = {};
export const getInnerAnimateScrollList = (args) => {
    innerAnimateScrollList = args;
};

export const handleHeadNavLinkJump = (callback) => {
    callback(innerAnimateScrollList);
};

export const whCss = (wh) => {
    const mathNum = 100;
    return `${wh / mathNum}rem`;
};

// 创建事件回调
let initFunc = null;
export const changeDispatchEvent = () => {
    if(Object.prototype.toString.call(initFunc) === `[object Function]`){
        initFunc();
    }
}
export const changeDispatchInner = (callback) => {
    initFunc = callback
}


// 事件触发
/**
 *
 * @param currentEle    元素
 * @param eventMethod   事件方法
 * @param data          数据
 * @param callback      事件回调
 */
export const eventRun = ({currentEle, eventMethod, data, callback}) => {
    const event = new EventEmitter();
    const videoMessage = (msg) => {
        callback({
            ele: currentEle, // 元素
            message: msg,    // 消息
        });
    };
    event.on(eventMethod, videoMessage);
    event.emit(eventMethod, data ? data : {});
}

// 发送邮箱 📪
export const mailtoSubmit = ({eMailAddress}) => {
    const who = prompt('请输入收件人邮箱: ', eMailAddress);
    const what = prompt('输入主题: ', 'Note');
    if (window.confirm(`你确定要向 ${who} 发送主题为 ${what} 的邮件么?`) === true) {
        window.parent.location.href = `mailto: ${who}?subject=${what} `;
    }
}





