import React, { memo, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { actions } from '@/models/stores/actions';
import { PositionText } from "@/components/FontStrokeCommTexts";
import styles from './Index.module.scss';
import {whCss, getBackTo, mailtoSubmit} from "@/utils";

const Details = memo((props) => {
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const { handleJobDetail } = actions;
    const dispatch = useDispatch();
    const bRenderDrawText = useMemo(() => {
        const isWidth = recReSizeReducer >= 1920;
        const hMax = 176.76;
        return recReSizeReducer > 750 ? (
            <PositionText
                config={{
                    css: {
                        width: whCss(isWidth ? 1243 : 906.27),
                        height: whCss(isWidth ? hMax : 134.01),
                    }
                }}
            />
        ): (
            <PositionText
                config={{
                    css: {
                        width: whCss(280.4),
                        height: whCss(40),
                        // border: '1px solid red',
                        paddingRight: whCss(5)
                    }
                }}
            />
        )
    }, [recReSizeReducer]);

    useEffect(() => {
        getBackTo();
        return () => {
            dispatch(handleJobDetail({}))
        }
    },[]);

    const handleBackToList = () => {
        const { history, location } = props;
        const queryUrlParams = location.search.split('?')[1];
        let isProgramId = qs.parse(queryUrlParams).id;
        history.push(`/join?id=${isProgramId}`);
    }


    const jobDetailData = () => {
        const { location } = props;
        const queryUrlParams = location.search.split('?')[1];
        let id = parseInt(qs.parse(queryUrlParams).id);
        let key = parseInt(qs.parse(queryUrlParams).key);
       const datils = localesReducer.join.pageFour.programListData.filter((item ,index) => item.id === id);
       const { list } = datils[0];
       const jobDetailDatas = list.filter(item => item.key === key);
        try{
            const {
                detailsTitle,
                positionName,
                address,
                emailName,
                email,
                details:{
                    responsibilities,
                    requirements,
                }
            } = jobDetailDatas[0];
            return (
                <>
                    <div className="info">
                        <p>{detailsTitle}</p>
                        <h1>{positionName}</h1>
                        <p>{address}</p>
                    </div>
                    <div className="row">
                        <h2>{responsibilities.title}</h2>
                        <div className="desc">
                            { responsibilities.descList.map((item, index) => {
                                return (
                                    <p key={index}>{item}</p>
                                )
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <h2>{requirements && requirements.title}</h2>
                        <div className="desc">
                            {requirements.descList.map((item, index) => {
                                return (
                                    <p key={index}>{item}</p>
                                )
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <h3 onClick={() => mailtoSubmit(email)}>{emailName}：{email}</h3>
                    </div>
                </>
            )
        } catch (e) {
            handleBackToList();
        }
    };

    const isNormal = recReSizeReducer > 750 ? (recReSizeReducer >= 1920 ? styles.normalMax : styles.normal) : styles.normalH5;

    return (
        <div className={isNormal}>
            <div className="content">
                <div className="textanimate">{ bRenderDrawText }</div>
                <div className="jobdetails">
                    { jobDetailData() }
                </div>
                <div className="backtolist">
                    <div className="line" />
                    <div className="scrollAnimation" onClick={handleBackToList}>
                        <div className="scrollText">
                            <span>BACK TO LIST</span>
                            <span>BACK TO LIST</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

});
export default withRouter(Details);