import React, {memo, useEffect, useMemo, useRef} from 'react';
import { useSelector } from 'react-redux';

import styles from './Index.module.scss';
import {eventRun} from "../../utils";

// 视频播放器
const WelcomeVisit = memo(({config}) => {
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);

    const { source, css, videoCss } = config;
    const videoRef = useRef();

    useEffect(() => {
        // 事件触发行为
        eventRun({
            currentEle: videoRef,               // 当前的元素-必传
            eventMethod: 'videoClick',          // 自定义事件-必传
            data: {name: 'Hello Video !!!!!!'}, // 数据-可选参数
            callback: ({ele, message }) => {    // 回调函数
                ele.current.play();
                ele.current.muted = true;
            },
        });
    }, []);

    const videoTemplate = useMemo(() => {
        return (
            <video
                id="video"
                ref={videoRef}
                className="video"
                autoPlay
                playsInline
                loop
                muted
                style={videoCss}
            >
                <source src={source} type="video/mp4" />
                <source src={source} type="video/ogg" />
                <p>您的浏览器不支持Video标签。</p>
                <p>Your browser does not support video tags.</p>
            </video>
        )
    }, [source, videoCss]);


    // PC
    const renderPC = () => {
        return (
            <div className={styles.normal} style={css}>
                {videoTemplate}
            </div>
        );
    }

    // H5
    const renderH5 = () => {
        return (
            <div className={styles.normalH5} style={css}>
                {videoTemplate}
            </div>
        );
    }

    return recReSizeReducer > 750 ? renderPC() : renderH5();
});
export default WelcomeVisit;