import React, {memo, useCallback, useState} from 'react';
import { withRouter } from 'react-router-dom';
import {useSelector} from "react-redux";
import { routers } from '@/routers';
import { getBackTo, storage } from '@/utils';
import styles from './Index.module.scss';

import wechat from '@/assets/public/wechat.svg';
import wechata from '@/assets/public/wechata.svg';
import weibo from '@/assets/public/weibo.svg';
import weiboa from '@/assets/public/weiboa.svg';



const Footer = memo((props) => {
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const [isWechat, setIsWechat] = useState(false);
    const [isWeibo, setIsWeibo] = useState(false);

    const handleLinkJump = useCallback((item) => {
        const { history } = props;
        window.document.title = item.name;
        history.push(item.path);
        getBackTo();
        storage('scrollTopKey').removeStorage();
        storage('details').removeStorage();
    }, [props]);

    const renderLink = () => {
        const routersList = routers.filter(item => item.path !== '/' && item.path !== '/news/detail' && item.path !== '/join/detail');
        const localesReducerList = localesReducer.navbar.menus.filter(item => item.name !== '首页' && item.name !== 'HOME');

        const aryList = routersList.map((item, index) => {
            let obj = {};

            // eslint-disable-next-line array-callback-return
            localesReducerList.map((k, j) => {
                if(index === j){
                    obj = {
                        path: item.path,
                        name: k.name,
                        pcw: k.pcw,
                        h5w: k.h5w,
                    }
                }
            });
            return obj;
        });

        return aryList.map((item, index) => (<p key={index} onClick={() => handleLinkJump(item)}>{item.name}</p>))
    }

    const handleMouseOver = useCallback((type) => {
        if(type === 'wechat'){
            setIsWechat(true);
        }else{
            setIsWeibo(true);
        }
    }, []);

    const handleMouseOut = useCallback((type) => {
        if(type === 'wechat'){
            setIsWechat(false);
        }else{
            setIsWeibo(false);
        }

    }, []);

    const handleJumpHome = () => {
        const { history } = props;
        window.document.title = localesReducer.navbar.menus[0].name;
        history.push('/');
        getBackTo();
        storage('scrollTopKey').removeStorage();
        storage('details').removeStorage();
    }

    const isNormal = recReSizeReducer >=1920 ? styles.footerMax : styles.footer;
    const isWidth = recReSizeReducer >=1920;

    return recReSizeReducer > 750 ? (
        <div className={isNormal}>
            <div className="content">
                <div className="left">
                    <div className="logo" onClick={handleJumpHome}><img src={localesReducer.footer.footerLogo} alt="" /></div>
                </div>
                <div className="center">
                    <p>{localesReducer.footer.title}</p>
                    {renderLink()}
                </div>
                <div className="right">
                    <div className="fame">
                        {isWidth ? (
                            localesReducer.footer.fameListMax.map((item, index) => (<p key={index}>{item}</p>))
                        ):(
                            localesReducer.footer.fameList.map((item, index) => (<p key={index}>{item}</p>))
                        )}
                    </div>
                    <div className="community">
                        <div className="cicon" onMouseOver={() => handleMouseOver('wechat')} onMouseOut={() => handleMouseOut('wechat')} ><img src={isWechat ? wechata : wechat} alt="wx" /></div>
                        <div className="cicon" onMouseOver={() => handleMouseOver('weibo')} onMouseOut={() => handleMouseOut('weibo')}><img src={isWeibo ? weiboa : weibo} alt="sina" /></div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className={styles.footerH5}>
            <div className="row">
                <div className="logo" onClick={handleJumpHome}><img src={localesReducer.footer.footerLogo} alt="" /></div>
            </div>
            <div className="row">
                <div className="community">
                    <div className="cicon" onMouseOver={() => handleMouseOver('wechat')} onMouseOut={() => handleMouseOut('wechat')} ><img src={isWechat ? wechata : wechat} alt="wx" /></div>
                    <div className="cicon" onMouseOver={() => handleMouseOver('weibo')} onMouseOut={() => handleMouseOut('weibo')}><img src={isWeibo ? weiboa : weibo} alt="sina" /></div>
                </div>
            </div>
            <div className="row">
                <div className="center">
                    {renderLink()}
                </div>
            </div>
            <div className="row">
                <div className="fame">
                    {localesReducer.footer.fameList.map((item, index) => (<p key={index}>{item}</p>))}
                </div>
            </div>
        </div>
    );
});


export default withRouter(Footer);