import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import WelcomeVisit from '../../components/WelcomeVisit';
import BlockContents from "../../components/BlockContents";
import styles from './Index.module.scss';
import {whCss, mailtoSubmit} from "../../utils";
import contact from "../../assets/contact/contact.png";
import {ContactText} from "../../components/FontStrokeCommTexts";
import CommonPurposeModulePage from "../../components/CommonPurposeModulePage";

const Contact = memo(() => {
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const { cshVal } = useSelector(({scrollNumReducer}) => scrollNumReducer);

    const renderPC = () => {
        const isNormal = recReSizeReducer >= 1920 ? styles.normalMax : styles.normal;
        const isWidth = recReSizeReducer >= 1920;
        const hMax = 176.76;
        return (
            <div className={isNormal}>
                <div className="carda">
                    <div className="content">
                        <CommonPurposeModulePage
                            config={{
                                status: 1, // 1 ， 2， 3， 4 ，5
                                normalCss: {
                                    width: `100%`,
                                    background: '#FFF',
                                    height: localesReducer['navbar.lang'] === 'English' ? whCss(isWidth ? 843 : 789) : whCss(isWidth ? 768 : 714),
                                },
                                rightBox: {
                                    img: contact,
                                    css: {
                                        width: whCss(isWidth ? 788 : 639),
                                        height: whCss(isWidth ? 550 : 446),
                                        overflow: 'hidden',
                                    },
                                },
                                purPoseTitle: () => {
                                    return (
                                        <ContactText
                                            config={{
                                                css: {
                                                    width: whCss(isWidth ? 1210 : 895.38),
                                                    height: whCss(isWidth ? hMax : 134.01),
                                                    marginBottom: whCss(50),
                                                }
                                            }}
                                        />
                                    )
                                },
                                purPoseContent: () => {
                                    return (
                                        <BlockContents config={{
                                            title: localesReducer.contact.pageOne.title,       // 标题
                                            list: localesReducer.contact.pageOne.subTitle,     // 副标题
                                            flag: false,                 // 状态
                                            alignItems: 'flex-start', // flex-start / flex-end
                                            status: 1, // 状态 1， 2， 3
                                            titleCss: {
                                                fontSize: whCss(20),
                                                color: '#B0A27C',
                                                marginBottom: whCss(16),
                                            },
                                            listCss: {
                                                width: '',
                                                height: '',
                                                fontSize: whCss(56),
                                                textAlign: 'left',
                                                color: '#1A1A1A',
                                                lineHeight: whCss(80),
                                            },
                                            fLCss: {
                                                firstColor: '#1A1A1A',
                                                lastColor: '#B0A27C',
                                            }
                                        }} />
                                    )
                                },
                                leftContentCss: {
                                    position: 'absolute',
                                    top: whCss(isWidth ? 386 : 375),
                                    left: whCss(isWidth ? 220 : 80),
                                    zIndex: 6,
                                },
                                rightContent: {
                                    contentCss: {
                                        position: 'absolute',
                                        top: whCss(204),
                                        right: whCss(0),
                                        width: whCss(isWidth ? 788 : 639),
                                        height: whCss(isWidth ? 550 : 446),
                                    },
                                    comModuleBgCss: {
                                        position: 'relative',
                                        width: whCss(isWidth ? 788 : 639),
                                        height: whCss(isWidth ? 550 : 446),
                                    }

                                }
                            }}
                        />
                    </div>
                    <div className="list">
                        <ul>
                            {
                                localesReducer.contact.pageOne.list.map((item) => {
                                    return (
                                        <li key={item.id}>
                                            <div className="icon"><img src={item.icon} alt={item.title} /></div>
                                            <div className="desc">
                                                <p>{item.title}</p>
                                                <p onClick={() => mailtoSubmit(item.email)}>{item.email}</p>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="cardb">
                    <WelcomeVisit
                        config={{
                            scrollNum: cshVal,
                        }}
                    />
                </div>

            </div>
        );
    }

    const renderH5 = () => {
        const isLang = localesReducer['navbar.lang'] === 'English';
        return (
            <div className={styles.normalh5}>
                <div className="carda">
                    <div className="content">
                        <CommonPurposeModulePage
                            config={{
                                status: 1, // 1 ， 2， 3， 4 ，5
                                normalCss: {
                                    width: `100%`,
                                    background: '#FFF',
                                    height: isLang ? whCss(468) : whCss(433),
                                },
                                rightBox: {
                                    img: contact,
                                    css: {
                                        width: whCss(236.4),
                                        height: whCss(165),
                                        overflow: 'hidden',
                                    },
                                },
                                purPoseTitle: () => {
                                    return (
                                        <ContactText
                                            config={{
                                                css: {
                                                    width: whCss(282),
                                                    height: whCss(68),
                                                    marginBottom: whCss(30),
                                                }
                                            }}
                                        />
                                    )
                                },
                                purPoseContent: () => {
                                    return (
                                        <BlockContents config={{
                                            title: localesReducer.contact.pageOne.title,       // 标题
                                            list: isLang ? [`${localesReducer.contact.pageOne.subTitle[0]}${localesReducer.contact.pageOne.subTitle[1]}`] : localesReducer.contact.pageOne.subTitle,     // 副标题
                                            flag: false,                 // 状态
                                            alignItems: 'flex-start', // flex-start / flex-end
                                            status: 1, // 状态 1， 2， 3
                                            titleCss: {
                                                fontSize: whCss(20),
                                                color: '#B0A27C',
                                                marginBottom: whCss(16),
                                            },
                                            listCss: {
                                                width: whCss(298),
                                                height: '',
                                                fontSize: whCss(32),
                                                textAlign: 'left',
                                                color: '#1A1A1A',
                                                lineHeight: whCss(40),
                                            },
                                            fLCss: {
                                                firstColor: '#1A1A1A',
                                                lastColor: '#B0A27C',
                                            }
                                        }} />
                                    )
                                },
                                leftContentCss: {
                                    position: 'absolute',
                                    top: whCss(182),
                                    left: whCss(24),
                                    zIndex: 6,
                                },
                                rightContent: {
                                    contentCss: {
                                        position: 'absolute',
                                        top: whCss(102),
                                        right: whCss(0),
                                        width: whCss(236.4),
                                        height: whCss(165),
                                    },
                                    comModuleBgCss: {
                                        position: 'relative',
                                        width: whCss(236.4),
                                        height: whCss(165),
                                    }

                                }
                            }}
                        />
                    </div>
                    <div className="list">
                        <ul>
                            {
                                localesReducer.contact.pageOne.list.map((item) => {
                                    return (
                                        <li key={item.id}>
                                            <div className="icon"><img src={item.icon} alt={item.title} /></div>
                                            <div className="desc">
                                                <p>{item.title}</p>
                                                <p onClick={() => mailtoSubmit(item.email)}>{item.email}</p>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="cardb">
                    <WelcomeVisit
                        config={{
                            scrollNum: cshVal,
                        }}
                    />
                </div>

            </div>
        );
    }

    if(Object.keys(localesReducer.contact).length){
        return recReSizeReducer > 750 ? renderPC() : renderH5();
    }else{
        return null;
    }
});

export default Contact;