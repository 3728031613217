import React, { memo, useState, useEffect } from 'react';
import styles from './Index.module.scss';
// 产品动态
import scroll from './fonts/SCROLL.svg';

const GroupRectangle = memo((props) => {
    const { css } = props.config;
    const renderLine = () => {
        const lineHeight = 148;
        const css = {
            position: 'absolute',
            top: `-${lineHeight}rem`,
        }
        return (<div className={`line ${styles.lineFend}`} style={css} />);
    }

    return (
        <div className={styles.normal}>
            <div className="scroll">
                <img src={scroll} alt=""/>
            </div>
            <div className="rectangle" style={css}>
                {renderLine()}
            </div>
        </div>
    );
});
export default GroupRectangle;