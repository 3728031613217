import React, {memo, useCallback, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../models/stores/actions";
import { whCss } from "../../utils";

import styles from './Index.module.scss';
import {routers} from "../../routers";

const Footer = memo((props) => {
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const {lang} = useSelector(({localesReducer}) => localesReducer);
    const dispatch = useDispatch();
    const { localesI18n } = actions;

    // 语言切换
    const handleLocales = useCallback((lang) => {
        const pathName = props.location.pathname;
        if(pathName !== "/news/detail" && pathName !== '/join/detail'){
            if(lang === 'zhCN'){
                localStorage.setItem('lang', lang);
                const langType = localStorage.getItem('lang');
                dispatch(localesI18n({lang: langType}));
            }else{
                localStorage.setItem('lang', lang);
                const langType = localStorage.getItem('lang');
                dispatch(localesI18n({lang: langType}));
            }
        }


    }, [dispatch, localesI18n, props.location.pathname]);

    useEffect(() => {
        const { location } = props;
        // 设置BOM浏览器的标题
        const pathName = routers.filter(item => item.path === location.pathname)[0];
        window.document.title = pathName.name;
    }, [dispatch, localesI18n, props]);

    const isNormal = recReSizeReducer <= 750 ? styles.normalH5 : styles.normal;

    /*return (
        <div className={isNormal}>
            <span onClick={() => handleLocales('zhCN')} className={lang ===  'zhCN' ? '' : 'langActive'}>中文</span>
            <span className="line" />
            <span onClick={() => handleLocales('enUS')} className={lang ===  'enUS' ? '' : 'langActive'}>EN</span>
        </div>
    );*/
    const isWidth = recReSizeReducer >=1920;

    const isHeight = {
        height: lang ===  'zhCN' ? whCss(24) : whCss(30),
        minHeight: lang ===  'zhCN' ? '24px' : '30px',
    }
    const isLineH= {
        marginTop: lang ===  'zhCN' ? whCss(-2.2) : whCss(-8),
    }



   return  recReSizeReducer > 750 ? (
       <div className={isNormal}>
           <div className="zhCn" onClick={() => handleLocales('zhCN')}>
               <div className="scrollAnimation" style={isHeight}>
                   <div className="scrollText">
                       <span className={lang ===  'zhCN' ? '' : 'langActive'}>中文</span>
                       <span className={lang ===  'zhCN' ? '' : 'langActive'}>中文</span>
                   </div>
               </div>
           </div>
           <div className="lines" style={isLineH} />
           <div className="enUs" onClick={() => handleLocales('enUS')} >
               <div className="scrollAnimation" style={isHeight}>
                   <div className="scrollText">
                       <span className={lang ===  'enUS' ? '' : 'langActive'}>EN</span>
                       <span className={lang ===  'enUS' ? '' : 'langActive'}>EN</span>
                   </div>
               </div>
           </div>
       </div>
   ) : (
       <div className={isNormal}>
           <span onClick={() => handleLocales('zhCN')} className={lang ===  'zhCN' ? '' : 'langActive'}>中文</span>
           <span className="line" />
           <span onClick={() => handleLocales('enUS')} className={lang ===  'enUS' ? '' : 'langActive'}>EN</span>
       </div>
   );

});


export default withRouter(Footer);