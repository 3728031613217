import { userInfoSlice } from './userInfo';
import { aboutInfoSlice } from './aboutInfo';
import { localesSlice } from './locales';
import { scrollNumSlice } from './scrollNum';
import { newsDetailSlice } from './newsDetail';
import { jobDetailSlice } from './jobDetail';
import { recReSizeSlice } from './recReSize';

export const reduces = {
    userInfoSlice,
    aboutInfoSlice,
    localesSlice,
    scrollNumSlice,
    newsDetailSlice,
    jobDetailSlice,
    recReSizeSlice,
};

