// home主页 start
import slogan from '../../assets/home/one/slogan.svg';
// pageThree
import threeImg1_0 from "../../assets/home/three/gimg1/g100.png";
import threeImg2_0 from "../../assets/home/three/gimg2/g100.png";
import threeImg2_1 from "../../assets/home/three/gimg2/g101.png";
import threeImg3_0 from "../../assets/home/three/gimg3/g100.png";
import threeImg4_0 from "../../assets/home/three/gimg4/g100.png";
import threeImg5_0 from "../../assets/home/three/gimg5/g100.png";


import threeNewImg1_0 from "../../assets/home/three/gimg1/ng100.jpg";
import threeNewImg2_0 from "../../assets/home/three/gimg2/ng100.jpg";
import threeNewImg3_0 from "../../assets/home/three/gimg3/ng100.jpg";
import threeNewImg4_0 from "../../assets/home/three/gimg4/ng100.jpg";
import threeNewImg5_0 from "../../assets/home/three/gimg5/ng100.jpg";


// pageFour
import titem1 from "../../assets/home/four/titem_1.png";
import titem2 from "../../assets/home/four/titem_2.png";
import titem3 from "../../assets/home/four/titem_3.png";
import titem4 from "../../assets/home/four/titem_4.png";

// pageFives
import fiveIcon1 from "../../assets/home/five/icon_1.svg";
import fiveIcon2 from "../../assets/home/five/icon_2.svg";
import fiveIcon3 from "../../assets/home/five/icon_3.svg";
// home主页 end


// about关于我们 start
// pageOne
import since from "../../assets/about/card2/since.png";
import sinceh5 from "../../assets/about/card2/sinceh5.svg";
import istockphoto_1 from "../../assets/about/card3/istockphoto_1.jpeg";
import istockphoto_2 from "../../assets/about/card3/istockphoto_2.png";
// pageTwo
import culturesIcon01 from "../../assets/about/card4/cultures/c1001.svg";
import culturesIcon02 from "../../assets/about/card4/cultures/c1002.svg";
import culturesIcon03 from "../../assets/about/card4/cultures/c1003.svg";
import culturesIcon04 from "../../assets/about/card4/cultures/c1004.svg";
import linecirclefirst from "../../assets/about/card5/img_1/linecirclefirst.svg";
import linecirclefirsth5 from "../../assets/about/card5/img_1/linecirclefirstzhh5.svg";

import timeImg1 from "../../assets/about/card5/img_1/img_1.png";
import timeImg2 from "../../assets/about/card5/img_1/img_2.png";
import timeImg3 from "../../assets/about/card5/img_1/img_3.png";
import timeImg4 from "../../assets/about/card5/img_1/img_4.png";
import timeImg5 from "../../assets/about/card5/img_1/img_5.png";
import timeImg6 from "../../assets/about/card5/img_1/img_6.png";
import linecirclelast from "../../assets/about/card5/img_1/linecirclelast.svg";
import linecirclelasth5 from "../../assets/about/card5/img_1/linecirclelastzhh5.svg";
// about关于我们 end

// news新闻动态 start
import newsImg01 from "../../assets/news/list/news/titem_1.png";
import newsImg02 from "../../assets/news/list/news/titem_3.png";
import newsImg03 from "../../assets/news/list/news/titem_4.png";
import productImg01 from "../../assets/news/list/product/img01.png";
import productImg02 from "../../assets/news/list/product/img02.png";
import productImg03 from "../../assets/news/list/product/img03.png";
// news新闻动态 end

// game游戏产品 start
import img01 from "../../assets/game/list/img01.jpeg";
import img02 from "../../assets/game/list/img02.png";
import img03 from "../../assets/game/list/img03.png";
import img04 from "../../assets/game/list/img04.png";
import img05 from "../../assets/game/list/img05.png";

import imgNg01 from "../../assets/game/list/ng100.jpg";
import imgNg02 from "../../assets/game/list/ng200.jpg";
import imgNg03 from "../../assets/game/list/ng300.jpg";
// game游戏产品 end
// contact联系我们 start
import icon1 from '../../assets/contact/email/icon01.svg';
import icon2 from '../../assets/contact/email/icon02.svg';
import icon3 from '../../assets/contact/email/icon03.svg';
// contact联系我们 end

// join加入我们 start
import benefits1 from "../../assets/join/card1/list/benefits1.svg";
import benefits2 from "../../assets/join/card1/list/benefits2.svg";
import benefits3 from "../../assets/join/card1/list/benefits3.svg";
import benefits4 from "../../assets/join/card1/list/benefits4.svg";
import benefits5 from "../../assets/join/card1/list/benefits5.svg";
import benefits6 from "../../assets/join/card1/list/benefits6.svg";
import benefits7 from "../../assets/join/card1/list/benefits7.svg";
import benefits8 from "../../assets/join/card1/list/benefits8.svg";
// pageThree
import imga101 from "../../assets/join/card2/lista/img1.png";
import imga102 from "../../assets/join/card2/lista/img2.png";
import imga201 from "../../assets/join/card2/lista/img3.png";
import imga202 from "../../assets/join/card2/lista/img4.png";
import imga301 from "../../assets/join/card2/lista/img5.jpeg";
import imga302 from "../../assets/join/card2/lista/img6.png";


import imgb101 from "../../assets/join/card2/listb/img1.png";
import imgb102 from "../../assets/join/card2/listb/img2.png";
import imgb201 from "../../assets/join/card2/listb/img3.png";
import imgb202 from "../../assets/join/card2/listb/img4.png";
import imgb301 from "../../assets/join/card2/listb/img5.png";
import imgb302 from "../../assets/join/card2/listb/img6.png";
// pageFour
import program1 from "../../assets/join/card3/list/program1.svg";
import program2 from "../../assets/join/card3/list/program2.svg";
import program3 from "../../assets/join/card3/list/program3.svg";
import program4 from "../../assets/join/card3/list/program4.svg";
import program5 from "../../assets/join/card3/list/program5.svg";
//
// join加入我们 end

// game游戏产品 style start
const flexLeft = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}
const flexRight = {
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}
const mlr = {margin: `0 ${56 / 100}rem`}
// game游戏产品 style end

// global config file start
const {
  logos: {
    header,             // 头部导航 LOGO
    footer,             // 底部导航 LOGO
  },
  websiteRecord,        // 网站备案
  companyName,          // 公司名字
  companyAddress,       // 公司地址
  contactInformation,   // 联系方式
  emailsTo,             // 品牌合作/商业计划书/个人简历/招聘邮箱
} = window.globalConfig;
// global config file end

const zhCN = {
  'navbar.lang': '中文',
  navbar: {
    headerLogo: header.wordImgZH,
    menus:[
      {
        id: 1001,
        name: '首页',
        pcw: 36,
        h5w: 37,
      },
      {
        id: 1002,
        name: '关于我们',
        pcw: 72,
        h5w: 72,
      },
      {
        id: 1003,
        name: '新闻动态',
        pcw: 72,
        h5w: 72,
      },
      {
        id: 1004,
        name: '游戏产品',
        pcw: 72,
        h5w: 72,
      },
      {
        id: 1005,
        name: '联系我们',
        pcw: 72,
        h5w: 72,
      },
      {
        id: 1006,
        name: '加入我们',
        pcw: 72,
        h5w: 72,
      },
    ]
  },
  footer: {
    title: companyName.abbreviationZH,
    footerLogo: footer.logoZH,
    fameList:[
        '健康游戏忠告 抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当',
        '适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活',
        `${websiteRecord.zh}`,
        `${companyName.fullNameZH} ©版权所有 互联网违法不良信息举报电话：${contactInformation.reportPhone}`,
    ],
    fameListMax:[
        '健康游戏忠告 抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活',
        `${websiteRecord.zh}`,
        `${companyName.fullNameZH} ©版权所有 互联网违法不良信息举报电话：${contactInformation.reportPhone}`,
    ],
  },
  home: {
    title: '首页',
    pageOne:{
      'slogan': slogan,
    },
    pageTwo: {
      title: companyName.abbreviationZH,
      subTitle: [
        '为全球玩家',
        '创造快乐与惊喜',
      ],
      texts: ['SINCE 2012', '____________'],
      desc: [
          '我们组建了强有力的研发团队，致力于自主研发和海内外本地化发行，为世界各地的玩家输送更有趣的游戏产品。',
      ],
      readMore: '了解更多',
    },
    pageThree: {
      plusMore: '+  更多游戏',
      threeNewTabsListData: [
        {
          id: 101,
          img: threeNewImg1_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg1_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: '白狼杀',
              subTitle: '⽩狼杀是⼀款⽹杀和⾯杀双结合的O2O狼⼈杀游戏。在常规的⽹上狼⼈杀的基础上，我们打破了狼⼈杀的空间局限性，实现玩家⽆论是在线下的桌游店还是在线上均可⽆缝对战，从⽽实现桌游店座位⾼利⽤率。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '休闲益智',
              },
              {
                name: '平台',
                desc: 'Android、iOS',
              },
              {
                name: '模式',
                desc: '多人',
              },
              {
                name: '发行时间',
                desc: '2021年',
              },
            ],
          }
        },
        {
          id: 102,
          img: threeNewImg2_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg2_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: '白狼杀',
              subTitle: '⽩狼杀是⼀款⽹杀和⾯杀双结合的O2O狼⼈杀游戏。在常规的⽹上狼⼈杀的基础上，我们打破了狼⼈杀的空间局限性，实现玩家⽆论是在线下的桌游店还是在线上均可⽆缝对战，从⽽实现桌游店座位⾼利⽤率。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '休闲益智',
              },
              {
                name: '平台',
                desc: 'Android、iOS',
              },
              {
                name: '模式',
                desc: '多人',
              },
              {
                name: '发行时间',
                desc: '2021年',
              },
            ],
          }
        },
        {
          id: 103,
          img: threeNewImg3_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg3_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: '白狼杀',
              subTitle: '⽩狼杀是⼀款⽹杀和⾯杀双结合的O2O狼⼈杀游戏。在常规的⽹上狼⼈杀的基础上，我们打破了狼⼈杀的空间局限性，实现玩家⽆论是在线下的桌游店还是在线上均可⽆缝对战，从⽽实现桌游店座位⾼利⽤率。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '休闲益智',
              },
              {
                name: '平台',
                desc: 'Android、iOS',
              },
              {
                name: '模式',
                desc: '多人',
              },
              {
                name: '发行时间',
                desc: '2021年',
              },
            ],
          }
        },
        {
          id: 104,
          img: threeNewImg4_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg4_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: '白狼杀',
              subTitle: '⽩狼杀是⼀款⽹杀和⾯杀双结合的O2O狼⼈杀游戏。在常规的⽹上狼⼈杀的基础上，我们打破了狼⼈杀的空间局限性，实现玩家⽆论是在线下的桌游店还是在线上均可⽆缝对战，从⽽实现桌游店座位⾼利⽤率。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '休闲益智',
              },
              {
                name: '平台',
                desc: 'Android、iOS',
              },
              {
                name: '模式',
                desc: '多人',
              },
              {
                name: '发行时间',
                desc: '2021年',
              },
            ],
          }
        },
        {
          id: 105,
          img: threeNewImg5_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeNewImg5_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: '白狼杀',
              subTitle: '⽩狼杀是⼀款⽹杀和⾯杀双结合的O2O狼⼈杀游戏。在常规的⽹上狼⼈杀的基础上，我们打破了狼⼈杀的空间局限性，实现玩家⽆论是在线下的桌游店还是在线上均可⽆缝对战，从⽽实现桌游店座位⾼利⽤率。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '休闲益智',
              },
              {
                name: '平台',
                desc: 'Android、iOS',
              },
              {
                name: '模式',
                desc: '多人',
              },
              {
                name: '发行时间',
                desc: '2021年',
              },
            ],
          }
        },
      ],
      threeTabsListData: [
        {
          id: 101,
          img: threeImg1_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `48%`,
            lr: `0%`
          },
          contents:{
            img: threeImg1_0,
            styles:{
              lr: `100%`,
              tb: `30%`,
            },
            desc: {
              title: '天外世界',
              subTitle: '《天外世界》是款第一人称科幻角色扮演游戏，故事背景设定在银河系边缘的太空殖民地，故事叙述主角因为太空船的错误而漂流到银河系边缘，从冷冻睡眠中醒来后孤身一人的他，将展开闯荡边陲殖民地的冒险。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '動作角色扮演',
              },
              {
                name: '平台',
                desc: 'PC、PS4、Xbox One、Switch',
              },
              {
                name: '模式',
                desc: '单人',
              },
              {
                name: '发行时间',
                desc: '2021/08/10',
              },
            ],
          }
        },
        {
          id: 102,
          img: threeImg2_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `64%`,
            lr: `0%`
          },
          contents:{
            img: threeImg2_1,
            styles:{
              lr: `100%`,
              tb: `65%`,
            },
            desc: {
              title: '守望先锋',
              subTitle: '为《守望先锋®》世界的未来而战，从士兵、科学家、冒险者、奇人异士组成的多元阵容中选择你的英雄。扭曲时间、对抗物理法则，运用各式非凡的能力和武器。在这款团队射击游戏中，在全球的标志性地点与你的对手展开较量。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '第一人称射击',
              },
              {
                name: '平台',
                desc: 'PC、Mobile、PS4、Xbox One、Switch',
              },
              {
                name: '模式',
                desc: '多人',
              },
              {
                name: '发行时间',
                desc: '2021/08/10',
              },
            ],
          }
        },
        {
          id: 103,
          img: threeImg3_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `52%`,
            lr: `0%`
          },
          contents:{
            img: threeImg3_0,
            styles:{
              lr: `100%`,
              tb: `50%`,
            },
            desc: {
              title: '天命2',
              subTitle: '地球最后一座安全的城市已经沦陷，在强大的新敌人「天帝高尔」及手下部队「红色军团」占领下成了断垣残壁。玩家将能创造属于自己的角色「守护者」，他们同时也是人类选出的保护者。重新凝聚城市势力并联手回击，重新夺回家园故土。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '第一人称射击、MMORPG',
              },
              {
                name: '平台',
                desc: 'PC、PS4、Xbox One、Switch',
              },
              {
                name: '模式',
                desc: '单人',
              },
              {
                name: '发行时间',
                desc: '2021/08/10',
              },
            ],
          }
        },
        {
          id: 104,
          img: threeImg4_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `5%`,
            lr: `0%`
          },
          contents:{
            img: threeImg4_0,
            styles:{
              lr: `100%`,
              tb: `22%`,
            },
            desc: {
              title: '众神殒落',
              subTitle: '《众神殒落》是款以打宝为主题的第三人称奇幻角色扮演游戏，着重在近身肉搏的战斗，让玩家享受追求极致装备的乐趣，支援最多3人连线合作游玩。游戏中玩家将扮演一名穿上英勇盔甲的英勇骑士，使用五种不同的武器类别与敌人进行战斗。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '動作角色扮演',
              },
              {
                name: '平台',
                desc: 'PC、PS4',
              },
              {
                name: '模式',
                desc: '单人、多人  ',
              },
              {
                name: '发行时间',
                desc: '2021/08/10',
              },
            ],
          }
        },
        {
          id: 105,
          img: threeImg5_0,
          title: '游戏产品',
          subTitle: [
            '开创全民互动',
            '娱乐新时代',
          ],
          styles:{
            tb: `40%`,
            lr: `0%`
          },
          contents:{
            img: threeImg5_0,
            styles:{
              lr: `100%`,
              tb: `100%`,
            },
            desc: {
              title: '极限巅峰',
              subTitle: '在阿尔卑斯山脉与阿拉斯加山脉极度辽阔的开放世界中尽情奔驰。踏上单板滑雪板、双版滑雪板、穿上飞鼠装、使用滑翔伞征服全世界最传奇的巅峰。单人游玩或与其他玩家挑战极限。',
              btnText: '游戏官网',
              url: '',
            },
            list: [
              {
                name: '类型',
                desc: '体育',
              },
              {
                name: '平台',
                desc: 'PC、PS4、Xbox One',
              },
              {
                name: '模式',
                desc: '单人、多人',
              },
              {
                name: '发行时间',
                desc: '2021/08/10',
              },
            ],
          }
        },
      ]
    },
    pageFour: {
      title: '新闻动态',
      subTitle: [
        '掌握实时动态',
        '了解最新活动',
      ],
      listFourData: [
        {
          id: 101,
          img: titem1,
          tag: '公司新闻',
          title: `超燃亮相ChinaJoy，${companyName.abbreviationZH}人气爆棚福利多多！`,
          time: '2021.07.21',
          styles: {
            tb: `58%`,
            lr: `100%`,
          }
        },
        {
          id: 102,
          img: titem2,
          tag: '产品动态',
          title: `${companyName.abbreviationZH}公众号游戏礼包上线啦！`,
          time: '2021.05.13',
          styles: {
            tb: `58%`,
            lr: `100%`,
          }
        },
        {
          id: 103,
          img: titem3,
          tag: '公司新闻',
          title: `${companyName.abbreviationZH}携多款精品手游新作角逐2020金翎奖`,
          time: '2021.03.11',
          styles: {
            tb: `58%`,
            lr: `100%`,
          }
        },
        {
          id: 104,
          img: titem4,
          tag: '公司新闻',
          title: '关于有不法分子冒用公司名义实施网络诈骗的声明',
          time: '2021.02.08',
          styles: {
            tb: `58%`,
            lr: `100%`,
          }
        },
      ]
    },
    pageFives: {
      title: '联系我们',
      subTitle: [
        '欢迎您通过以下方',
        '式联系我们',
      ],
      listIcsData: [
        {
          id: 101,
          img: fiveIcon1,
          title: '商务合作',
          email: emailsTo.businessCooperation,
        },
        {
          id: 102,
          img: fiveIcon2,
          title: '客户服务',
          email: emailsTo.customerService,
        },
        {
          id: 103,
          img: fiveIcon3,
          title: '投递简历',
          email: emailsTo.resumeDelivery,
        },
      ]
    },
    pageSix: {
      title: '找到我们',
      subTitle: [
        '欢迎探访',
        '我们在23°N, 113°E',
      ],
      info: {
        nation: contactInformation.countryRegionZH,
        list: [
          {
            name: '地址',
            desc: companyAddress.addressZH,
          },
          {
            name: '电话',
            desc: contactInformation.tel,
          },
          {
            name: '传真',
            desc: contactInformation.fox,
          },
        ]
      }
    },
  },
  about: {
    title: '关于我们',
    pageOne:{
      title: '关于我们',
      subTitle: [
        '让游戏拥有',
        '无限可能',
      ],
      info: {
        since: {
          title: 'SINCE 2012',
          desc: `${companyName.fullNameZH}成立于2012年5月，业务覆盖游戏全产业链，包含游戏平台、游戏发行、游戏研发。同时以创意为核心，品质为根基，打造极具竞争力的游戏生态体系，持续为粉丝创造精品内容和互动体验。`,
          img: since,
          imgH: sinceh5,
        },
        la: {
          title: '领先优势',
          desc: `${companyName.abbreviationZH}立足于国内市场，同时积极布局海外。我们依托游戏品质优势、完备的研发和运营实力，陆续推出了多款风靡全球的精品移动游戏，累积用户量超过2亿，在玩家中形成了极大的影响力。${companyName.abbreviationZH}旗下游戏发行版图覆盖国内、日韩、东南亚、欧美、俄罗斯等主流游戏市场，已在海外市场建立起自己的核心竞争力和领先优势。`,
          img: istockphoto_1,
        },
        cv: {
          title: '核心价值',
          desc: '秉持着高效、专注、创新、尊重的企业理念，我们汇聚了高素质、高凝聚力的管理团队和专业高效的技术团队。未来，我们会以产品研发作为企业发展的核心价值，陆续推出一系列具备不同文化属性、玩法内核和市场定位的领导型产品，为全球游戏玩家提供乐趣无穷的娱乐体验。',
          img: istockphoto_2,
        }
      }
    },
    pageTwo: {
      title: '企业文化',
      subTitle: [
        '以用户为中心',
        '以产品为核心',
      ],
      cultureListData: [
        {
          id: 1001,
          mTop: 0,
          card: {
            img: culturesIcon01,
            title: '高效',
            text: 'Efficient'
          },
          desc: {
            line: '––',
            num: '01',
            text: '聚焦目标，高效执行。',
          }
        },
        {
          id: 1002,
          mTop: 40,
          card: {
            img: culturesIcon02,
            title: '专注',
            text: 'Focus'
          },
          desc: {
            line: '––',
            num: '02',
            text: '心有所定，必有收获。',
          }
        },
        {
          id: 1003,
          mTop: 80,
          card: {
            img: culturesIcon03,
            title: '创新',
            text: 'Innovation'
          },
          desc: {
            line: '––',
            num: '03',
            text: '永远好奇，永远热爱。',
          }
        },
        {
          id: 1004,
          mTop: 120,
          card: {
            img: culturesIcon04,
            title: '尊重',
            text: 'Respect'
          },
          desc: {
            line: '––',
            num: '04',
            text: '尊重对手，欣赏对手。',
          }
        },
      ],
    },
    pageThree: {
      title: '发展历程',
      subTitle: [
        '与时代并进',
        '我们的旅程充满挑战',
      ],
      timelineListData: [
        {
          id: 1001,
          year: '2012',
          month: '5月',
          desc: `${companyName.fullNameZH}在广州成立，专注手机游戏研发`,
          img: timeImg1,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1002,
          year: '2015',
          month: '2月',
          desc: '卡牌产品系列上线，在全球主流App市场聚会类型游戏中长期排名Top30',
          img: timeImg2,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1003,
          year: '2016',
          month: '4月',
          desc: '获得天使轮投资，公司成功从单纯研发商转型为研运一体化的游戏厂商',
          img: timeImg3,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1004,
          year: '2018',
          month: '11月',
          desc: '建立海外市场运营团队，开启海外发展征程',
          img: timeImg4,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1005,
          year: '2019',
          month: '2月',
          desc: '已拥有上百款经典游戏，全球用户超过2亿',
          img: timeImg5,
          line: linecirclefirst,
          lineH5: linecirclefirsth5,
          lineHeight: 298,
        },
        {
          id: 1006,
          year: '2020',
          month: '10月',
          desc: '新产品投放市场获得巨大反响，App Store游戏排行榜多地区排名前列，可持续发展能力得到验证',
          img: timeImg6,
          line: linecirclelast,
          lineH5: linecirclelasth5,
          lineHeight: 326,
        },
      ]
    },
  },
  news: {
    title: '新闻动态',
    subTitle: '新闻动态/详情',
    pageOne:{
      title: '发展历程',
      subTitle: [
        '掌握实时动态',
        '了解最新活动',
      ],
      loadMore: '加载更多',
      tabListData:[
        {
          id: 1001,
          title: '全部',
          type: 'all',
          list: [],
        },
        {
          id: 1002,
          title: '公司新闻',
          type: 'news',
          list: [
            {
              id: 100201,
              type: 'news',
              img: newsImg01,
              tag: '公司新闻',
              title: `超燃亮相ChinaJoy，${companyName.abbreviationZH}人气爆棚福利多多！`,
              desc: `7月31日，2020 ChinaJoy在上海新国际博览中心正式开幕。${companyName.abbreviationZH}携旗下多款产品亮相，为广大玩家带来...`,
              time: '2021.07.21',
              list: [
                `中国游戏产业最为权威的评选活动——2020年度优秀游戏评选大赛（第十五届金翎奖）如今已正式拉开序幕。十五年来，金翎奖作为“汇聚玩家所爱”的业界盛事，有着国内游戏界“奥斯卡”的美誉，更因其评选的权威性和公正性而受到中国游戏行业的广泛认同。`,
                `${companyName.abbreviationZH}将携旗下多款游戏产品，与全国游戏爱好者一起共赴这场游戏界的年度盛会，角逐2020金翎奖“玩家最期待的移动网络游戏”、“玩家最喜爱的移动网络游戏”奖项。`,
                `${companyName.abbreviationZH}立足于国内市场，同时积极布局海外，并快速完成了研发、运营、平台三大业务线的梳理整合，建立起完善的产业化研发和精细化运营体系，打造出了一条包括产品立项、IP改编、测试调优、推广发行、长线运营等环节在内的完整产业链。我们一直致力于向用户提供更加优质的娱乐内容和服务，并跟随时代及行业发展的脚步，研发数款多类型与题材的移动游戏。秉持着精品化研发与专业化发行一体化理念，我们将为玩家持续贡献出好玩的高质量游戏。`,
                `自2005年创办以来，金翎奖为国内游戏行业的发展起到了巨大贡献，也成为国内玩家了解游戏产品梯次排名、厂商表达产品理念的风向标。为充分体现广大游戏玩家的心声，2020金翎奖仍由全国玩家通过网络投票的方式评选出最终的获奖作品与厂商及媒体，我们期待玩家们为自己喜爱的优秀产品和媒体投上一票，我们也相信，在本届金翎奖的角逐中，${companyName.abbreviationZH}将不会辜负大家的期待`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
            {
              id: 100202,
              type: 'news',
              img: newsImg02,
              tag: '公司新闻',
              title: `${companyName.abbreviationZH}携多款精品手游新作角逐2020金翎奖`,
              desc: '中国游戏产业最为权威的评选活动——2020年度优秀游戏评选大赛（第十五届金翎奖）如今已正式拉开序幕...',
              time: '2021.03.11',
              list: [
                `中国游戏产业最为权威的评选活动——2020年度优秀游戏评选大赛（第十五届金翎奖）如今已正式拉开序幕。十五年来，金翎奖作为“汇聚玩家所爱”的业界盛事，有着国内游戏界“奥斯卡”的美誉，更因其评选的权威性和公正性而受到中国游戏行业的广泛认同。`,
                `${companyName.abbreviationZH}将携旗下多款游戏产品，与全国游戏爱好者一起共赴这场游戏界的年度盛会，角逐2020金翎奖“玩家最期待的移动网络游戏”、“玩家最喜爱的移动网络游戏”奖项。`,
                `${companyName.abbreviationZH}立足于国内市场，同时积极布局海外，并快速完成了研发、运营、平台三大业务线的梳理整合，建立起完善的产业化研发和精细化运营体系，打造出了一条包括产品立项、IP改编、测试调优、推广发行、长线运营等环节在内的完整产业链。我们一直致力于向用户提供更加优质的娱乐内容和服务，并跟随时代及行业发展的脚步，研发数款多类型与题材的移动游戏。秉持着精品化研发与专业化发行一体化理念，我们将为玩家持续贡献出好玩的高质量游戏。`,
                `自2005年创办以来，金翎奖为国内游戏行业的发展起到了巨大贡献，也成为国内玩家了解游戏产品梯次排名、厂商表达产品理念的风向标。为充分体现广大游戏玩家的心声，2020金翎奖仍由全国玩家通过网络投票的方式评选出最终的获奖作品与厂商及媒体，我们期待玩家们为自己喜爱的优秀产品和媒体投上一票，我们也相信，在本届金翎奖的角逐中，${companyName.abbreviationZH}将不会辜负大家的期待`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
            {
              id: 100203,
              type: 'news',
              img: newsImg03,
              tag: '公司新闻',
              title: '关于有不法分子冒用公司名义实施网络诈骗的声明',
              desc: `近日，有不法分子冒用${companyName.fullNameZH}（以下简称“我司”）名义，骗取用户在其非法网站上进...`,
              time: '2021.02.08',
              list: [
                `中国游戏产业最为权威的评选活动——2020年度优秀游戏评选大赛（第十五届金翎奖）如今已正式拉开序幕。十五年来，金翎奖作为“汇聚玩家所爱”的业界盛事，有着国内游戏界“奥斯卡”的美誉，更因其评选的权威性和公正性而受到中国游戏行业的广泛认同。`,
                `${companyName.abbreviationZH}将携旗下多款游戏产品，与全国游戏爱好者一起共赴这场游戏界的年度盛会，角逐2020金翎奖“玩家最期待的移动网络游戏”、“玩家最喜爱的移动网络游戏”奖项。`,
                `${companyName.abbreviationZH}立足于国内市场，同时积极布局海外，并快速完成了研发、运营、平台三大业务线的梳理整合，建立起完善的产业化研发和精细化运营体系，打造出了一条包括产品立项、IP改编、测试调优、推广发行、长线运营等环节在内的完整产业链。我们一直致力于向用户提供更加优质的娱乐内容和服务，并跟随时代及行业发展的脚步，研发数款多类型与题材的移动游戏。秉持着精品化研发与专业化发行一体化理念，我们将为玩家持续贡献出好玩的高质量游戏。`,
                `自2005年创办以来，金翎奖为国内游戏行业的发展起到了巨大贡献，也成为国内玩家了解游戏产品梯次排名、厂商表达产品理念的风向标。为充分体现广大游戏玩家的心声，2020金翎奖仍由全国玩家通过网络投票的方式评选出最终的获奖作品与厂商及媒体，我们期待玩家们为自己喜爱的优秀产品和媒体投上一票，我们也相信，在本届金翎奖的角逐中，${companyName.abbreviationZH}将不会辜负大家的期待`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
          ],
        },
        {
          id: 1003,
          title: '产品动态',
          type: 'product',
          list: [
            {
              id: 100301,
              type: 'product',
              img: productImg01,
              tag: '产品动态',
              title: `${companyName.abbreviationZH}公众号游戏礼包上线啦！`,
              desc: `为方便各位玩家领取各款游戏的礼包，${companyName.abbreviationZH}公众号【游戏礼包】功能上线啦！今后${companyName.abbreviationZH}旗下游戏礼...`,
              time: '2021.05.13',
              list: [
                `中国游戏产业最为权威的评选活动——2020年度优秀游戏评选大赛（第十五届金翎奖）如今已正式拉开序幕。十五年来，金翎奖作为“汇聚玩家所爱”的业界盛事，有着国内游戏界“奥斯卡”的美誉，更因其评选的权威性和公正性而受到中国游戏行业的广泛认同。`,
                `${companyName.abbreviationZH}将携旗下多款游戏产品，与全国游戏爱好者一起共赴这场游戏界的年度盛会，角逐2020金翎奖“玩家最期待的移动网络游戏”、“玩家最喜爱的移动网络游戏”奖项。`,
                `${companyName.abbreviationZH}立足于国内市场，同时积极布局海外，并快速完成了研发、运营、平台三大业务线的梳理整合，建立起完善的产业化研发和精细化运营体系，打造出了一条包括产品立项、IP改编、测试调优、推广发行、长线运营等环节在内的完整产业链。我们一直致力于向用户提供更加优质的娱乐内容和服务，并跟随时代及行业发展的脚步，研发数款多类型与题材的移动游戏。秉持着精品化研发与专业化发行一体化理念，我们将为玩家持续贡献出好玩的高质量游戏。`,
                `自2005年创办以来，金翎奖为国内游戏行业的发展起到了巨大贡献，也成为国内玩家了解游戏产品梯次排名、厂商表达产品理念的风向标。为充分体现广大游戏玩家的心声，2020金翎奖仍由全国玩家通过网络投票的方式评选出最终的获奖作品与厂商及媒体，我们期待玩家们为自己喜爱的优秀产品和媒体投上一票，我们也相信，在本届金翎奖的角逐中，${companyName.abbreviationZH}将不会辜负大家的期待`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
            {
              id: 100302,
              type: 'product',
              img: productImg02,
              tag: '产品动态',
              title: `${companyName.abbreviationZH}2021年劳动节活动介绍`,
              desc: `五一劳动节来啦！在这里祝各位玩家大人小长假快乐！为了感谢各位玩家大人一直以来对${companyName.abbreviationZH}的支持与...`,
              time: '2021.05.01',
              list: [
                `中国游戏产业最为权威的评选活动——2020年度优秀游戏评选大赛（第十五届金翎奖）如今已正式拉开序幕。十五年来，金翎奖作为“汇聚玩家所爱”的业界盛事，有着国内游戏界“奥斯卡”的美誉，更因其评选的权威性和公正性而受到中国游戏行业的广泛认同。`,
                `${companyName.abbreviationZH}将携旗下多款游戏产品，与全国游戏爱好者一起共赴这场游戏界的年度盛会，角逐2020金翎奖“玩家最期待的移动网络游戏”、“玩家最喜爱的移动网络游戏”奖项。`,
                `${companyName.abbreviationZH}立足于国内市场，同时积极布局海外，并快速完成了研发、运营、平台三大业务线的梳理整合，建立起完善的产业化研发和精细化运营体系，打造出了一条包括产品立项、IP改编、测试调优、推广发行、长线运营等环节在内的完整产业链。我们一直致力于向用户提供更加优质的娱乐内容和服务，并跟随时代及行业发展的脚步，研发数款多类型与题材的移动游戏。秉持着精品化研发与专业化发行一体化理念，我们将为玩家持续贡献出好玩的高质量游戏。`,
                `自2005年创办以来，金翎奖为国内游戏行业的发展起到了巨大贡献，也成为国内玩家了解游戏产品梯次排名、厂商表达产品理念的风向标。为充分体现广大游戏玩家的心声，2020金翎奖仍由全国玩家通过网络投票的方式评选出最终的获奖作品与厂商及媒体，我们期待玩家们为自己喜爱的优秀产品和媒体投上一票，我们也相信，在本届金翎奖的角逐中，${companyName.abbreviationZH}将不会辜负大家的期待`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
            {
              id: 100303,
              type: 'product',
              img: productImg03,
              tag: '产品动态',
              title: `${companyName.abbreviationZH}手游获韩国Google Play首页推荐 海外布局持续深化`,
              desc: '伴随手游行业高速发展及市场竞争加剧，手游出海热潮接踵而至，越来越多的国产精品手游推出海外版本且...',
              time: '2020.12.08',
              list: [
                `中国游戏产业最为权威的评选活动——2020年度优秀游戏评选大赛（第十五届金翎奖）如今已正式拉开序幕。十五年来，金翎奖作为“汇聚玩家所爱”的业界盛事，有着国内游戏界“奥斯卡”的美誉，更因其评选的权威性和公正性而受到中国游戏行业的广泛认同。`,
                `${companyName.abbreviationZH}将携旗下多款游戏产品，与全国游戏爱好者一起共赴这场游戏界的年度盛会，角逐2020金翎奖“玩家最期待的移动网络游戏”、“玩家最喜爱的移动网络游戏”奖项。`,
                `${companyName.abbreviationZH}立足于国内市场，同时积极布局海外，并快速完成了研发、运营、平台三大业务线的梳理整合，建立起完善的产业化研发和精细化运营体系，打造出了一条包括产品立项、IP改编、测试调优、推广发行、长线运营等环节在内的完整产业链。我们一直致力于向用户提供更加优质的娱乐内容和服务，并跟随时代及行业发展的脚步，研发数款多类型与题材的移动游戏。秉持着精品化研发与专业化发行一体化理念，我们将为玩家持续贡献出好玩的高质量游戏。`,
                `自2005年创办以来，金翎奖为国内游戏行业的发展起到了巨大贡献，也成为国内玩家了解游戏产品梯次排名、厂商表达产品理念的风向标。为充分体现广大游戏玩家的心声，2020金翎奖仍由全国玩家通过网络投票的方式评选出最终的获奖作品与厂商及媒体，我们期待玩家们为自己喜爱的优秀产品和媒体投上一票，我们也相信，在本届金翎奖的角逐中，${companyName.abbreviationZH}将不会辜负大家的期待`,
              ],
              styles: {
                tb: `58%`,
                lr: `100%`,
              },
            },
          ],
        },
      ]
    },
  },
  game: {
    title: '游戏产品',
    pageOne:{
      title: '发展历程',
      subTitle: [
        '开创全民互动',
        '娱乐新时代',
      ],
      listNewData: [
        {
          id: 1001,
          title: '白狼杀',
          en: 'THE WEREWOLVES',
          direction: 'left',
          img: imgNg01,
          subLineText: {
            role: '休闲益智',
            party: '多人',
            platform: 'Android、iOS',
          },
          desc: `⽩狼杀是⼀款⽹杀和⾯杀双结合的O2O狼⼈杀游戏。在常规的⽹上狼⼈杀的基础上，我们打破了狼⼈杀的空间局限性，实现玩家⽆论是在线下的桌游店还是在线上均可⽆缝对战，从⽽实现桌游店座位⾼利⽤率。`,
          btnText: '游戏官网',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          },
        },
        {
          id: 1002,
          title: '',
          en: '',
          direction: 'left',
          img: imgNg02,
          subLineText: {
            role: '',
            party: '',
            platform: '',
          },
          desc: `⽩狼杀除了是⼀款为狼⼈杀游戏玩家⽽设计的游戏，也是⼀款为线下桌游商家经营考虑到位的产品。除了丰富⻔店游戏体验、增加特⾊，我们还提供了游戏周边相关收益分润、⼴告流量分润和线上⻔店展示引流等⼀系列福利，旨在提升⻔店的赚钱能⼒和客流量。`,
          btnText: '',
          url: '',
          css: {
            flex: flexRight,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          },
        },
        {
          id: 1003,
          title: '',
          en: '',
          direction: 'left',
          img: imgNg03,
          subLineText: {
            role: '',
            party: '',
            platform: '',
          },
          desc: `⽩狼杀是⼀款⽹杀和⾯杀双结合的O2O狼⼈杀游戏。在常规的⽹上狼⼈杀的基础上，我们打破了狼⼈杀的空间局限性，实现玩家⽆论是在线下的桌游店还是在线上均可⽆缝对战，从⽽实现桌游店座位⾼利⽤率。`,
          btnText: '',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          },
        },
      ],
      listData: [
        {
          id: 1001,
          title: '天外世界',
          en: 'THE OUTER WORLDS',
          direction: 'left',
          img: img01,
          subLineText: {
            role: '動作角色扮演',
            party: '单人',
            platform: 'PC、PS4、Xbox One、Switch',
          },
          desc: '《天外世界》是款第一人称科幻角色扮演游戏，故事背景设定在银河系边缘的太空殖民地，故事叙述主角因为太空船的错误而漂流到银河系边缘，从冷冻睡眠中醒来后孤身一人的他，将展开闯荡边陲殖民地的冒险。',
          btnText: '游戏官网',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          },
        },
        {
          id: 1002,
          title: '守望先锋',
          en: 'OVERWATCH',
          direction: 'left',
          img: img02,
          subLineText: {
            role: '第一人称射击',
            party: '多人',
            platform: 'PC、Mobile、PS4、Xbox One、Switch',
          },
          desc: '为《守望先锋®》世界的未来而战，从士兵、科学家、冒险者、奇人异士组成的多元阵容中选择你的英雄。扭曲时间、对抗物理法则，运用各式非凡的能力和武器。在这款团队射击游戏中，在全球的标志性地点与你的对手展开较量。',
          btnText: '游戏官网',
          url: '',
          css: {
            flex: flexRight,
            margin: mlr,
            tb: '50%',
            lr: '100%',
          }
        },
        {
          id: 1003,
          title: '命运2',
          en: 'DESTINY 2',
          direction: 'left',
          img: img03,
          subLineText: {
            role: '第一人稱射擊、MMORPG',
            party: '单人',
            platform: 'PC、PS4、Xbox One、Switch',
          },
          desc: '地球最后一座安全的城市已经沦陷，在强大的新敌人「天帝高尔」及手下部队「红色军团」占领下成了断垣残壁。玩家将能创造属于自己的角色「守护者」，他们同时也是人类选出的保护者。重新凝聚城市势力并联手回击，重新夺回家园故土。',
          btnText: '游戏官网',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '47%',
            lr: '100%',
          }
        },
        {
          id: 1004,
          title: '众神陨落',
          en: 'GODFALL',
          direction: 'left',
          img: img04,
          subLineText: {
            role: '動作角色扮演',
            party: '单人、多人',
            platform: 'PC、PS4',
          },
          desc: '《众神殒落》是款以打宝为主题的第三人称奇幻角色扮演游戏，着重在近身肉搏的战斗，让玩家享受追求极致装备的乐趣，支援最多3人连线合作游玩。游戏中玩家将扮演一名穿上英勇盔甲的英勇骑士，使用五种不同的武器类别与敌人进行战斗。',
          btnText: '游戏官网',
          url: '',
          css: {
            flex: flexRight,
            margin: mlr,
            tb: '100%',
            lr: '100%',
          }
        },
        {
          id: 1005,
          title: '极限巅峰',
          en: 'STEEP',
          direction: 'left',
          img: img05,
          subLineText: {
            role: '体育',
            party: '单人、多人',
            platform: 'PC、PS4、Xbox One',
          },
          desc: '在阿尔卑斯山脉与阿拉斯加山脉极度辽阔的开放世界中尽情奔驰。踏上单板滑雪板、双版滑雪板、穿上飞鼠装、使用滑翔伞征服全世界最传奇的巅峰。单人游玩或与其他玩家挑战极限。',
          btnText: '游戏官网',
          url: '',
          css: {
            flex: flexLeft,
            margin: mlr,
            tb: '82%',
            lr: '100%',
          }
        },
      ],
    },
  },
  contact: {
    title: '联系我们',
    pageOne:{
      title: '联系我们',
      subTitle: [
        '欢迎您通过以下方式联系我们',
      ],
      list: [
        {
          id: 1001,
          title: '商务合作',
          email: emailsTo.businessCooperation,
          icon: icon1,
        },
        {
          id: 1002,
          title: '客户服务',
          email: emailsTo.customerService,
          icon: icon2,
        },
        {
          id: 1003,
          title: '投递简历',
          email: emailsTo.recruitmentEmail,
          icon: icon3,
        },
      ]
    },
  },
  join: {
    title: '加入我们',
    subTitle: '加入我们/详情',
    pageOne:{
      subTitle: [
        '寻找优秀的你',
        '共创',
        '无限',
        '未来！',
      ],
    },
    pageTwo: {
      title: '公司福利',
      subTitle: [
        '薪酬、福利、发展，',
        '你所期待的我们统统满足！',
      ],
      benefitsListData: [
        {
          id: 1001,
          icon: benefits1,
          title: '薪酬福利',
          list: [
            '年度调薪',
            '激励奖金'
          ],
        },
        {
          id: 1002,
          icon: benefits2,
          title: '五险一金',
          list: [
            '按照国家标准缴纳',
            '安全有保障'
          ],
        },
        {
          id: 1003,
          icon: benefits3,
          title: '弹性上班',
          list: [
            '弹性工作制',
            '避开高峰期'
          ],
        },
        {
          id: 1004,
          icon: benefits4,
          title: '职业发展',
          list: [
            '组织各种培训',
            '晋升空间巨大'
          ],
        },
        {
          id: 1005,
          icon: benefits5,
          title: '生活福利',
          list: [
            '零食饮料免费',
            '工作餐补助'
          ],
        },
        {
          id: 1006,
          icon: benefits6,
          title: '节日福利',
          list: [
            '每逢佳节',
            '都有礼品'
          ],
        },
        {
          id: 1007,
          icon: benefits7,
          title: '年度福利',
          list: [
            '健康体检',
            '年度豪华游'
          ],
        },
        {
          id: 1008,
          icon: benefits8,
          title: '团队活动',
          list: [
            '春游社团活动',
            '运动会嗨起来'
          ],
        },
      ]
    },
    pageThree: {
      title: '办公环境',
      subTitle: [
        '每天要待上8个小时的地方',
        '必须很舒适！',
      ],
      oneOfficeEnvironmentData: [
        {
          id: 1001,
          desc: '办公室的功能设计和空间划分科学，注重环保性和安全性，所有家具均严格采用进口E0级板材，达到国际顶级环保标准。',
          list: [
            {
              key: 'img01',
              imgFirst: imga101,
              imgNext: imga102,
              style: {
                tb: '100%',
                lr: '50%',
              },
            },
            {
              key: 'img02',
              imgFirst: imga201,
              imgNext: imga202, // 119% -12% / 112%
              style: {
                tb: '-88%',
                lr: '119%',
              },
            },
            {
              key: 'img03',
              imgFirst: imga301,
              imgNext: imga302,
              style: {
                tb: '55%',
                lr: '100%',
              },
            },
          ],
        },
        {
          id: 1002,
          desc: '办公室的功能设计和空间划分科学，注重环保性和安全性，所有家具均严格采用进口E0级板材，达到国际顶级环保标准。',
          list: [
            {
              key: 'img01',
              imgFirst: imga101,
              imgNext: imga102,
              style: {
                tb: '100%',
                lr: '50%',
              },
            },
            {
              key: 'img02',
              imgFirst: imga201,
              imgNext: imga202,
              style: {
                tb: '35%',
                lr: '100%',
              },
            },
            {
              key: 'img03',
              imgFirst: imga301,
              imgNext: imga302,
              style: {
                tb: '55%',
                lr: '100%',
              },
            },
          ],
        },
      ],
      twoOfficeEnvironmentData: [
        {
          id: 1001,
          desc: '公共区域拥有诸多复合型功能设计，休闲区、茶水间、母婴室、游戏区等模块一应俱全，充分体现了当代互联网公司以人为本的价值理念。',
          list: [
            {
              key: 'img01',
              imgFirst: imgb101,
              imgNext: imgb102,
              style: {
                tb: '100%',
                lr: '55%',
              },
            },
            {
              key: 'img02',
              imgFirst: imgb201,
              imgNext: imgb202,
              style: {
                tb: '100%',
                lr: '100%',
              },
            },
            {
              key: 'img03',
              imgFirst: imgb301,
              imgNext: imgb302,
              style: {
                tb: '100%',
                lr: '100%',
              },
            },
          ],
        },
        {
          id: 1002,
          desc: '公共区域拥有诸多复合型功能设计，休闲区、茶水间、母婴室、游戏区等模块一应俱全，充分体现了当代互联网公司以人为本的价值理念。',
          list: [
            {
              key: 'img01',
              imgFirst: imgb101,
              imgNext: imgb102,
              style: {
                tb: '100%',
                lr: '55%',
              },
            },
            {
              key: 'img02',
              imgFirst: imgb201,
              imgNext: imgb202,
              style: {
                tb: '100%',
                lr: '100%',
              },
            },
            {
              key: 'img03',
              imgFirst: imgb301,
              imgNext: imgb302,
              style: {
                tb: '100%',
                lr: '100%',
              },
            },
          ],
        },
      ]
    },
    pageFour: {
      title: '职位列表',
      subTitle: [
        '寻找优秀的你',
        '共创无限未来！',
      ],
      programListData: [
        {
          id: 101,
          icon: program1,
          title: '程序类',
          thead: [
            {id: 1, title: '职位名称'},
            {id: 2, title: '发布时间'},
            {id: 3, title: '招聘人数'},
          ],
          list: [
            {
              key: 10101,
              positionName: 'C++服务器开发工程师', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: '岗位详情',
              address: '广州',
              emailName: '招聘邮箱',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: '岗位职责',
                  descList: [
                    `1. 设计实现游戏服务端相应功能`,
                    `2. 进行游戏服务端性能逻辑优化`,
                    `3. 制作和改善相关的游戏工具`,
                    `4. 遵守代码规范,及开发文档的编写维护`,
                  ]
                },
                requirements:{
                  title: '任职要求',
                  descList: [
                    `1. 计算机相关专业，3年以上游戏服务器开发经验`,
                    `2. 熟悉linux/windows环境下的C/C++编程语言，熟悉脚本语言lua`,
                    `3. 熟悉数据结构和算法，熟悉TCP/IP网络编程`,
                    `4. 熟悉mysql.redis数据库`,
                    `5. 积极主动，良好团队意识和沟通能力, 具备较强的逻辑思维和独立解决问题能力`,
                  ]
                }
              }
            },
            {
              key: 10102,
              positionName: 'U3D客户端开发工程师', // 职位名称
              releaseTime: '2021-05-01', // 发布时间
              numberRecruits: '3', // 招聘人数
              detailsTitle: '岗位详情',
              address: '广州',
              emailName: '招聘邮箱',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: '岗位职责',
                  descList: [
                    `1. 负责Unity3D项目的系统逻辑,UI,工具,图形特效的程序开发；`,
                    `2. 协同上级，持续优化改善软件开发；`,
                    `3. 完成项目辅助管理工具的开发。`,
                  ]
                },
                requirements:{
                  title: '任职要求',
                  descList: [
                    `1. 两年以上Unity3D开发项目经验，完整参与过上线游戏项目者优先；`,
                    `2. 熟练掌握C#语言，具有1年以上的C#软件开发工作经验；`,
                    `3. 具有扎实的软件基础，掌握常用的数据结构和算法；`,
                    `4. 熟悉lua脚本语言使用者、熟悉ios、Android程序发布流程者优先；`,
                    `5. 热衷于技术钻研，有良好的团队合作精神和抗压能力，工作踏实诚恳，认真负责。`,
                  ]
                }
              }
            },
          ],
        },
        {
          id: 102,
          icon: program2,
          title: '美术类',
          thead: [
            {id: 1, title: '职位名称'},
            {id: 2, title: '发布时间'},
            {id: 3, title: '招聘人数'},
          ],
          list: [
            {
              key: 10101,
              positionName: '角色原画师', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: '岗位详情',
              address: '广州',
              emailName: '招聘邮箱',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: '岗位职责',
                  descList: [
                    `1. 负责游戏角色原画设计，立绘插画.宣传相关的绘制；`,
                    `2. 按照实际需求积极进行创作内容的调整，可以配合3D人员进行一些补充设计工作`,
                  ]
                },
                requirements:{
                  title: '任职要求',
                  descList: [
                    `1. 具有良好美术功底和美术水平，能够适应风格和内容绘制的调整`,
                    `2. 熟练运用各类美术软件，熟悉或希望了解游戏美术设计工作规范及流程；`,
                    `3. 有良好的沟通能力，愿意积极提出自己的意见并接受他人意见，做事认真细致，具有高度责任感和团队合作精神；`,
                    `4. 对日式ACG有所了解，喜爱进行此类风格的相关创作；`,
                    `5. 简历中需提供近期作品，方便我们能全面的了解您 。`,
                  ]
                }
              }
            },
          ],
        },
        {
          id: 103,
          icon: program3,
          title: '产品策划类',
          thead: [
            {id: 1, title: '职位名称'},
            {id: 2, title: '发布时间'},
            {id: 3, title: '招聘人数'},
          ],
          list: [
            {
              key: 10101,
              positionName: '商业化产品经理', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: '岗位详情',
              address: '广州',
              emailName: '招聘邮箱',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: '岗位职责',
                  descList: [
                    `1. 通过市场调研、了解市场同类软件产品的状况；`,
                    `2. 负责分析和研究产品、优化收入来源和途径、提高流量的变现效率；`,
                    `3. 负责对不同的流量形态提出合适的变现方案；`,
                    `4. 善于利用公司内部资源进行跨部门合作。`,
                  ]
                },
                requirements:{
                  title: '任职要求',
                  descList: [
                    `1. 全日制本科以上学历、3年以上互联网商业产品经验、有商业化流量变现经验；`,
                    `2. 有独立产品策划和技术跟进能力、善于发现问题、解决问题、并能总结问题；`,
                    `3. 对产品有追求极致的精神、并且有创新意识；`,
                    `4. 卓越的逻辑思维与分析能力、优秀的沟通协调能力、善于协调及整合资源；`,
                    `5. 掌握xmind、ppt等工具类产品者优先。`,
                  ]
                }
              }
            },
          ],
        },
        {
          id: 104,
          icon: program4,
          title: '发行运营类',
          thead: [
            {id: 1, title: '职位名称'},
            {id: 2, title: '发布时间'},
            {id: 3, title: '招聘人数'},
          ],
          list: [
            {
              key: 10101,
              positionName: '广告投放', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: '岗位详情',
              address: '广州',
              emailName: '招聘邮箱',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: '岗位职责',
                  descList: [
                    `1. 负责公司手游产品广告投放计划制定以及日常投放，及时跟进素材投放效果，持续优化，定期总结素材投放效果；`,
                    `2. 负责日常广告投放的执行管理，确保广告的准确投放，跟进广告投放效果，及时优化；`,
                    `3. 定期对在投渠道的效果进行整体分析，根据数据分析结果优化广告投放方案，持续精准化广告投放。`,
                  ]
                },
                requirements:{
                  title: '任职要求',
                  descList: [
                    `1. 有一定的主流广告平台（如广点通、头条、百度竞价等）广告投放经验；`,
                    `2. 有腾讯广点通投放经验优先；有游戏广告投放经验优先；`,
                    `3. 思路清晰，熟悉excel，善于数据统计、分析与处理；`,
                    `4. 性格开朗，工作积极主动踏实，能承受工作压力和强度；`,
                    `5. 热爱游戏行业，对游戏有一定了解。`,
                  ]
                }
              }
            },
          ],
        },
        {
          id: 105,
          icon: program5,
          title: '职能类',
          thead: [
            {id: 1, title: '职位名称'},
            {id: 2, title: '发布时间'},
            {id: 3, title: '招聘人数'},
          ],
          list: [
            {
              key: 10101,
              positionName: '游戏客服', // 职位名称
              releaseTime: '2021-05-02', // 发布时间
              numberRecruits: '2', // 招聘人数
              detailsTitle: '岗位详情',
              address: '广州',
              emailName: '招聘邮箱',
              email: emailsTo.recruitmentEmail,
              details: {
                responsibilities:{
                  title: '岗位职责',
                  descList: [
                    `1. 日常QQ业务及电话咨询回复；`,
                    `2. 深入游戏业务，记录客户反馈建议与情况及时反馈；`,
                    `3. 主动维护游戏大户，减少用户流失。`,
                  ]
                },
                requirements:{
                  title: '任职要求',
                  descList: [
                    `1. 1年以上的客服经验，具备较强的服务意识；`,
                    `2. 有责任心，有耐心，具良好情绪控制能力；`,
                    `3. 接受能力强，有较好沟通能力和技巧；`,
                    `4. 普通话标准，反应灵敏，能应付各种提问和质疑；`,
                    `5. 接受晚班与排班。`,
                  ]
                }
              }
            },
          ],
        },
      ],
    },
    pageFives: {},
    pageSix: {},
    pageSeven: {},
    pageEight: {},
    pageNine: {},
    pageTen: {},
  },
};
export default zhCN;
