import React, {memo, useMemo } from 'react';
import styles from './Index.module.scss';

const VerticalLine = memo(({config}) => {
    const { css} = config;
    const lineCss = useMemo(() => {
        return {
            position: 'absolute',
            top: 0,
            ...css,
        }
    }, [css]);

    return (
        <div className={styles.normal} style={lineCss}/>
    );
});

export default VerticalLine;