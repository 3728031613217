import React, {memo, useMemo, useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import BlockContents from "../BlockContents";
import {
    whCss,
    getOffset,
    getParentElement,
} from '@/utils';
import { WelcomeVisitText} from '@/components/FontStrokeCommTexts';
import WebBaiduMap from '../WebBaiduMap';
import styles from './Index.module.scss';

// import findusBg from '../../assets/home/six/findus.png';
// import imgMap from '../../assets/public/imgmap.svg';

// 产品动态

const WelcomeVisit = memo(({config}) => {
    const { scrollNum: cshVal } = config;
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const [mapFlag, setMapFlag] = useState(false);
    const threeEl= useRef(null);

    const { companyImg: findusBg, mapImg: imgMap} = window.globalConfig.contactInformation;

    const refCleft = useRef(null);
    const refCRight = useRef(null);
    const [imgBoxHeight, setImgBoxHeight] = useState({
        height: whCss(400),
    })

    // TODO: two start
    // PC端-动画
    const wRenderDrawTextRef = useRef();
    const [wRenderDrawFlag, setWRenderDrawFlag] = useState(false);
    const wRenderDrawTextRefM = useRef();
    const [wRenderDrawFlagM, setWRenderDrawFlagM] = useState(false);
    const wRenderDrawAnimateScroll = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        // let B1 = getParentElement(A1.offsetParent);
        const A = A1.offsetHeight+getOffset(A1).top;
        // console.log("A1: ", getOffset(A1).top, A1.offsetHeight, A1);
        // console.log("B1: ", getOffset(B1).top, B1.offsetHeight, B1);
        // console.log("打印的结果: ", "A：", A ,"B:", B, "A <= B: ",A <= B);
        if(A <= B){
            setWRenderDrawFlag(true);
        }else{
            setWRenderDrawFlag    (false);
        }
    }
    // 移动端-动画
    const wRenderDrawAnimateScrollM = (curEle) => {
        let B = cshVal;
        let A1 = getParentElement(curEle.current); // 当前元素
        // let B1 = getParentElement(A1.offsetParent);
        const A = A1.offsetHeight+getOffset(A1).top;
        // console.log("A1: ", getOffset(A1).top, A1.offsetHeight, A1);
        // console.log("B1: ", getOffset(B1).top, B1.offsetHeight, B1);
        // console.log("打印的结果: ", "A：", A ,"B:", B, "A <= B: ",A <= B);
        if(A <= B){
            setWRenderDrawFlagM(true);
        }else{
            setWRenderDrawFlagM(false);
        }
    }


    // PC和移动端进行判断
    const pcMAnimateScroll = () => {
        if(recReSizeReducer > 750){
            // PC
            wRenderDrawAnimateScroll(wRenderDrawTextRef);
        }else{
            // H5
            wRenderDrawAnimateScrollM(wRenderDrawTextRefM);
        }
    }
    // TODO: two end

    useEffect(() => {
        pcMAnimateScroll();
    }, [cshVal])

    const wRenderDrawText = useMemo(() => {
        const isWidth = recReSizeReducer >=1920;
        const hMax = 176.76;
        return (
            <WelcomeVisitText
                config={{
                    css: {
                        width: whCss(isWidth ? 2202 : 1512.7),
                        height: whCss(isWidth ? hMax : 134.01),
                    }
                }}
            />
        );
    }, [recReSizeReducer]);

    const wRenderDrawTextH5 = useMemo(() => {
        return (
            <WelcomeVisitText
                config={{
                    css: {
                        width: whCss(472),
                        height: whCss(68),
                    }
                }}
            />
        );
    }, []);

    const handleKeyDown = () => {
        document.onkeydown =  (oEvent) => {
            if(oEvent.code === 'KeyM'){
                setMapFlag(true);
            }

        }
        document.onkeyup =  (oEvent) => {
            // setMapFlag(false);
            setMapFlag(true);

        }
    }


    useEffect(() => {
        handleKeyDown();
    }, [mapFlag]);

    useEffect(() => {
        try{
            threeEl.current.scrollLeft = 113;
        } catch(e) {
            return false;
        }
    }, []);

    useEffect(() => {
        const isWidth = recReSizeReducer >=1920;
        if(refCleft.current && refCleft.current.offsetHeight){
            if(localesReducer['navbar.lang'] === 'English'){
                setImgBoxHeight({
                    height: whCss(isWidth ? 520 : 400)
                });
            }else{
                setImgBoxHeight({
                    height: whCss(isWidth ? 520 : 400)
                });
            }
        }
    }, [localesReducer, recReSizeReducer]);

    const isWidth = {
        Width: localesReducer['navbar.lang'] === 'English' ? `${52 / 100}rem` : `${28 / 100}rem`,
        minWidth: localesReducer['navbar.lang'] === 'English' ? `${52}px` : `${28}px`
    }

    const isClassName = recReSizeReducer > 1000 ? '' : 'ellipsis';

    const handleScrollChange = () => {
        // console.log("reftList", threeEl.current.scrollLeft)

    }

    const handleClickPressM = () => {
        setMapFlag(true);
    }

    const {
        mapPosition,
    }  = window.globalConfig.companyAddress;

    const [ mapPositionObj, setMapPositionObj ] = useState({
        lng: mapPosition.lng,
        lat: mapPosition.lat,
    })
    const handleClickMap = (mapPosition) => {
        setMapFlag(false);
        setMapPositionObj({
            lng: mapPosition.lng,
            lat: mapPosition.lat,
        });
    }

    // PC
    const renderPC = () => {
        const isNormal = recReSizeReducer >=1920 ? styles.normalMax : styles.normal;
        return (
            <div className={isNormal}>
                <div className="gamesBorderTextBox" ref={wRenderDrawTextRef}>
                    { wRenderDrawFlag ? wRenderDrawText : null}
                </div>
                <div className="content">
                    <BlockContents config={{
                        title: localesReducer.home.pageSix.title,       // 标题
                        list: [`${localesReducer.home.pageSix.subTitle[0]}${localesReducer.home.pageSix.subTitle[1]}`],     // 副标题
                        flag: false,                 // 状态
                        alignItems: 'flex-start', // flex-start / flex-end
                        status: 1, // 状态 1， 2， 3
                        titleCss: {
                            fontSize: `${20 / 100}rem`,
                            color: '#B0A27C',
                            marginBottom: `${16 / 100}rem`,
                        },
                        listCss: {
                            width: '',
                            height: '',
                            fontSize: `${56 / 100}rem`,
                            textAlign: 'left',
                            color: '#1A1A1A',
                            lineHeight: `${80 / 100}rem`
                        },
                        fLCss: {
                            firstColor: '',
                            lastColor: '',
                        }
                    }} />
                </div>
                <div className="cardinfo">
                    <div className="cleft" ref={refCleft}>
                        <div className="cdesc">
                            <h5 className="ctitle">{localesReducer.home.pageSix.info.nation}</h5>
                            {localesReducer.home.pageSix.info.list.map((item, index) => {
                                return (
                                    <div key={index} className="csubtext"><span style={isWidth}>{item.name}</span><span className={index === 0 ? isClassName:''}>{item.desc}</span></div>
                                )
                            })}
                        </div>
                    </div>

                    {mapFlag ? (
                        <div className="cright" ref={refCRight} style={{...imgBoxHeight }}>
                            <WebBaiduMap
                                config={{
                                    css: imgBoxHeight,
                                    handleClickMap,
                                    recReSizeReducer,
                                    lang: localesReducer['navbar.lang'] === 'English'
                                }}
                            />
                        </div>
                    ) : (
                        <div className="cright" style={{...imgBoxHeight, background: `url(${findusBg}) no-repeat 62% 12% / 154%` }}>
                            <div className="pressm" onClick={handleClickPressM}><span>Map</span></div>
                        </div>
                    )}

                </div>
            </div>
        );
    }




    // H5
    const renderH5 = () => {
        return (
            <div className={styles.normalH5}>
                <div className="gamesBorderTextBox" ref={wRenderDrawTextRefM}>
                    { wRenderDrawFlagM ? wRenderDrawTextH5 : null}
                </div>
                <div className="content">
                    <BlockContents config={{
                        title: localesReducer.home.pageSix.title,       // 标题
                        list: localesReducer.home.pageSix.subTitle,     // 副标题
                        flag: false,                 // 状态
                        alignItems: 'flex-start', // flex-start / flex-end
                        status: 1, // 状态 1， 2， 3
                        titleCss: {
                            fontSize: `${17 / 100}rem`,
                            color: '#B0A27C',
                            marginBottom: `${16 / 100}rem`,
                        },
                        listCss: {
                            width: '',
                            height: '',
                            fontSize: `${32 / 100}rem`,
                            textAlign: 'left',
                            color: '#1A1A1A',
                            lineHeight: `${40 / 100}rem`
                        },
                        fLCss: {
                            firstColor: '',
                            lastColor: '',
                        }
                    }} />
                </div>
                <div className="cardinfo">
                    <div className="cleft">
                        <div className="cdesc">
                            <h5 className="ctitle">{localesReducer.home.pageSix.info.nation}</h5>
                            {localesReducer.home.pageSix.info.list.map((item, index) => {
                                return (
                                    <div key={index} className="csubtext"><span style={isWidth}>{item.name}</span><span className={index === 0 ? isClassName:''}>{item.desc}</span></div>
                                )
                            })}
                        </div>
                    </div>
                    {mapFlag ? (
                        <div className="cright" ref={refCRight} style={{...imgBoxHeight }}>
                            <WebBaiduMap
                                config={{
                                    css: imgBoxHeight,
                                    handleClickMap,
                                    recReSizeReducer,
                                    lang: localesReducer['navbar.lang'] === 'English'
                                }}
                            />
                        </div>
                    ) : (
                        <div className="cright" style={{...imgBoxHeight, background: `url(${findusBg}) no-repeat 62% 12% / 154%` }}>
                            <div className="pressm" onClick={handleClickPressM}><span>Map</span></div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return recReSizeReducer > 750 ? renderPC() : renderH5();
});
export default WelcomeVisit;