import { createSlice } from '@reduxjs/toolkit';

export const newsDetailSlice = createSlice({
  name: 'newsDetail',
  initialState: {
    list: [],
    data: {
      id: 0,
      page: 0,
      size: 0,
      list: [],
    }
  },
  reducers: {
    handleNewsDetail: (state, action) => {
          state.list = action.payload;
          state.data = {
            ...state.data,
            ...action.payload,
          }
      }
  }
});