import { createSlice } from '@reduxjs/toolkit';

export const scrollNumSlice = createSlice({
    name: 'scrollNum',
    initialState: {
        value: 0,
        cshVal: 0,
    },
    reducers: {
        selectScrollNum: (state, action) => {
            state.value = action.payload.value;
            state.cshVal = action.payload.cshVal;
        }
    }
  });