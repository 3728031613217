import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { actions } from '@/models/stores/actions';
import arrowLeft from '@/assets/news/detail/arrow_left.svg'
import arrowRight from '@/assets/news/detail/arrow_right.svg'
import styles from './Index.module.scss';
import {
    whCss,
    storage,
} from '@/utils';
import {NewsLineText} from "@/components/FontStrokeCommTexts";
import {getBackTo} from "@/utils";

const Detail = memo((props) => {
    const newsDetailReducer = useSelector(({newsDetailReducer}) => newsDetailReducer.list);
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const dispatch = useDispatch();
    const { handleNewsDetail } = actions;
    
    const [active, setActive] = useState('all');
    const [pageIndex, setPageIndex] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const nCRenderDrawText = useMemo(() => {
        const isWidth = recReSizeReducer >= 1920;
        const hMax = 176.76;
        return (
            <NewsLineText
                config={{
                    css: {
                        width: whCss( isWidth ? 747 : 541.76),
                        height: whCss( isWidth ? hMax : 134.01),
                        marginBottom: whCss(50),
                    }
                }}
            />
        );
    }, [recReSizeReducer]);

    const nCRenderDrawTextH5 = useMemo(() => {
        return (
            <NewsLineText
                config={{
                    css: {
                        width: whCss( 175),
                        height: whCss(68),
                        marginBottom: whCss(50),
                    }
                }}
            />
        );
    }, []);

    useEffect(() => {
        const srr = props.location.search.split('?')[1];
        let obj = qs.parse(srr);
        setActive(obj.active);

        console.log("newsDetailReducer.list: ", newsDetailReducer.list, newsDetailReducer.page);

        try{
            setPageIndex(newsDetailReducer.page);
            setPageTotal(newsDetailReducer.size -1);

        }catch(e){
            return false;
        }

        if(newsDetailReducer.list === undefined){
            handLinkJump();
        }

        return () => {
            dispatch(handleNewsDetail({
                page: 0,
                size: 0,
                list: [],
            }));
        }
    }, []);

    useEffect(() => {
        getBackTo();
    }, []);



    const handLinkJump = () => {
        try {
            let { type } = storage('details').getStorage();
            props.history.push(type === 'home' ? '/' : `/news?active=${active}`);
        }catch (e) {
            props.history.push(`/news?active=${active}`);
        }

    }

    const handlePrevNext = useCallback((type) => {
        if(type === 'PREV'){
            if(pageIndex !== 0 ){
                setPageIndex(pageIndex-1);
            }
        }else{
            if(pageIndex !== newsDetailReducer.size-1 ){
                setPageIndex(pageIndex+1);
            }
        }

    }, [pageIndex, newsDetailReducer ]);

    const data = newsDetailReducer.list && newsDetailReducer.list[pageIndex];

    const renderPC = () => {

        const isNormal = recReSizeReducer >= 1920 ? styles.normalMax : styles.normal;
        return (
            <div className={isNormal}>
                <div className="textanimate">{nCRenderDrawText}</div>
                <div className="content">
                    <div className="info">
                        <div className="top" style={{ background: `url(${data ? data.img : ''}) no-repeat 100% 100% / 100%`}} />
                        <div className="cardbox">
                            <div className="headerTitle">
                                <h1>{data ? data.title : null}</h1>
                            </div>
                            <div className="cardMain">
                                <div className="mleft">
                                    <div className="mrdatatime">
                                        <div className="mrdyd">
                                            <p>{data ? `${data.time.split('.')[0]}.${data.time.split('.')[1]}` : null}</p>
                                            <p>{data ? `${data.time.split('.')[2]}` : null}</p>
                                        </div>
                                        <div className="mrtitle"><p>{data ? data.tag : null}</p></div>
                                    </div>
                                </div>
                                <div className="mright">
                                    {
                                        data ? (data.list.map((item, index) => {
                                            return (<p key={index}>{item}</p>);
                                        })) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cardFooter">
                        <div className="line" />
                        <div className="tabselect">
                            <div className={pageIndex === 0 ? 'previous active' : 'previous'} onClick={() => handlePrevNext('PREV')}>
                                <div className="scrollAnimation">
                                    <div className="scrollText">
                                       <div className="row">
                                           <i className="icon"><img src={arrowLeft} alt="icon"/></i>
                                            <span>PREV</span>
                                       </div>
                                        <div className="row">
                                            <i className="icon"><img src={arrowLeft} alt="icon"/></i>
                                            <span>PREV</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="backtolist" onClick={handLinkJump}>
                                <div className="scrollAnimation">
                                    <div className="scrollText">
                                        <span>BACK TO LIST</span>
                                        <span>BACK TO LIST</span>
                                    </div>
                                </div>
                            </div>
                            <div className={pageIndex === pageTotal ? 'next active' : 'next'} onClick={() => handlePrevNext('NEXT')}>
                                <div className="scrollAnimation">
                                    <div className="scrollText">
                                        <div className="row">
                                            <span>NEXT</span>
                                            <i className="icon"><img src={arrowRight} alt="icon"/></i>
                                        </div>
                                        <div className="row">
                                            <span>NEXT</span>
                                            <i className="icon"><img src={arrowRight} alt="icon"/></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderH5 = () => {
        return (
            <div className={styles.normalh5}>
                <div className="textanimate">{nCRenderDrawTextH5}</div>
                <div className="content">
                    <div className="info">
                        <div className="top" style={{ background: `url(${data ? data.img : ''}) no-repeat 100% 100% / 100%`}} />
                        <div className="cardbox">
                            <div className="headerTitle">
                                <h1>{data ? data.title : null}</h1>
                            </div>
                            <div className="cardMain">
                                <div className="mrdatatime">
                                    <p>{data ? data.tag : null}</p>
                                    <p>{data && data.time}</p>
                                </div>
                                <div className="mright">
                                    {
                                        data ? (data.list.map((item, index) => {
                                            return (<p key={index}>{item}</p>);
                                        })) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cardFooter">
                        <div className="line" />
                        <div className="tabselect">
                            <div className={pageIndex === 0 ? 'previous active' : 'previous'} onClick={() => handlePrevNext('PREV')}>
                                <i className="icon"><img src={arrowLeft} alt="icon"/></i>
                                <span>PREV</span>
                            </div>
                            <div className="backtolist" onClick={handLinkJump}><span>BACK TO LIST</span></div>
                            <div className={pageIndex === pageTotal ? 'next active' : 'next'} onClick={() => handlePrevNext('NEXT')}>
                                <span>NEXT</span>
                                <i className="icon"><img src={arrowRight} alt="icon"/></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    if(data && data){
        return recReSizeReducer > 750 ? renderPC() : renderH5();
    }else{
        return null;
    }
});

export default withRouter(Detail);