import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../models/stores/actions';
import { routers } from '../../routers';
import Locales from '@/components/Locales';
import {
    getBackTo,
    storage,
    whCss,
    delay,
} from '@/utils';
import styles from './Index.module.scss';


// import logo from '../../assets/public/logo.svg';
import menuS from '../../assets/public/menua.svg';
import menuH from '../../assets/public/menub.svg';

import wechat from '@/assets/public/wechat.svg';
import wechata from '@/assets/public/wechata.svg';
import weibo from '@/assets/public/weibo.svg';
import weiboa from '@/assets/public/weiboa.svg';


const Header = memo((props) => {
    const [isMenu, setIsMenu] = useState(true);
    const [isWechat, setIsWechat] = useState(false);
    const [showLogo, setShowLogo] = useState(false);
    const [isWeibo, setIsWeibo] = useState(false);
    const localesReducer = useSelector(({localesReducer}) => localesReducer.i18n);
    const recReSizeReducer = useSelector(({recReSizeReducer}) => recReSizeReducer.value);
    const dispatch = useDispatch();
    const { localesI18n } = actions;
    const { scrollNum } = props;

    const { logo, logo1, logo2 } = window.globalConfig.logos.header;

    // transform:translateY(-8px); // transition: all  .25s  ease-in;
    const easeIn = 'all 0.3s ease 0s';
    const [navAnimationCss, setNavAnimationCss] = useState({
        opacity: 0,
        transition: easeIn,
        transform: `translateX(${whCss(1000)})`,
    })

    // 路由跳转的方法
    const handleLinkJump = useCallback((item) => {
        const { history } = props;
        window.document.title = item.name;
        history.push(item.path);
        setIsMenu(true);
        getBackTo();
        storage('scrollTopKey').removeStorage();
        storage('details').removeStorage();
    }, [props]);

    // 导航菜单的展示
    const renderLink =  () => {
        const { location } = props;

        const routersList = routers.filter(item => item.path !== '/news/detail' && item.path !== '/join/detail');

        const aryList = routersList.map((item, index) => {
            let obj = {};
            // eslint-disable-next-line array-callback-return
            localesReducer.navbar.menus.map((k, j) => {
                if(index === j){
                    obj = {
                        path: item.path,
                        name: k.name,
                        pcw: k.pcw,
                        h5w: k.h5w,
                    }
                }
            });
            return obj;
        });

        // eslint-disable-next-line array-callback-return
        return aryList.map((item, i) => {
            if(item.path !== '/news/detail' && item.path !== '/join/detail'){
                return recReSizeReducer > 750 ? (
                    <div key={i} className={item.path === location.pathname ? 'defaults active' : 'defaults'} onClick={() => handleLinkJump(item)}>
                        <div className="txtBox">
                            <div className="tetContent">
                                <div>{item.name}</div>
                                <div>{item.name}</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div key={i} className={item.path === location.pathname ? 'defaults active' : 'defaults'} onClick={() => handleLinkJump(item)}>
                        <div className="txtBox">
                            <div className="tetContent">
                                <div>{item.name}</div>
                                <div>{item.name}</div>
                            </div>
                        </div>
                    </div>

                );
            }
        });
    };

    useEffect(() => {
        const { location } = props;
        // 初始语言编译
        const langType = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zhCN';
        dispatch(localesI18n({lang: langType}));

        // 设置BOM浏览器的标题
        try{
            const pathName = routers.filter(item => item.path === location.pathname)[0];
            window.document.title = pathName.name;
        }catch (e) {
            return false;
        }
    }, [dispatch, localesI18n, props]);

    /**
     * $num: 100;
     *  @return $total / $num + rem;
     */
    // 判断是否展开设置导航的高度
    // const isHeight = recReSizeReducer > 750 ? `${108 / 100}rem` : (!isMenu ? `100vh` : `${76 / 100}rem`);
    const isH = scrollNum > 108 ? `${66 / 100}rem` : `${108 / 100}rem`;
    const isHM = scrollNum > 108 ? `${56 / 100}rem` : `${76 / 100}rem`;
    const isLogos = scrollNum > 108 ? { height: `${56 / 100}rem` } : { height: `${76 / 100}rem` };
    const isHeight = recReSizeReducer > 750 ? isH : (!isMenu ? `100vh` : isHM);

    // 设置导航滑动到指定的距离设置背景色
    const bgRgba = 'rgba(255, 255, 255, 0.97)';
    const bg = {
        background: bgRgba,
        backdropFilter:`blur(${12 / 100})rem`,
        height: isHeight,
        // transition: `all 0.5s`
    }
    const tra = useMemo(() => {
        return {
            background: !isMenu ? bgRgba : 'transparent',
            height: isHeight,
        }
    }, [isHeight, isMenu]);

    // 判断是否为移动端并设置样式
    const cssReSizeVal = recReSizeReducer > 750 ? styles.header : styles.headerM;

    // 设置 navBtn 按钮的状态
    const handleMenu = useCallback(() => {
        setIsMenu(!isMenu);
        if(!isMenu){
            setNavAnimationCss({
                opacity: 0,
                transition: easeIn,
                transform: `translateX(${whCss(1000)})`,
            });
        }else{
            delay(100, () => {
                setNavAnimationCss({
                    opacity: 1,
                    transition: easeIn,
                    transform: `translateX(${whCss(0)})`,
                });
            })
        }

    }, [isMenu]);

    const handleMouseOver = useCallback((type) => {
        if(type === 'wechat'){
            setIsWechat(true);
        }else{
            setIsWeibo(true);
        }
    }, []);

    const handleMouseOut = useCallback((type) => {
        if(type === 'wechat'){
            setIsWechat(false);
        }else{
            setIsWeibo(false);
        }

    }, []);

    const handleJumpHome = () => {
        const { history } = props;
        window.document.title = localesReducer.navbar.menus[0].name;
        history.push('/');
        getBackTo();
        setIsMenu(true);
    }

    const handleShowLogo = useCallback((type) => {
        if (type === 'logo1'){
            setShowLogo(false);
        }
        if (type === 'logo2'){
            setShowLogo(true);
        }

    },[]);



    return (
        <div className={cssReSizeVal} style={scrollNum > 108 ? bg : tra}>
            <div className="logos" style={isLogos}>
                {/*<img onClick={handleJumpHome} src={logo1} alt="logo" />*/}
                <img  onClick={handleJumpHome} src={showLogo ? logo1 : logo2} onMouseOver={() => handleShowLogo('logo1')} onMouseLeave={() => handleShowLogo('logo2')} alt="Logo"/>
                {recReSizeReducer > 750 ? <img src={localesReducer.navbar.headerLogo} alt="logo" onClick={handleJumpHome} /> : (
                    <div className="navBtn" onClick={handleMenu}>
                        <img src={isMenu ? menuS : menuH} alt="logo" />
                    </div>
                )}
            </div>
            {
                recReSizeReducer > 750 ? (
                    <div className="nav">
                        <div className="links">
                            { renderLink() }
                        </div>
                        {recReSizeReducer > 750 ? null : (
                            <div className="share">
                                <i><img src={wechat} alt="wechat"/></i>
                                <i><img src={weibo} alt="weibo"/></i>
                            </div>
                        )}
                        <div className="localesTab">
                            <Locales />
                        </div>
                    </div>
                ) : (
                    !isMenu ? (
                        <div className="nav" style={navAnimationCss}>
                            <div className="links">
                                { renderLink() }
                            </div>
                            {recReSizeReducer > 750 ? null : (
                                <div className="share">
                                    <i onMouseOver={() => handleMouseOver('wechat')} onMouseOut={() => handleMouseOut('wechat')}><img src={isWechat ? wechata : wechat} alt="wechat"/></i>
                                    <i onMouseOver={() => handleMouseOver('weibo')} onMouseOut={() => handleMouseOut('weibo')}><img src={isWeibo ? weiboa : weibo} alt="weibo"/></i>
                                </div>
                            )}
                            <div className="localesTab">
                                <Locales />
                            </div>
                        </div>
                    ) : null
                )
            }
        </div>
    );
});


export default withRouter(Header);