import React, { memo, useState, } from 'react';
import { Map, Marker, APILoader, } from '@uiw/react-baidu-map';
import styles from './index.module.scss';
const BMap = window.BMap;

const CustomIcon = ({ config }) => {
    // console.log("config: ", config)
    const {
        addressZH,
        addressEN,
        mapMarkIcon,
        mapPosition,
        mapMarkIconM,
        mapBgImg,

    }  = window.globalConfig.companyAddress;


    const sizes = config.recReSizeReducer > 750;

    const [enableDragging, setEnableDragging] = useState(false);
    const [isAddEvent, setIsAddEvent] = useState(false);
    // const icon = new BMap.Icon(mapMarkIcon, new BMap.Size(119, 170));
    const icon = new BMap.Icon(sizes ? mapMarkIcon : mapMarkIconM, new BMap.Size(sizes ? 119 : 80, sizes ? 170 : 112));
    /*BMap.setMapStyle({
        style:"dark"
    });*/

    const dragendHandle = ({ type, target, pixel, point }) => {
        console.log('dragendHandle', type, target, pixel, point);
    }

    const markerRef = (props) => {
        if (props && props.marker && !isAddEvent) {
            setIsAddEvent(true)
            props.marker.removeEventListener('dragend', dragendHandle);
            props.marker.addEventListener('dragend', dragendHandle);
            if(config.handleClickMap){
                props.marker.addEventListener('click', () => {
                    config.handleClickMap(mapPosition);
                });
            }

        }
    }

    return (
        <>
            {/*<button onClick={() => setEnableDragging(!enableDragging)}>{enableDragging ? '禁用拖拽' : '启用拖拽'}</button>*/}
            {/*<button onClick={() => setIcon(iconfox1)}>设置icon</button>*/}
            {/*<button onClick={() => setIcon(iconfox)}>设置icon</button>*/}
            {/*{postion && <span>{postion}</span>}*/}
            <Map
                zoom={13}
                center={{ ...mapPosition }}

            >
                <Marker
                    ref={markerRef}
                    enableDragging={enableDragging}
                    position={{ ...mapPosition }} icon={icon}
                />
            </Map>
        </>
    );
}

const Index  =  memo(({config}) => {
    const { css, handleClickMap } = config;
    const isNormal = config.recReSizeReducer > 750 ? styles.normal : styles.normalM;

    // 未连接到互联网
    const networkLine = () => {
        return <p style={{ textAlign: 'center',height: css.height, lineHeight: css.height}}>{config.lang ? 'Not Internet' : '未连接到互联网'}</p>
    }
    return (
        <div className={isNormal} style={{ width: '100%', height: css.height }}>
            {
                navigator.onLine ? (
                    <APILoader akay="GTrnXa5hwXGwgQnTBG28SHBubErMKm3f">
                        <CustomIcon
                            config={{
                                handleClickMap
                            }}
                        />
                    </APILoader>
                ) : networkLine()
            }

        </div>
    );
});

export default Index;